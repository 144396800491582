import React, { useState, useContext, useEffect } from "react";
import { Button, Modal, Spinner, Table } from "react-bootstrap";
import { LanguageContext } from "../../../resources/languageContext";
import d from "../../../resources/dictionary.json";
import Cookies from "js-cookie";
import { useParams } from "react-router-dom";
import Unit from "./Unit";

function Objectives() {
  const { language } = useContext(LanguageContext);
  const [showModal, setShowModal] = useState(false);
  const [objective, setObjective] = useState({
    type: "",
    minValue: "",
    maxValue: "",
  });
  const [objectives, setObjectives] = useState([]);
  const token = Cookies.get("jwt");
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;

    setObjective((previousObjective) => ({
      ...previousObjective,
      [name]: value,
    }));
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const request = {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        ObjectiveType: objective.type,
        greaterThan: objective.maxValue,
        lessThan: objective.minValue,
        patient_id: id,
      }),
    };
    try {
      let response = await fetch(
        "https://doctor.shls.care/api/doctor/objectif",
        request
      );
      if (response.status === 200) {
        let newObjectives = [...objectives];
        let newObjective = { ...objective };
        let newIndex = newObjectives.findIndex(
          (obj) => obj.objective_type === newObjective.type
        );
        newObjectives[newIndex].objective_less = newObjective.minValue;
        newObjectives[newIndex].objective_greater = newObjective.maxValue;
        setObjectives(newObjectives);
      }
    } catch (error) {
      console.error(error.message);
    } finally {
      setShowModal(false);
    }
  };

  useEffect(() => {
    let ignore = false;
    const fetchData = async () => {
      const request = {
        method: "GET",
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers":
            "Origin, X-requested-With, Content-Type, Accept",
          Authorization: "Bearer " + token,
        },
      };
      try {
        let response = await fetch(
          `https://doctor.shls.care/api/doctor/get_objectif?id=${id}`,
          request,
          { mode: "cors" }
        );
        const data = await response.json();
        if (response.status === 200) {
          setObjectives(data.data);
        }
        setLoading(false);
      } catch (err) {
        console.log(err.message);
      }
    };
    if (!ignore) {
      fetchData();
    }

    return () => {
      setLoading(false);
      window.scrollTo(0, 0);
      ignore = true;
    };
  }, []);

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center p-4">
        <Spinner animation="border" variant="primary" />
      </div>
    );
  } else {
    return (
     <div className="objectives ">
        <Button className="m-3 px-3 float-right" onClick={() => setShowModal(true)}>
          {d["add objective"][language]}
        </Button>
          <Modal
            centered
            show={showModal}
            onHide={() => {
              setShowModal(false);
            }}
            className="modal fade"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">{d["new objective"][language]}</h5>
                <button
                  type="button"
                  onClick={() => {
                    setShowModal(false);
                  }}
                  className="close"
                  data-dismiss="modal"
                >
                  <span>&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label className="text-black font-w400 mt-3">
                      {d["type"][language]}
                    </label>
                    <select
                      style={objective.type === "" ? {} : { color: "black" }}
                      required
                      className="form-control"
                      name="type"
                      value={objective.type}
                      onChange={handleChange}
                    >
                      <option value={""}>{d["type"][language]}</option>
                      <option style={{ color: "black" }} value={"Glycemia"}>
                        {d["glycemia"][language]}
                      </option>
                      <option style={{ color: "black" }} value={"Pressure"}>
                        {d["blood pressure"][language]}
                      </option>
                      <option style={{ color: "black" }} value={"Hba1c"}>
                        HbA1c
                      </option>
                      <option style={{ color: "black" }} value={"Weight"}>
                        {d["weight"][language]}
                      </option>
                    </select>

                    <label className="text-black font-w400 mt-3">
                      {d["minValue"][language]}
                    </label>
                    <input
                      style={objective.minValue === "" ? {} : { color: "black" }}
                      required
                      type="number"
                      placeholder="80 >"
                      className="form-control"
                      name="minValue"
                      value={objective.minValue}
                      onChange={handleChange}
                    />

                    <label className="text-black font-w400 mt-3">
                      {d["maxValue"][language]}
                    </label>
                    <input
                      style={objective.maxValue === "" ? {} : { color: "black" }}
                      required
                      placeholder="> 100"
                      type="number"
                      className="form-control"
                      name="maxValue"
                      value={objective.maxValue}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="text-center mt-4">
                    <button type="submit" className="btn btn-primary">
                      {d["send"][language]}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </Modal>
          {objectives && (
            <>
            <Table className="table table-bordered " style={{ borderRadius: "0.5rem" }}>
              <thead>
                <tr>
                  <th className="fs-18">{d["type"][language]}</th>
                  <th className="fs-18">{d["minValue"][language]}</th>
                  <th className="fs-18">{d["maxValue"][language]}</th>
                </tr>
              </thead>
              <tbody>
                {objectives.map((objective, key) => (
                  <tr key={key} className="fs-16">
                    <td>
                      {d[objective.objective_type][language]}
                    </td>
                    <td>
                      {objective.objective_less}{" "}
                      <Unit type={objective.objective_type} />
                    </td>
                    <td>
                      {objective.objective_greater}{" "}
                      <Unit type={objective.objective_type} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            </>
          )}
        </div>
    );
  }
}

export default Objectives;
