import React, { useState, useEffect, useContext } from "react";
import { Calendar as BigCalendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import Alert from "sweetalert2";
import Cookies from "js-cookie";
import d from "../../resources/dictionary.json";
import { LanguageContext } from "../../resources/languageContext";
import { Link } from "react-router-dom";
import moment from "moment";
import 'moment/locale/en-gb';
import 'moment/locale/fr';
import "./Calendar.css";
import patientPhoto from "../../images/avatar/patient.png";
import woman from "../../images/avatar/woman.png";
import { Spinner, Modal } from "react-bootstrap";
import { toast } from "react-toastify";


// Custom event component
const localizer = momentLocalizer(moment);
const CustomEvent = ({ event }) => {
  const { language } = useContext(LanguageContext);
  moment.locale(language);
  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };
  return (

          <div className={`event d-flex flex-column justify-content-evenly px-2 consultation ${expanded ? 'expanded' : ''}${event.status==='accepted' ? ' accepted' : ''}`}onClick={toggleExpand}>
            <div className="">
              <div className="d-flex justify-content-between align-items-center">
                <strong className="fs-20 GoldmanSemiBold">{event.patient} </strong>
                <svg className={`${expanded ? 'arrow-up' : ''}`} width="12" height="12" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_3355_4018)">
                    <path d="M15.9314 4.375C15.8783 4.2375 15.7939 4.13438 15.5033 3.84063C15.0564 3.39063 14.9502 3.33125 14.6096 3.32813C14.4283 3.32813 14.3252 3.34375 14.2346 3.3875C14.1502 3.42813 13.1252 4.42813 11.0533 6.49688L8.0002 9.54687L4.94395 6.49375C2.56895 4.11875 1.85958 3.425 1.7502 3.37813C1.65645 3.33438 1.54083 3.3125 1.40645 3.3125C1.08458 3.3125 0.928326 3.40313 0.497076 3.8375C0.193951 4.14375 0.125201 4.23437 0.0689506 4.38437C-0.0185494 4.6125 -0.0185494 4.79375 0.0658256 5.02187C0.128326 5.19687 0.287701 5.35937 3.79395 8.86562C7.84395 12.9187 7.5877 12.6875 8.0002 12.6875C8.4127 12.6875 8.15645 12.9187 12.2064 8.86562C15.7158 5.35625 15.8721 5.19688 15.9346 5.02188C16.0158 4.79688 16.0158 4.59688 15.9314 4.375Z" fill="#0A113D" />
                  </g>
                  <defs>
                    <clipPath id="clip0_3355_4018">
                      <rect width="16" height="16" fill="white" transform="translate(16) rotate(90)" />
                    </clipPath>
                  </defs>
                </svg>
              </div>

              {expanded &&<p className="fs-16 event-time">{event.start.getHours() + ":" + event.start.getMinutes()} - {event.end.getHours() + ":" + event.end.getMinutes()}</p>}
            </div>
            {expanded && 
            <div className="d-flex justify-content-start mb-3 ">
              <img
                src={
                  (() => {
                    if (event.photo !== null && event.photo !=="") {
                      return "https://doctor.shls.care/pictures/" + event.photo
                    } else {
                      if ((event.sexe === "male") || (event.sexe === "Male")) {
                        return patientPhoto
                      } else {
                        return woman
                      }
                    }
                  })()
                }
                className="rounded-circle mr-2"
                alt="user"
                width="48"
                height="48"
              />
              <div>
                <p className="fs-14 mb-0 GoldmanSemiBold" style={{ color: "#0A113D" }}>{event.patient}</p>
                <span className="patient-city GoldmanNormal" style={{ color: "#0A113D" }}>{event.city}</span>
              </div>
            </div>
            }
    </div>
  );
};

const Calendar = () => {
  const { language } = useContext(LanguageContext);
  const [appointments, setAppointments] = useState([]);
  const token = Cookies.get("jwt");
  const [loadingButton, setLoadingButton] = useState(false);
  const [patients, setPatients] = useState("");
  const [selectedPatient, setSelectedPatient] = useState("");

  const [error, setError] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [duration, setDuration] = useState("");
  const [note, setNote] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [minDate, setMinDate] = useState(null);
  useEffect(() => {
    const min_date = new Date();
    setMinDate(moment(min_date).format("YYYY-MM-DD"));
    getAppointments("https://doctor.shls.care/api/doctor/appointements");
    getData("https://doctor.shls.care/api/doctor/patients");

  }, []);
  const getAppointments = async (url) => {
    const request = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    try {
      let response = await fetch(url, request, { mode: "cors" });
      if (response.status === 200) {
        let data = await response.json();
        let events = data.data.map((apt) => {
          const dateString = apt.appointement_date;
          const year = parseInt(dateString.substring(0, 4));
          const month = parseInt(dateString.substring(5, 7)) - 1; 
          const day = parseInt(dateString.substring(8, 10));
          const hours = parseInt(dateString.substring(11, 13));
          const minutes = parseInt(dateString.substring(14, 16));
          const seconds = parseInt(dateString.substring(17, 19));
      
          const localDate = new Date(year, month, day, hours, minutes, seconds);
          const endDate = new Date(localDate.getTime() + (apt.appointement_duration!=null ? apt.appointement_duration: 15) * 60000);
      
          return {
              title: "consultation",
              start: localDate,
              end: endDate,
              id: apt.appointement_id,
              photo: apt.profil_picture,
              sexe: apt.patient_sexe,
              patient: apt.patient_firstname + " " + apt.patient_lastname,
              city: apt.patient_ville,
              status:apt.appointement_status
          };
      });
        setAppointments(events);
      } else if (response.status === 401) {
        console.log("unauthorized");
      }
    } catch (error) {
      console.error(error.message);
    }
  };
  const addAppointment = async () => {
    setLoadingButton(true);
    if ((date === "") || (time === "") || (duration === "")) {
      toast.error(d["insert a valid date, time and duration"][language]);
      return
    }
    const request = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        patient_id: selectedPatient,
        appointementDate: date + " " + time,
        appointementDuration: duration,
        appointementNote: note,
      }),
    };
    
    try {
      let response = await fetch(
        "https://doctor.shls.care/api/doctor/appointement/add",
        request,
        {
          mode: "cors",
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        setShowModal(false);
        setDate("")
        setTime("")
        setDuration("")
        setNote("")
        setSelectedPatient("")
        toast.success(data.message);
    getAppointments("https://doctor.shls.care/api/doctor/appointements");
      } else if (response.status === 409) {
        setError(data.message);
      } else if (response.status === 401) {
        console.log("unauthorized");
        // history.push("/login-page");
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoadingButton(false);
    }
  };
  const getData = async (url) => {
    const request = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    try {
      let response = await fetch(url, request, { mode: "cors" });

      if (response.status === 200) {
        let data = await response.json();
        setPatients(data);
      } else if (response.status === 401) {
        console.log("unauthorized");
      }
    } catch (error) {
      console.error(error.message);
    }
  };


  // Language specific messages for the calendar
  const messages = {
    allDay: d["All day"][language],
    previous: d["Previous"][language],
    next: d["Next"][language],
    today: d["Today"][language],
    month: d["Month"][language],
    week: d["Week"][language],
    day: d["Day"][language],
    showMore: (total) => `+${total} ${d["more"][language]}`,

  };
  const formats = {

    monthHeaderFormat: (date, culture, localizer) =>
      localizer.format(date, "MMMM YYYY", culture), // Month and year
    dayHeaderFormat: (date, culture, localizer) =>
      localizer.format(date, "dddd, MMM Do", culture), // Day and month

  };
  return (
    <div className="row">
      <Modal
        centered
        show={showModal}
        onHide={() => {
          setShowModal(false);
          setDate("");
          setTime("");
          setDuration("")
          setNote("");
          setSelectedPatient("");
        }}
        className="modal fade"
        id="addOrderModal"
      >
        <div role="document">
          <div className="modal-header d-flex align-items-center">
            <h5 className="modal-title fs-24" style={{ color: "#112361" }}>

              {d["new appointment"][language]}
            </h5>
            <span
              type="button"
              onClick={() => {
                setShowModal(false);
                setDate("")
                setTime("")
                setDuration("")
                setNote("")
                setSelectedPatient("")
              }}
              className="fs-24"
              data-dismiss="modal"
            >
              <span>&times;</span>
            </span>
          </div>
          <div className="modal-body">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                addAppointment();
              }}
            >
              <div className="form-group">
                <label className="text-black font-w500">Patient</label>
                {<select
                  style={selectedPatient === "" ? {} : { color: "black" }}
                  name="patients"
                  className="form-control"
                  onChange={(e) => {
                    setSelectedPatient(e.target.value);
                  }}
                  required="required"
                >
                  <option value="">
                    {d["choose a patient"][language]}
                  </option>
                  {patients.accepted?.map((p, index) => {
                    return (
                      <option key={p.patient_id} value={p.patient_id}>
                        {p.patient_firstname} {p.patient_lastname}
                      </option>
                    );
                  })}
                </select>
                }

              </div>
              <div className="form-group">
                <label className="text-black font-w500">
                  {d["appointment date"][language]}
                </label>
                <input
                  style={date === "" ? {} : { color: "black" }}
                  required
                  min={minDate}
                  id="dateInput"
                  type="date"
                  className="form-control"
                  value={date}
                  onChange={(e) => {
                    setDate(e.target.value);
                  }}
                />
              </div>
              <div className="form-group">
                <label className="text-black font-w500">
                  {d["appointment time"][language]}
                </label>
                <input
                  required
                  style={time === "" ? {} : { color: "black" }}
                  type="time"
                  className="form-control"
                  placeholder={d["appointment time"][language]}
                  value={time}
                  onChange={(e) => {
                    setTime(e.target.value);
                  }}
                />
              </div>
              <div className="form-group">
                <label className="text-black font-w500">{d["duration"][language]}</label>
                <select
                  name="duration"
                  className="form-control"
                  onChange={(e) => {
                    setDuration(e.target.value);
                  }}
                  value={duration}
                  required="required"
                >
                  <option className="fs-18" value="">
                    {d["select a duration"][language]}
                  </option>
                  <option className="fs-18" value={15}>15 mins</option>
                  <option className="fs-18" value={30}>30 mins</option>
                  <option className="fs-18" value={45}>45 mins</option>
                  <option className="fs-18" value={60}>60 mins</option>
                </select>
              </div>
              <div className="form-group">
                <label className="text-black font-w500">
                  {d["note"][language]}
                </label>
                <textarea
                  style={note === "" ? {} : { color: "black" }}
                  placeholder={d["note"][language]}
                  className="form-control"
                  rows="5"
                  value={note}
                  onChange={(e) => {
                    setNote(e.target.value);
                  }}
                ></textarea>
              </div>

              {error && (
                <div style={{ color: "red" }} className="text-center">
                  {d[`${error}`]?.[language]}
                </div>
              )}
              <div className="form-group text-center pt-3">
                {loadingButton ? (
                  <button type="button" className="btn btn-primary">
                    <Spinner animation="border" />
                  </button>
                ) : (
                  <button type="submit" className="btn btn-block btn-save" >
                    {d["save"][language]}
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </Modal>
      <div className="col-12">
        <Link to="appointments" >
          <button className="btn GoldmanSemiBold fs-18 mb-3 float-right px-3 ml-2" style={{ background: "#3453DF", color: "#fff" }}>
            {d["All appointments"][language]}
          </button>
        </Link>
        <Link
          to={"#"}
          onClick={() => {

            setShowModal(true)
          }}
          data-toggle="modal"
          data-target="#addOrderModal"

        >
          <button className="btn GoldmanSemiBold fs-18 mb-3 float-right px-3" style={{ background: "#2BC155", color: "#fff" }}>

            <svg className="mr-2" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_3168_5863)">
                <path d="M9.7875 2.2461C10.6172 2.2461 11.4286 2.56813 12.0141 3.12969C12.3605 3.46391 12.4852 3.63125 12.6792 4.02219C13.0017 4.67328 13.1034 5.33938 12.9895 6.05891C12.8766 6.74797 12.5231 7.39672 11.9953 7.88516C11.4717 8.36984 10.8112 8.66422 10.1344 8.71484C10.0364 8.72234 9.9375 8.72609 9.84047 8.72609C8.63766 8.72609 7.59891 8.14719 6.98953 7.13797C6.84047 6.88578 6.72328 6.59047 6.62062 6.20984L6.61781 6.19953L6.615 6.18922C6.60516 6.15406 6.60047 6.13531 6.59812 6.12547C6.58781 6.01531 6.58969 5.62625 6.59062 5.49641V5.48984V5.48328C6.59062 5.30141 6.59062 4.92453 6.60094 4.83641C6.60281 4.82563 6.61078 4.79188 6.63844 4.69063L6.63984 4.68547L6.64125 4.68031C6.71812 4.39109 7.035 3.74797 7.17609 3.56047C7.69078 2.88922 8.52281 2.3975 9.34781 2.27703C9.49172 2.25688 9.63984 2.2461 9.7875 2.2461ZM9.7875 -0.00390434C9.5325 -0.00390434 9.27703 0.0143757 9.02344 0.0514069C7.6125 0.257657 6.25312 1.06391 5.38594 2.19828C5.05781 2.62953 4.61719 3.53891 4.46719 4.10141C4.35 4.53266 4.34062 4.64047 4.34062 5.48422C4.33594 6.29516 4.35 6.44516 4.44844 6.79672C4.60781 7.38734 4.80469 7.86547 5.05781 8.29203C6.08062 9.99406 7.84781 10.9766 9.84047 10.9766C9.99328 10.9766 10.1475 10.9709 10.3031 10.9592C12.7453 10.7764 14.8125 8.86391 15.2109 6.41703C15.3984 5.24047 15.225 4.09203 14.6953 3.02328C14.3859 2.39984 14.1047 2.02016 13.575 1.50922C12.5644 0.538439 11.182 -0.00390434 9.7875 -0.00390434Z" fill="white" />
                <path d="M19.2375 4.64062C18.9469 4.75312 18.6094 5.07656 18.4594 5.38125C18.361 5.57812 18.3469 5.68594 18.3281 6.39844L18.3047 7.19531L17.5078 7.21875C16.5938 7.24687 16.4344 7.29375 16.0922 7.63594C15.8156 7.9125 15.6938 8.19375 15.6656 8.59687C15.6281 9.20625 15.9469 9.75 16.4906 10.0172C16.7438 10.1437 16.7953 10.1484 17.5406 10.1625L18.3188 10.1812L18.3375 10.9828C18.3563 11.9016 18.3985 12.0516 18.7594 12.4125C19.0735 12.7312 19.3078 12.8203 19.8281 12.8203C20.3485 12.8203 20.55 12.7359 20.8875 12.3937C21.2156 12.0562 21.2813 11.8078 21.2813 10.9219V10.1812L22.0922 10.1625C23.0016 10.1437 23.161 10.1016 23.5313 9.76875C24.2813 9.09375 24.1078 7.83281 23.1985 7.35C23.011 7.25156 22.9125 7.24219 22.1485 7.21875L21.3047 7.19531L21.2813 6.39844C21.2578 5.67656 21.2485 5.57812 21.1453 5.37187C21.0047 5.09062 20.7469 4.81875 20.475 4.68281C20.1844 4.52812 19.5656 4.50937 19.2375 4.64062Z" fill="white" />
                <path d="M9.75517 13.7887C10.0088 13.7958 10.8206 13.8722 11.1539 13.9327L11.1572 13.9331L11.1605 13.9336C12.7059 14.2087 14.1047 14.903 15.2053 15.9412C16.2867 16.9612 17.011 18.2498 17.3002 19.6692C17.3452 19.8895 17.347 19.8984 17.3316 20.2439L17.3311 20.25V20.2561C17.3147 20.677 17.31 20.6859 17.1942 20.9217L17.191 20.9283L17.1877 20.9348C17.0658 21.187 16.8689 21.382 16.6027 21.5152C16.3945 21.6173 16.2844 21.6689 15.6755 21.7078C15.3563 21.7284 13.1813 21.75 9.78001 21.75C6.48657 21.75 4.15454 21.7294 3.71907 21.7092C3.2972 21.6511 2.95173 21.5222 2.71876 21.3356C2.51861 21.1753 2.38689 20.9648 2.30486 20.6747C2.26079 20.4717 2.25329 19.8741 2.32736 19.5267C2.49564 18.7852 2.86501 17.91 3.26954 17.2936C3.92626 16.298 4.83704 15.4509 5.90392 14.8439C6.97501 14.2345 8.18486 13.8773 9.40267 13.8108L9.41626 13.8098L9.42986 13.8089C9.49923 13.8042 9.57564 13.7995 9.64173 13.7958C9.68439 13.7934 9.72283 13.7911 9.75517 13.7887ZM9.72986 11.5383C9.70033 11.5383 9.67548 11.5387 9.65626 11.5406C9.59064 11.5453 9.42189 11.5547 9.28126 11.5641C6.09376 11.7375 3.12657 13.425 1.3922 16.0547C0.834387 16.9031 0.356262 18.0422 0.131262 19.0406C-0.0140503 19.7062 -0.0140503 20.7094 0.131262 21.2531C0.54845 22.7766 1.74376 23.7328 3.4922 23.9484C3.76642 23.9822 6.78845 24 9.78095 24C12.5672 24 15.3277 23.985 15.8203 23.9531C16.6266 23.9016 17.0485 23.8031 17.6016 23.5312C18.3094 23.1797 18.8719 22.6219 19.2141 21.9141C19.4813 21.3703 19.5516 21.0656 19.5797 20.3437C19.6031 19.8141 19.5891 19.6312 19.5047 19.2188C18.7266 15.3984 15.5859 12.4359 11.5547 11.7187C11.1005 11.6367 10.0805 11.5383 9.72986 11.5383Z" fill="white" />
              </g>
              <defs>
                <clipPath id="clip0_3168_5863">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>

            {d["new appointment"][language]}
          </button>
        </Link>


      </div>
      <div className="col-12">
        <div className="card">
          <div className="card-body big-calendar-card">
            <BigCalendar
              localizer={localizer}
              events={appointments}
              views={["month", "week"]}
              defaultView="week"
              startAccessor="start"
              endAccessor="end"
              style={{ minHeight: 660 }}
              messages={messages}
              components={{ event: CustomEvent }}
              formats={formats}
              min={new Date(0, 0, 0, 6, 0, 0)}
              max={new Date(0, 0, 0, 22, 0, 0)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Calendar;
