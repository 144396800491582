import Cookies from "js-cookie";
import React from "react";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { Table, Badge } from "reactstrap";
import useFetch from "../../helpers/useFetch";
import Unit from "../components/custom/Unit";
import TypePill from "../components/custom/TypePill";
import d from "../../resources/dictionary.json";
import { LanguageContext } from "../../resources/languageContext";
import { useContext } from "react";
import { Spinner } from "react-bootstrap";
import moment from "moment";

function DetailedValues({ title, selected, selectedLevel }) {
  const { language } = useContext(LanguageContext);

  const token = Cookies.get("jwt");
  const request = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  const { data } = useFetch(
    `https://doctor.shls.care/api/doctor/params/values?date=${selected}`,
    request
  );
  const derivedData = useMemo(
    () => dataFilter(data, title),
    [data, title]
  );

  if (!data) {
    return (
      <div className="d-flex justify-content-center align-items-center p-4">
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }


  return (
    <>

      <div className="detailed values " >
        <Table className="table" style={{ backgroundColor: "rgba(52,83,223,.04" }}>

          <tbody style={{ padding: "1.5rem" }}>
            {derivedData && derivedData[selectedLevel + "_values"]?.map((item, index) => (
         
              <tr key={index}>
                <td className="GoldmanSemiBold fs-20 pl-4" style={{ color: "#2C47DB" }}>{item.patient_firstname + " " + item.patient_lastname}</td>
                <td>{moment(item.date).locale("en").format("h:mm A - DD MMM YYYY")}</td>
        
                <td className="GoldmanSemiBold fs-20 pl-4" style={{ color: "#2C47DB" }}>{item[selectedLevel + "_value"]}{title==="Pressure"&& "/"+item["sys_"+selectedLevel + "_value"]} <Unit type={title} /></td>
                <td>
                  {(() => {
                    if (selectedLevel === "high") {
                      return <button className="badge border-0 px-4" style={{ color: "#ED1607", backgroundColor: "rgba(237,22,7,0.08)", boxShadow: "none", borderRadius: "12px" }}>
                        {d[selectedLevel][language]}
                      </button>
                    }else if(selectedLevel==="bad"){
                      return <button className="badge border-0 px-4" style={{ color: "#FFA500", backgroundColor: "rgba(255,165,0,0.08)", boxShadow: "none", borderRadius: "12px" }}>
                        {d["low"][language]}
                      </button>
                    }else{
                      return <button className="badge border-0 px-4" style={{ color: "#2BC155", backgroundColor: "rgba(43,193,85,0.08)", boxShadow: "none", borderRadius: "12px" }}>
                        {d[selectedLevel][language]}
                      </button>
                    }
                  })()
                  }
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );
}

function dataFilter(data, title) {
  let newObj = [];
  let newTitle;
  if (title === "Hemoglobin") {
    newTitle = "hemoglobine"
  } else if (title === "BMI") {
    newTitle = "BMI"
  }else if (title === "Triglycerides") {
    newTitle = "triglycerid"
   } else if (title === "Triglyceride") {
    newTitle = "triglycerid"
  } else if (title === "uricAcid") {
    newTitle = "uricAcid"
  }else if (title === "Uric Acid") {
    newTitle = "uricAcid"
  } else if (title === "Physical activity") {
    newTitle = "steps"
  } else if (title === "physical activity") {
    newTitle = "steps"
  }else if (title === "Sportive Activity") {
    newTitle = "sportive"
  } else {
    newTitle = title.toLowerCase();
  }
  newObj = data[`${newTitle}_values`];
  return newObj;
}

export default DetailedValues;
