import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../../images/LOGOSH.png";
import d from "../../../resources/dictionary.json";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { LanguageContext } from "../../../resources/languageContext";

const Register = () => {
  const { language } = useContext(LanguageContext);
  const [registrationData, setRegistrationData] = useState({});
  const [validation, setValidation] = useState("");
  const special = [
    "Allergologie",
    "Anatomie et Cytologie Pathologiques",
    "Andrologie",
    "Anesthésiologie-réanimation",
    "Angiologie",
    "Biologie médicale",
    "Cardiologie et maladies vasculaires",
    "Chirurgie Cardio-vasculaire",
    "Chirurgie digestive",
    "Chirurgie generale",
    "Chirurgie maxillo-faciale",
    "Chirurgie orthopdique",
    "Chirurgie pediatrique",
    "Chirurgie Plastique",
    "Chirurgie thoracique",
    "Chirurgie viscerale",
    "Dermatologie et vénérologie",
    "Endocrinologie et métabolismes",
    "Gastro-entérologie et hépatologie",
    "Gynécologie-Obstetrique",
    "Génétique médicale",
    "Gériatrie",
    "Hématologie et onco-hématologie",
    "Indefinie",
    "Infectiologie",
    "Information Médicale DIM",
    "Informatique Médicale et Technologie",
    "Medecine d'expertise",
    "Medecine de l'addiction",
    "Medecine du sport",
    "Medecine legale",
    "Médecine d'urgence",
    "Médecine du travail",
    "Médecine générale",
    "Médecine hyperbare",
    "Médecine interne",
    "Médecine nucléaire",
    "Médecine nutritionnelle",
    "Médecine physique et de réadaptation",
    "Médecin Légiste",
    "Neuro-chirurgie",
    "Neurologie",
    "Néphrologie",
    "O.R.L.",
    "Oncologie médicale",
    "Oncologie radiothérapique ou R...",
    "Ophtalmologie",
    "Pharmacologie",
    "Pneumologie",
    "Psychiatrie",
    "Psychiatrie infanto-juvenile",
    "Pédiatrie",
    "Radiodiagnostic et Imagerie médicale",
    "Rhumatologie",
    "Santé publique et médecine sociale",
    "Stomatologie",
    "Toxicologie",
    "Urologie"
  ]
  const handleBlur = (e) => {
    const newRegistrationData = { ...registrationData };
    newRegistrationData[e.target.name] = e.target.value;
    setRegistrationData(newRegistrationData);
  };

  const register = async () => {
    const request = {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
      body: JSON.stringify({
        firstname: registrationData.firstname,
        lastname: registrationData.lastname,
        speciality: registrationData.speciality,
        address: registrationData.address,
        city: registrationData.city,
        email: registrationData.email,
        phone: registrationData.phone,
        password: registrationData.password,
        sexe: registrationData.sex,
      }),
    };
    try {
      let response = await fetch(
        "https://doctor.shls.care/api/auth/doctors/register",
        request,
        {
          mode: "cors",
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        Cookies.set("jwt", data.accessToken, { expires: 15 });
        // window.location.href = `${process.env.PUBLIC_URL}/`
        window.location.href = "/";
      } else {
        toast.error(data.message);
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (registrationData.password !== registrationData.passwordConfirm) {
      setValidation(d["passwords don't match"][language]);
    } else {
      register();
    }
  };
  return (
    <div className="row justify-content-center  align-items-center " style={{background: '#EFF0F8'}}>
      <div className="col-md-8 my-5">
        <div className="authincation-content">
          <div className="row no-gutters">
            <div className="col-xl-12">
              <div className="auth-form">
                <div className="text-center mb-3">
                  <Link to={"/"}><img src={logo} alt="" style={{ height: "200px" }} /></Link>

                </div>
                <h4 className="text-center mb-4 text-white">
                  {d["sign up to your account"][language]}
                </h4>
                <form action="" onSubmit={submitHandler}>
                  <div className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <label className="mb-1 text-white">
                          {" "}
                          <strong>{d["firstname"][language]}</strong>{" "}
                        </label>
                        <input
                          style={{ color: "black" }}
                          value={registrationData.firstname}
                          required
                          type="text"
                          className="form-control"
                          placeholder={d["firstname"][language]}
                          name="firstname"
                          onChange={handleBlur}
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label className="mb-1 text-white">
                          {" "}
                          <strong>{d["lastname"][language]}</strong>{" "}
                        </label>
                        <input
                          style={{ color: "black" }}
                          required
                          type="text"
                          className="form-control"
                          placeholder={d["lastname"][language]}
                          name="lastname"
                          onChange={handleBlur}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <label className="mb-1 text-white ">
                          {" "}
                          <strong>{d["speciality"][language]}</strong>{" "}
                        </label>
                        <select
                          style={{ color: "black" }}
                          required
                          className="form-control"
                          name="speciality"
                          value={registrationData.speciality}
                          onChange={handleBlur}
                        >
                          <option value="">{d["speciality"][language]}</option>
                          {special.map((spec) => {
                            return <option value={spec}>{spec}</option>
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label className="mb-1 text-white ">
                          {" "}
                          <strong>Email</strong>{" "}
                        </label>
                        <input
                          style={{ color: "black" }}
                          required
                          type="email"
                          className="form-control"
                          placeholder="email@email.com"
                          name="email"
                          onChange={handleBlur}
                          value={registrationData.email}
                        />
                      </div>
                    </div>
                  </div>



                  <div className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <label className="mb-1 text-white ">
                          {" "}
                          <strong>{d["phone"][language]}</strong>{" "}
                        </label>
                        <input
                          style={{ color: "black" }}
                          required
                          type="phone"
                          className="form-control"
                          placeholder="555 555 555"
                          name="phone"
                          onChange={handleBlur}
                          value={registrationData.phone}
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label className="mb-1 text-white ">
                          {" "}
                          <strong>{d["address"][language]}</strong>{" "}
                        </label>
                        <input
                          style={{ color: "black" }}
                          required
                          type="text"
                          className="form-control"
                          placeholder={d["address"][language]}
                          name="address"
                          onChange={handleBlur}
                          value={registrationData.address}
                        />
                      </div>
                    </div>
                  </div>


                  <div className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <label className="mb-1 text-white ">
                          {" "}
                          <strong>{d["city"][language]}</strong>{" "}
                        </label>
                        <input
                          style={{ color: "black" }}
                          required
                          type="text"
                          className="form-control"
                          placeholder={d["city"][language]}
                          name="city"
                          onChange={handleBlur}
                          value={registrationData.city}
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label className="mb-1 text-white ">
                          {" "}
                          <strong>{d["sex"][language]}</strong>{" "}
                        </label>
                        <select
                          style={{ color: "black" }}
                          required
                          className="form-control"
                          name="sex"
                          value={registrationData.sex}
                          onChange={handleBlur}
                        >
                          <option value="">{d["sex"][language]}</option>
                          <option value="male">{d["male"][language]}</option>
                          <option value="female">{d["female"][language]}</option>
                        </select>
                        {/* <input
                      style={{ color: "black" }}
                      required
                      type="email"
                      className="form-control"
                      placeholder="email@email.com"
                      name="email"
                      onChange={handleBlur}
                    /> */}
                      </div>
                    </div>
                  </div>



                  <div className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <label className="mb-1 text-white">
                          {" "}
                          <strong>{d["password"][language]}</strong>{" "}
                        </label>
                        <input
                          style={{ color: "black" }}
                          required
                          minLength="6"
                          type="password"
                          className="form-control"
                          placeholder="******"
                          name="password"
                          onChange={handleBlur}
                          value={registrationData.password}
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label className="mb-1 text-white">
                          {" "}
                          <strong>{d["confirm password"][language]}</strong>{" "}
                        </label>
                        <input
                          style={{ color: "black" }}
                          required
                          minLength="6"
                          type="password"
                          className="form-control"
                          placeholder="******"
                          name="passwordConfirm"
                          onChange={handleBlur}
                          value={registrationData.passwordConfirm}

                        />

                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <small>
                        <div className="form-check">
                          <input className="form-check-input text-secondary " required type="checkbox" id="flexCheckDefault" style={{ height: 'auto !important' }} />
                          <label className="form-check-label text-light" for="flexCheckDefault">
                            {d["I agree to the"][language]} <a href="/terms-&-conditions-page" className=" text-light font-weight-bold"><u>{d["terms & conditions"][language]}</u></a>
                          </label>
                        </div>
                      </small>
                    </div>
                  </div>



                  <div className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <p style={{ color: "white" }}>{validation}</p>
                      </div>
                    </div>
                  </div>


                  <div className="text-center mt-4">
                    <button
                      type="submit"
                      className="btn bg-white text-primary btn-block"
                      onClick={() => submitHandler}
                    >
                      {" "}
                      {d["sign up"][language]}{" "}
                    </button>
                  </div>
                </form>
                <div className="new-account mt-3">
                  <p className="text-white">
                    {d["already have an account"][language]}?{" "}
                    <Link
                      className="text-white"
                      to="/login-page"
                      style={{ textDecoration: "underline" }}
                    >
                      {d["log in"][language]}
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
