import moment from "moment";
import React from "react";
import Cookies from "js-cookie";
import { useState, useContext, useMemo, Fragment, useEffect } from "react";
import { Spinner, Button, Table } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { LanguageContext } from "../../../resources/languageContext";
import d from "../../../resources/dictionary.json";
import { Doughnut } from "react-chartjs-2";
import ReactApexChart from "react-apexcharts";
import TypePill from "./TypePill";

function Details({ recent }) {

  const { language } = useContext(LanguageContext);
  const token = Cookies.get("jwt");
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const handleToggleShow = (id) => {
    if (id === selectedId) {
      setSelectedId("");
    } else {
      setSelectedId(id);
    }
  };

  //inputs
  const [inputs, setInputs] = useState({ type: "1", period: "day", fasting: "", venous: "" });
  const handleInputChange = (e) => {
    setInputs((prv) => ({ ...prv, [e.target.name]: e.target.value }));
    
  };

  const [type, setType] = useState("");
  const [fasting, setFasting] = useState("");
  const [venous, setVenous] = useState("");
  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const request = {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      const response = await fetch(
        `https://doctor.shls.care/api/doctor/patients/stats?id=${id}&parameter=${inputs.type}&date=${inputs.period}`,
        request,
        { mode: "cors" }
      );
      const data = await response.json();
      console.log(data);
      if (response.status === 200) {

        setType(switchFn(inputs.type));
        setFasting(inputs.fasting);
        setVenous(inputs.venous);
        setData(data.data);
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData()
  }, [inputs]);
  const datalvl2 = useMemo(() => {
    let newArray = [...data];

    if (type === switchFn("1")) {
        if (fasting !== "" && fasting !== "not important") {
            newArray = newArray.filter(obj => obj.glycemia_period === fasting);
        }

        if (venous === "venous") {
            newArray = newArray.filter(obj => obj.glycemia_venous_blood_sugar === true);
        } else if (venous === "capillary") {
            newArray = newArray.filter(obj => obj.glycemia_venous_blood_sugar === false || obj.glycemia_venous_blood_sugar === null);
        }
    }

    return newArray;
}, [data, fasting, venous, type]);
 

  const filteredData = useMemo(() => {
    const interval = switchInterval(type);
    let arrayData = [];
    let arrayData2 = [];
    let arraySeries = [];
    let arrayTime = [];
    let lowCount = 0;
    let goodCount = 0;
    let highCount = 0;
    if (type === switchFn("5")) {
      datalvl2.map((obj) => {
        arrayData.push(obj[`${type}_diastolic_value`]);
        arrayData2.push(obj[`${type}_sysolic_value`]);
        arrayTime.push(Date.parse(obj[`date`]));
        if (
          +obj[`${type}_diastolic_value`] < interval.lowDia ||
          +obj[`${type}_sysolic_value`] < interval.lowSys
        ) {
          return lowCount++;
        } else if (
          +obj[`${type}_diastolic_value`] > interval.highDia ||
          +obj[`${type}_sysolic_value`] > interval.highSys
        ) {
          return highCount++;
        } else {
          return goodCount++;
        }
      });
      arraySeries = [
        {
          name: d["diastolic"][language],
          type: "line",
          data: arrayData,
        },
        {
          name: d["systolic"][language],
          type: "line",
          data: arrayData2,
        },

      ];
    } else {
      datalvl2.map((obj) => {

        (() => {
          if (type === "uricAcid")
            arrayData.push(obj[`uricacid_value`]);
          else if (type === "triglyceride")
            arrayData.push(obj[`triglycerid_value`]);

          else
            arrayData.push(obj[`${type}_value`]);
        })()


        arrayTime.push(Date.parse(obj[`date`]));
        if (obj[`${type}_value`] < interval.low) {
          lowCount++;
        } else if (obj[`${type}_value`] > interval.high) {
          highCount++;
        } else {
          goodCount++;
        }
      });
      arraySeries = [
        {
          name: d[type][language] ?? "",
          type: "line",
          data: arrayData,
        },
      ];
    }
    return { arraySeries, arrayTime, lowCount, goodCount, highCount };
  }, [data]);

  const doughnutOptions = {
    maintainAspectRatio: true,
    cutoutPercentage: 75,
    weight:1,
    responsive: true,
    title: {
      display: true,
      text: !type ? "" : d[type][language],
      fontSize: 18,
    },
  };
  const doughnutDataSet = { 
    borderWidth: 1,
    labels: [d["low"][language], d["good"][language], d["high"][language]],
    datasets: [
      {
        data: [
          filteredData.lowCount,
          filteredData.goodCount,
          filteredData.highCount,
        ],
        borderWidth: 3,
        borderColor: "rgba(255,255,255,1)",
        backgroundColor: [
          "#FFA500",
          "#2BC155",
          "#ED1607",
        ],
        hoverBackgroundColor: [
          "rgba(255, 165, 0, 0.08)",
          "rgba(43, 193, 85, 0.08)",
          "rgba(237, 22, 7, 0.08)",
        ],
      },
    ],
  };

  console.log(doughnutDataSet);
  const lineOptions = {
    chart: {
      height: 350,
      type: "area",
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },

    stroke: {
      width: [3, 3],
      colors: ["#247BA0", "#FF1654"],
      curve: "straight",
    },
    legend: {
      show: false,
      position: "top",
      horizontalAlign: "left",
    },
    colors: ["#247BA0", "#FF1654"],
    xaxis: {
      type: "datetime",
      categories: filteredData?.arrayTime,
    },
    markers: {
      size: [8],
      strokeWidth: [0],
      strokeColors: ["#247BA0", "#FF1654"],
      border: 0,
      colors: ["#247BA0", "#FF1654"],
      hover: {
        size: 10,
      },
    },
    // yaxis: [{
    //   title: {
    //     text: "",
    //   },
    // },]
  };
  const lineSeries = filteredData.arraySeries;


  const [statType, setStatType] = useState("graph")


  function switchFn(type) {
    switch (type) {
      case "1":
        return "glycemia";
      case "2":
        return "hba1c";
      case "3":
        return "hemoglobine";
      case "4":
        return "creatinine";
      case "5":
        return "pressure";
      case "6":
        return "temperature";
      case "7":
        return "thyroide_tsh";
      case "8":
        return "weight";
      case "9":
        return "spo2";
      case "11":
        return "uricacid";
      case "12":
        return "triglycerid";
      case "13":
        return "cholesterol";
      case "14":
        return "steps";
      case "15":
        return "sportive";
      default:
        return "";
    }
  }

  function switchInterval(type) {
    switch (type) {
      case "glycemia":
        return { low: 80, high: 120 };
      case "hba1c":
        return { low: 5, high: 6 };
      case "hemoglobine":
        return { low: 12, high: 18 };
      case "creatinine":
        return { low: 0.7, high: 1.3 };
      case "pressure":
        return { lowSys: 100, highSys: 140, lowDia: 60, highDia: 89 };
      case "temperature":
        return { low: 36.5, high: 37.5 };
      case "thyroide_tsh":
        return { low: 0.3, high: 5.5 };
      case "weight":
        return { low: 18.5, high: 24.9 };
      case "spo2":
        return { low: 80, high: 100 };
      case "thyroide_t3":
        return { low: 0.3, high: 5.5 };
      case "thyroide_t4":
        return { low: 0.3, high: 5.5 };
      case "uricAcid":
        return { low: 3.5, high: 7.2 };
      case "triglyceride":
        return { low: 1.5, high: 1.99 };
      case "cholesterol":
        return { low: 1, high: 2.3 };
      case "steps":
        return { low: 5000, high: 999999999999999 };
      case "sportive":
        return { low: 20, high: 600 };
      default:
        return "";
    }
  }




  return (
    <>
      <div className="container-fluid pt-0">
        <div className="row">
          <div className="col-6">
            <div className="row mb-3">
              <div className={`form-group mb-0 ${inputs.type === "1" ? "col-3" : "col-8"}`}>
                <select
                  id="type"
                  name="type"
                  style={{ color: "black" }}
                  value={inputs.type}
                  className="form-control form-control-lg"
                  onChange={handleInputChange}
                >
                  <option value="1">{d["glycemia"][language]}</option>
                  <option value="2">Hba1c</option>
                  <option value="3">{d["hemoglobin"][language]}</option>
                  <option value="4">{d["creatinine"][language]}</option>
                  <option value="5">{d["pressure"][language]}</option>
                  <option value="6">{d["temperature"][language]}</option>
                  <option value="7">TSH</option>
                  <option value="8">{d["weight"][language]}</option>
                  <option value="9">Spo2</option>
                  <option value="11">{d["uricAcid"][language]}</option>
                  <option value="12">{d["triglyceride"][language]}</option>
                  <option value="13">{d["cholesterol"][language]}</option>
                  <option value="14">{d["physical activity"][language]}</option>
                  <option value="15">{d["sportive"][language]}</option>
                </select>
              </div>
              <div className={inputs.type === "1" ? "col-3" : "col-4"}>
                <select
                  id="period"
                  name="period"
                  style={{ color: "black" }}
                  value={inputs.period}
                  className="form-control form-control-lg"
                  onChange={handleInputChange}
                >
                  <option value="day">{d["daily"][language]}</option>
                  <option value="week">{d["weekly"][language]}</option>
                  <option value="month">{d["monthly"][language]}</option>
                  <option value="year">{d["yearly"][language]}</option>
                </select>
              </div>
              {inputs.type === "1" && (
                <>

                  <div className="col-md-3 form-group">
                    <select
                      id="time"
                      name="fasting"
                      style={{ color: "black" }}
                      value={inputs.fasting}
                      className="form-control form-control-lg"
                      onChange={handleInputChange}
                    >
                      <option value="">{d["all"][language]}</option>
                      <option value="fasting">{d["fasting"][language]}</option>
                      <option value="postparandial">
                        {d["postparandial"][language]}
                      </option>
                      <option value="preprandial">{d["preprandial"][language]}</option>
                    </select>
                  </div>
                  <div className="col-md-3 form-group">
                    <select title={d["measure type"][language]}
                      id="type"
                      name="venous"
                      style={{ color: "black" }}
                      value={inputs.venous}
                      className="form-control form-control-lg"
                      onChange={handleInputChange}
                    >
                      <option value="">{d["all"][language]}</option>
                      <option value="venous">{d["venous glycemia"][language]}</option>
                      <option value="capillary">{d["capillary glycemia"][language]}</option>

                    </select>
                  </div>
                </>
              )}
            </div>
            {loading ?

              <div className="d-flex justify-content-center align-items-center p-5">
                <Spinner animation="border" variant="primary" />
              </div>
              :
              <div className="table-container">
                <table className="table card-table" style={{ backgroundColor: "rgba(52,83,223,.04", marginTop: "1.5rem", borderRadius: "1rem", borderTop: "0" }}>
                  <tbody>
                    {datalvl2.length!=0 && datalvl2.map((item, key) => (
                      <Fragment key={item[`${type}_id`]}>
                        <tr
                          style={{ cursor: "pointer" }}
                          key={key}
                          onClick={() => handleToggleShow(item[`${type}_id`])}
                        >
                          <td className="fs-20 pl-4" style={{ color: "#112361" }}>{d[type][language]}</td>
                          <td className="GoldmanNormal fs-20" style={{ color: "#112361", opacity: "0.64" }}>{moment(item.date).format("h:mm - DD/MM/YYYY")}</td>
                          <td className="GoldmanSemiBold fs-20" style={{ color: "#2C47DB" }}>
                            {
                              (() => {
                                if (type === "pressure")
                                  return (+item[`${type}_sysolic_value`]).toFixed(2) +
                                    "/" + (+item[`${type}_diastolic_value`]).toFixed(2)
                                else
                                  return +item[`${type}_value`]
                              })()
                            }
                            {" "}
                            <Unit type={type} />
                            {type === "steps" ? "/" + ((item[`${type}_value`] * 0.001) / 1.5).toFixed(1) + "Km" : ""}
                            {type === "sportive" ? " | " + d[item[`${type}_type`]][language] : ""}
                          </td>
                          <td>
                            {selectedId === item[`${type}_id`] ? (
                              <i className="fa fa-chevron-up"></i>
                            ) : (
                              <i className="fa fa-chevron-down"></i>
                            )}


                          </td>
                        </tr>

                        {selectedId === item[`${type}_id`] ? (
                          <tr>
                            <td colSpan={5}>
                              <div className="row">
                                <div className="col-12 widget-timeline dz-scroll ps ps--active-y">
                                  <ul className="timeline">
                                    {
                                      <li>
                                        <div className="timeline-badge success"></div>
                                        <span
                                          to={"#"}
                                          className="timeline-panel text-muted"
                                        >
                                          <h6 className="mb-0">
                                            {d["value"][language]}:{" "}
                                            <strong className="text-success">
                                              {type === "pressure"
                                                ? (+item[
                                                  `${type}_sysolic_value`
                                                ]).toFixed(2) +
                                                "/" +
                                                (+item[
                                                  `${type}_diastolic_value`
                                                ]).toFixed(2)
                                                : (+item[`${type}_value`]).toFixed(
                                                  2
                                                )}{" "}
                                              <Unit type={type} />
                                              {type === "steps" ? "/" + ((item[`${type}_value`] * 0.001) / 1.5).toFixed(1) + "Km" : ""}
                                              {type === "sportive" ? " | " + item[`${type}_type`] : ""}
                                            </strong>
                                          </h6>
                                        </span>
                                      </li>
                                    }

                                    {item[`${type}_cardiac_frequency`] && (
                                      <li>
                                        <div className="timeline-badge primary"></div>
                                        <span
                                          to={"#"}
                                          className="timeline-panel text-muted"
                                        >
                                          <h6 className="mb-0">
                                            {d["cardiac frequency"][language]}:{" "}
                                            <strong className="text-primary">
                                              {item[`${type}_cardiac_frequency`]}{" "}
                                              bpm
                                            </strong>
                                          </h6>
                                        </span>
                                      </li>
                                    )}
                                    {item[`${type}_note`] && (
                                      <li>
                                        <div className="timeline-badge danger"></div>
                                        <span
                                          to={"#"}
                                          className="timeline-panel text-muted"
                                        >
                                          <h6 className="mb-0">
                                            <strong className="text-danger">
                                              Note:
                                            </strong>{" "}
                                            {item[`${type}_note`]}
                                          </h6>
                                        </span>
                                      </li>
                                    )}
                                    {item[`bmi_value`] && (
                                      <li>
                                        <div className="timeline-badge warning"></div>
                                        <span
                                          to={"#"}
                                          className="timeline-panel text-muted"
                                        >
                                          <h6 className="mb-0">
                                            BMI:{" "}
                                            <strong className="text-warning">
                                              {(+item[`bmi_value`])?.toFixed(2)}
                                            </strong>{" "}
                                          </h6>
                                        </span>
                                      </li>
                                    )}
                                    {item[`glycemia_tookmedication`]&& (
                                      <li>
                                        <div className="timeline-badge warning"></div>
                                        <span
                                          to={"#"}
                                          className="timeline-panel text-muted"
                                        >
                                          <h6 className="mb-0">
                                          {d["took medication"][language]}:{" "}
                                            <strong className="text-warning">
                                              {item[`glycemia_tookmedication`]}
                                            </strong>{" "}
                                          </h6>
                                        </span>
                                      </li>
                                    )}
                                    {item[`glycemia_venous_blood_sugar`] && (
                                      <li>
                                        <div className="timeline-badge primary"></div>
                                        <span
                                          to={"#"}
                                          className="timeline-panel text-muted"
                                        >
                                          <h6 className="mb-0">
                                            {d["location of collection"][language]}:{" "}
                                            <strong className="text-primary">
                                              {item[`glycemia_venous_blood_sugar`]}{" "}
                                              
                                            </strong>
                                          </h6>
                                        </span>
                                      </li>
                                    )}
                                    {item[`${type}_period`] && (
                                      <li>
                                        <div className="timeline-badge info"></div>
                                        <span
                                          to={"#"}
                                          className="timeline-panel text-muted"
                                        >
                                          <h6 className="mb-0">
                                            {d["period"][language]}:{" "}
                                            <strong className="text-info">
                                              {
                                                (()=>{
                                                  if ((item[`${type}_period`]==="not important") ||(item[`${type}_period`]==="fasting")){
                                                    return d[item[`${type}_period`]][language]
                                                  } else {
                                                    return item[`${type}_period`]
                                                  }
                                                })()
                                              }
                                            </strong>{" "}
                                          </h6>
                                        </span>
                                      </li>
                                    )}
                                  </ul>
                                </div>
                              </div>
                            </td>
                          </tr>
                        )
                          : null}
                      </Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
            }

          </div>
          <div className="col-6">
            <div className="row stat-row mb-3">
              <div className="col-6 px-0">
                <button className={"btn btn-block btn-stat btn-graph" + (statType === "graph" && " btn-selected")}
                  onClick={() => setStatType("graph")}
                >{d["Graphic Curve"][language]}</button>
              </div>
              <div className="col-6 px-0">
                <button className={"btn btn-block btn-stat btn-pie " + (statType === "pie" && " btn-selected")}
                  onClick={() => setStatType("pie")}
                >{d["Graphic Pie"][language]}</button>
              </div>
            </div>
            {
              loading?
              <div className="d-flex justify-content-center align-items-center p-5">
                <Spinner animation="border" variant="primary" />
              </div>
              : (datalvl2.length!==0 && (statType === "pie" ?
              <Doughnut data={doughnutDataSet} options={doughnutOptions} /> :
              <ReactApexChart options={lineOptions} series={lineSeries} statType="area" />))
              
            }
          </div>

        </div>
      </div>
    </>
  );

}


function Unit({ type }) {
  switch (type) {
    case "spo2":
      return <>%</>;
    case "hemoglobine":
      return <>g/dL</>;
    case "creatinine":
      return <>mg/L</>;
    case "weight":
      return <>kg</>;
    case "glycemia":
      return <>mg/dL</>;
    case "pressure":
      return <>mmHg</>;
    case "thyroid":
      return <>mUl/L</>;
    case "temperature":
      return <>°</>;
    case "hba1c":
      return <>%</>;
    case "cholesterol":
      return <>mg/dL</>;
    case "uricAcid":
      return <>mg/dL</>;
    case "triglyceride":
      return <>mg/dL</>;
    case "uricacid":
      return <>mg/dL</>;
    case "triglycerid":
      return <>mg/dL</>;
    case "thyroide":
      return <>uIU/mL</>;
    case "thyroide_t3":
      return <>nmol/L</>;
    case "thyroide_t4":
      return <>mIU/mL</>;
    case "steps":
      return <>Steps</>;
    case "sportive":
      return <>Min</>;
    default:
      return <>{type}</>;
  }
}

export default Details;
