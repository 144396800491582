import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import { LanguageContext } from "../../resources/languageContext";
import d from "../../resources/dictionary.json";
import { useContext } from "react";
import Cookies from "js-cookie";
import jwt from "jsonwebtoken";
import { toast } from "react-toastify";
// import "./DoctorProfile.css"

function ContactUs() {
    const { language } = useContext(LanguageContext);
    const token = Cookies.get("jwt");

    const [inputs, setInputs] = useState({
        title: "",
        message: "",
    });
   
    const handleinputs = (e) => {
        setInputs((prv) => ({ ...prv, [e.target.name]: e.target.value }));
      };
    const sendEmail = async () => {
        toast.success(d["message sent"][language]);
        return
        const request = {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify({
                password: inputs.title,
                oldpassword: inputs.title,
            }),
        };
        try {
            let response = await fetch(
                "https://doctor.shls.care/api/doctor/changePassword",
                request
            );
            if (response.status === 200) {
                toast.success(d["success"][language]);
            } else if (response.status === 409) {
                toast.error(d["error"][language]);
            }
        } catch (error) {
            console.error(error.message);
            toast.error(d["error"][language]);
        }
    };

    return (
        <Fragment>
            <div className="row justify-content-center">
                <div className="col-9 ">
                    <div className="card setting-card" >
                        <div className="card-header px-0" >
                            <h4 className="fs-24 mb-0 GoldmanSemiBold">
                                {d["contact us"][language]}
                            </h4>
                        </div>
                        <PerfectScrollbar
                            className=" card-body height620 dlab-scroll"
                        >
                            <form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    if ((inputs.title !== "") && (inputs.message !== "")) {
                                        sendEmail();
                                    }else{
                                        toast.error(d["please fill in all fields"][language]);
                                    }
                                }}
                            >

                                <div className="settings-form mt-3">


                                    <div className="row ">
                                        <div className="col-12 px-0">
                                            <div className="right-inputs">
                                                <div className="form-group col-md-12 px-0">
                                                    <label>{d["title"][language]}</label>
                                                    <input
                                                        value={inputs.title}
                                                        style={
                                                            inputs.title === ""
                                                                ? {}
                                                                : { color: "black" }
                                                        }
                                                        name="title"
                                                        onChange={handleinputs}
                                                        className="form-control"
                                                    />
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-12 px-0">
                                            <div className="left-inputs">

                                                <div className="form-group col-md-12 px-0">
                                                    <label>
                                                        {d["message"][language]}
                                                    </label>
                                                    <textarea
                                                        value={inputs.message}
                                                        style={{
                                                            color: inputs.message ? "black" : "initial",
                                                            height: "auto"
                                                        }}
                                                        name="message"
                                                        onChange={handleinputs}
                                                        className="form-control"
                                                        rows={6}
                                                    />
                                                </div>
                                                <div className="form-group col-md-12 px-0">
                                                    <button className="btn btn-block btn-primary GoldmanSemiBold save-btn" type="submit">
                                                        {d["send"][language]}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </form>
                        </PerfectScrollbar>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default ContactUs;
