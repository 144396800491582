import React from "react";
import { useHistory } from "react-router-dom";
import TypePill from "./TypePill";
import Unit from "./Unit";
import { useContext } from "react";
import d from "../../../resources/dictionary.json";
import { LanguageContext } from "../../../resources/languageContext";
import moment from "moment";

export default function RecentActivity({ item }) {
  const { language } = useContext(LanguageContext);
  const history = useHistory();
  return (
    <div
      className="recent-activity mb-2 "
      style={{ cursor: "pointer" }}
      onClick={() => history.push(`/patient:${item.patient_id}`)}
    >
      <div className="d-flex justify-content-between">
        <span
          style={{
            color: "black",
            fontWeight: "600",
            textOverflow: "ellipsis",
            paddingRight: "10px",
          }}
        >
          {item.patient_firstname} {item.patient_lastname}
        </span>
        <span>
          <TypePill type={item.type} />
        </span>
      </div>
      <div className="d-flex justify-content-between">
      
        <span className="">{moment(item?.date).format("YYYY/MM/DD-h:m")}</span>
        <span className="">
        {
   (() => {
       if (item.type === "pressure")
          return <span>
          {Number(item[`${item.type}_sysolic_value`])}/
          {Number(item[`${item.type}_diastolic_value`])}
        </span>
       else if (item.type === "uricAcid")
       return +item[`uricacid_value`]
       else if (item.type === "thyroid")
       return +item[`thyroide_tsh_value`]
       else if (item.type === "triglyceride")
       return +item[`triglycerid_value`]
       else
          return +item[`${item.type}_value`]
   })()
}
         
          {item.type==="steps"?<Unit type={d[item.type][language]} /> :<Unit type={item.type} /> }
          
          {item.type==="steps"? "/"+((item[`${item.type}_value`]*0.001)/1.5).toFixed(1)+"Km":""}
        </span>
      </div>
      <hr />
    </div>
  );
}

