import React from "react";

export default function About() {
  return (
    <div className="row">
      <div className="col-md-6">
        <div className="card">
          <div className="card-body">
            <h4 className="text-center GoldmanSemiBold">PLATEFORME SOLIDE</h4>
            <p>
              Une solution Web bien conçue pour mieux convenir aux médecins
              désireux de suivre les activités de leurs patients diabétiques et
              d'évaluer leurs progrès tout au long du parcours
            </p>
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <div className="card">
          <div className="card-body">
            <h4 className="text-center GoldmanSemiBold">SÉCURITÉ</h4>
            <p>
              Nous prêtons une attention particulière à la sécurité, nos
              protocoles de connexion sont sécurisés grâce à TLS pour crypter
              les demandes entrantes / sortantes.
            </p>
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <div className="card">
          <div className="card-body">
            <h4 className="text-center GoldmanSemiBold">SYSTÈME DE GESTION</h4>
            <p>
              Le suivi des patients est très simple et peut se faire en quelques
              clics seulement, un médecin peut parcourir / naviguer dans les
              profils et déterminer quels patients vont bien et lesquels ne le
              sont pas.
            </p>
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <div className="card">
          <div className="card-body">
            <h4 className="text-center GoldmanSemiBold">HISTORIQUE</h4>
            <p>
              Les activités de chaque patient seront synchronisées une fois
              connecté à l'internet et devraient être affichées quotidiennement.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
