import React, { useState, useEffect, useContext, useMemo } from "react";
import { Link, useHistory,useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { Spinner, Tab, Nav, Table, Modal,Badge } from "react-bootstrap";
import patient from "../../images/avatar/patient.png";
import woman from "../../images/avatar/woman.png";
import moment from "moment";
import d from "../../resources/dictionary.json";
import { LanguageContext } from "../../resources/languageContext";
import { toast } from "react-toastify";
import StatusBadge from "../components/custom/StatusBadge";
import Swal from "sweetalert2";
import RDV from "../../images/rdv.svg"
import "./Appointments.css"

function Appointments() {
  const { language } = useContext(LanguageContext);
  const token = Cookies.get("jwt");
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [keyword, setKeyword] = useState("all");
  const [error, setError] = useState("");

  // modal elements
  const [loadingButton, setLoadingButton] = useState(false);
  const [patients, setPatients] = useState("");
  const [selectedPatient, setSelectedPatient] = useState("");
  const [selectedAppointment, setSelectedAppointment] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [duration, setDuration] = useState("");
  const [note, setNote] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  //min date for the modal input field
  const [minDate, setMinDate] = useState(null);
  useEffect(() => {
    const min_date = new Date();
    setMinDate(moment(min_date).format("YYYY-MM-DD"));
  }, []);

  //appointments from database
  const [fetchData, setFetchData] = useState("");
  const { data } = fetchData;
  const [all, setAll] = useState([])
  const [accepted, setAccepted] = useState([])
  const [pending, setPending] = useState([])
  const [request, setRequest] = useState([])
  const [rejected, setRejected] = useState([])
  const [canceled, setCanceled] = useState([])
  const [expired, setExpired] = useState([])
  const location = useLocation();
  // api call to set the appointment.
  const addAppointment = async () => {
    setLoadingButton(true);
    if ((date === "") || (time === "") || (duration === "")) {
      toast.error(d["insert a valid date, time and duration"][language]);
      return
    }
    const request = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        patient_id: selectedPatient,
        appointementDate: date + " " + time,
        appointementDuration: duration,
        appointementNote: note,
      }),
    };
    
    try {
      let response = await fetch(
        "https://doctor.shls.care/api/doctor/appointement/add",
        request,
        {
          mode: "cors",
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        setShowModal(false);
        setDate("")
        setTime("")
        setDuration("")
        setNote("")
        setSelectedPatient("")
        toast.success(data.message);
    getAppointements("https://doctor.shls.care/api/doctor/appointements");
      } else if (response.status === 409) {
        setError(data.message);
      } else if (response.status === 401) {
        console.log("unauthorized");
        // history.push("/login-page");
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoadingButton(false);
    }
  };
  const handleReject = (id) => {
    Swal.fire({
      text: d["do you really want to reject appointment ?"][language],
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: d['confirm'][language]
    }).then((result) => {
      if (result.isConfirmed) {
        rejectAppointment(id)
      }
    })
  }

  const rejectAppointment = async (id) => {

    setLoadingButton(true);
    const request = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        appointement_id: id,
        checker: false,
      }),
    };
    try {
      let response = await fetch(
        "https://doctor.shls.care/api/doctor/appointement/responce",
        request,
        {
          mode: "cors",
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        toast.error(d["rejected"][language]);
        getAppointements("https://doctor.shls.care/api/doctor/appointements");
      } else if (response.status === 409) {
        setError(data.message);
      } else if (response.status === 401) {
        console.log("unauthorized");
        // history.push("/login-page");
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoadingButton(false);
    }

  };

  const handleCancel = (id, pid) => {
    Swal.fire({
      text: d["do you really want to cancel appointment ?"][language],
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: d['confirm'][language]
    }).then((result) => {
      if (result.isConfirmed) {
        cancelAppointment(id, pid)
      }
    })
  }

  const cancelAppointment = async (id, pid) => {
    setLoadingButton(true);
    const request = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        appointement_status: "canceled",
        appointement_id: id,
        patient_id: pid,
      }),
    };

    try {
      let response = await fetch(
        "https://doctor.shls.care/api/doctor/appointement/cancel",
        request,
        {
          mode: "cors",
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        toast.error(d["canceled"][language]);
        getAppointements("https://doctor.shls.care/api/doctor/appointements");
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoadingButton(false);
    }

  };
  const acceptAppointment = async () => {
    setLoadingButton(true);
    if ((date === "") || (time === "") || (duration === "")) {
      toast.error(d["insert a valid date and time"][language]);
      return
    }
    const request = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        appointement_id: selectedAppointment,
        responce_back: true,
        appointementDate: date + " " + time,
        appointementDuration: duration,
        appointementNote: note,
      }),
    };
    try {

      let response = await fetch(
        "https://doctor.shls.care/api/doctor/appointement/responce/request",
        request,
        {
          mode: "cors",
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        setShowModal2(false);
        setDate("")
        setTime("")
        setDuration("")
        setNote("")
        setSelectedAppointment("")
        toast.success(d["accepted"][language]);
        getAppointements("https://doctor.shls.care/api/doctor/appointements");
      } else if (response.status === 409) {
        setError(data.message);
      } else if (response.status === 401) {
        console.log("unauthorized");
        // history.push("/login-page");
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoadingButton(false);
    }
  };

  const updateAppointment = async () => {
    setLoadingButton(true);
    if ((date === "") || (time === "")) {
      toast.error(d["insert a valid date and time"][language]);
      return
    }
    const request = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        appointement_id: selectedAppointment,
        patient_id: selectedPatient,
        appointementDuration: duration,
        date: date + " " + time + ":00",

      }),
    };

    try {

      let response = await fetch(
        "https://doctor.shls.care/api/doctor/appointement/update",
        request,
        {
          mode: "cors",
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        setShowUpdateModal(false);
        setDate("")
        setTime("")
        setDuration("")
        setNote("")
        setSelectedAppointment("")
        setSelectedPatient("")
        toast.success(d["updated"][language]);
        getAppointements("https://doctor.shls.care/api/doctor/appointements");
      } else if (response.status === 409) {
        setError(data.message);
      } else if (response.status === 401) {
        console.log("unauthorized");
        // history.push("/login-page");
      } else {

        toast.error(data.message);
      }
    } catch (error) {
      console.log("******");
      console.log(error.message);
      toast.error(error.message);
    } finally {
      setLoadingButton(false);
    }
  };
  const getAppointements = async (url) => {
    const request = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    try {
      let response = await fetch(url, request, { mode: "cors" });
      if (response.status === 200) {
        let data = await response.json();
        setFetchData(data);
      } else if (response.status === 401) {
        console.log("unauthorized");
      }
      setLoading(false);
    } catch (error) {
      console.error(error.message);
    }
  };
  const getData = async (url) => {
    const request = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    try {
      let response = await fetch(url, request, { mode: "cors" });

      if (response.status === 200) {
        let data = await response.json();
        setPatients(data);
      } else if (response.status === 401) {
        console.log("unauthorized");
      }
      setLoading(false);
    } catch (error) {
      console.error(error.message);
    }
  };
  useEffect(() => {
    if(location.state==="5"){
      setKeyword("rejected");
    }if(location.state==="4"){
      setKeyword("request");
    }
    getAppointements("https://doctor.shls.care/api/doctor/appointements");
    getData("https://doctor.shls.care/api/doctor/patients");

    return () => {
      // ignore = true;
      setLoading(false);
    };
  }, [token]);

  const filter = () => {
    if (data !== undefined) {
      let newData = [...data];
      let notExpired = newData.filter((obj) => {
        return Date.parse(obj.appointement_date) > Date.now() || obj.appointement_status === "request";
      });

      let expired = newData.filter((obj) => {
        return Date.parse(obj.appointement_date) < Date.now();
      });
      expired.reverse();
      let statusFiltered = notExpired.filter(
        (obj) => obj.appointement_status === keyword
      );

      setAll(notExpired);
      setAccepted(notExpired.filter(
        (obj) => obj.appointement_status === "accepted"
      ))
      setPending(notExpired.filter(
        (obj) => obj.appointement_status === "sending" || obj.appointement_status === "updated"
      ))
      setRequest(newData.filter(
        (obj) => obj.appointement_status === "request"
      ))
      setRejected(notExpired.filter(
        (obj) => obj.appointement_status === "rejected"
      ))
      setCanceled(notExpired.filter(
        (obj) => obj.appointement_status === "canceled"
      ))
      setExpired(expired)

      if (keyword === "all") {
        return notExpired;
      } else if (keyword === "expired") {
        return expired;
      } else if (keyword === "request") {
        return notExpired.filter(
          (obj) => obj.appointement_status === "request"
        )
      } else if (keyword === "canceled") {
        return notExpired.filter(
          (obj) => obj.appointement_status === "canceled"
        )
      } else if (keyword === "pending") {
        return notExpired.filter(
          (obj) => obj.appointement_status === "updated" || obj.appointement_status === "sending"
        )
      } else {
        return statusFiltered;
      }
    }
  };
  //memo
  const memoAppointments = useMemo(filter, [keyword, data]);
  if (loading)
    return (
      <div className="d-flex justify-content-center align-items-center h-75">
        <Spinner animation="border" variant="primary" />
      </div>
    );

  return (
    <Tab.Container defaultActiveKey={location.state ?? 1}>
      <div className="row d-md-flex d-block mb-4 ">
        <div className="card-action card-tabs mb-md-0 mb-3  mr-auto">
          <Nav as="ul" className="nav nav-tabs tabs-lg ">
            <Nav.Item as="li" key={1}>
              <Nav.Link

                eventKey={1}
                onClick={() => {
                  setKeyword("all");
                }}
              >
                {all?.length + " " + d["all"][language]}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li" key={2}>
              <Nav.Link
                eventKey={2}
                onClick={() => {
                  setKeyword("accepted");
                }}
              >
                {accepted?.length + " " + d["accepted s"][language]}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li" key={3}>
              <Nav.Link
                eventKey={3}
                onClick={() => {
                  setKeyword("pending");
                }}
              >
                {pending?.length + " " + d["pending"][language]}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li" key={4}>
              <Nav.Link
                eventKey={4}
                onClick={() => {
                  setKeyword("request");
                }}
              >
                {request?.length + " " + d["requested s"][language]}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li" key={5}>
              <Nav.Link
                eventKey={5}
                onClick={() => {
                  setKeyword("rejected");
                }}
              >
                {rejected?.length + " " + d["rejected s"][language]}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li" key={6}>
              <Nav.Link
                eventKey={6}
                onClick={() => {
                  setKeyword("expired");
                }}
              >
                {expired?.length + " " + d["expired s"][language]}
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </div>
        <div>

          <Link
            to={"#"}
            onClick={() => {

              setShowModal(true)
            }}
            data-toggle="modal"
            data-target="#addOrderModal"
            className="btn btn-primary follow-btn GoldmanSemiBold fs-18"
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_3168_5863)">
                <path d="M9.7875 2.2461C10.6172 2.2461 11.4286 2.56813 12.0141 3.12969C12.3605 3.46391 12.4852 3.63125 12.6792 4.02219C13.0017 4.67328 13.1034 5.33938 12.9895 6.05891C12.8766 6.74797 12.5231 7.39672 11.9953 7.88516C11.4717 8.36984 10.8112 8.66422 10.1344 8.71484C10.0364 8.72234 9.9375 8.72609 9.84047 8.72609C8.63766 8.72609 7.59891 8.14719 6.98953 7.13797C6.84047 6.88578 6.72328 6.59047 6.62062 6.20984L6.61781 6.19953L6.615 6.18922C6.60516 6.15406 6.60047 6.13531 6.59812 6.12547C6.58781 6.01531 6.58969 5.62625 6.59062 5.49641V5.48984V5.48328C6.59062 5.30141 6.59062 4.92453 6.60094 4.83641C6.60281 4.82563 6.61078 4.79188 6.63844 4.69063L6.63984 4.68547L6.64125 4.68031C6.71812 4.39109 7.035 3.74797 7.17609 3.56047C7.69078 2.88922 8.52281 2.3975 9.34781 2.27703C9.49172 2.25688 9.63984 2.2461 9.7875 2.2461ZM9.7875 -0.00390434C9.5325 -0.00390434 9.27703 0.0143757 9.02344 0.0514069C7.6125 0.257657 6.25312 1.06391 5.38594 2.19828C5.05781 2.62953 4.61719 3.53891 4.46719 4.10141C4.35 4.53266 4.34062 4.64047 4.34062 5.48422C4.33594 6.29516 4.35 6.44516 4.44844 6.79672C4.60781 7.38734 4.80469 7.86547 5.05781 8.29203C6.08062 9.99406 7.84781 10.9766 9.84047 10.9766C9.99328 10.9766 10.1475 10.9709 10.3031 10.9592C12.7453 10.7764 14.8125 8.86391 15.2109 6.41703C15.3984 5.24047 15.225 4.09203 14.6953 3.02328C14.3859 2.39984 14.1047 2.02016 13.575 1.50922C12.5644 0.538439 11.182 -0.00390434 9.7875 -0.00390434Z" fill="white" />
                <path d="M19.2375 4.64062C18.9469 4.75312 18.6094 5.07656 18.4594 5.38125C18.361 5.57812 18.3469 5.68594 18.3281 6.39844L18.3047 7.19531L17.5078 7.21875C16.5938 7.24687 16.4344 7.29375 16.0922 7.63594C15.8156 7.9125 15.6938 8.19375 15.6656 8.59687C15.6281 9.20625 15.9469 9.75 16.4906 10.0172C16.7438 10.1437 16.7953 10.1484 17.5406 10.1625L18.3188 10.1812L18.3375 10.9828C18.3563 11.9016 18.3985 12.0516 18.7594 12.4125C19.0735 12.7312 19.3078 12.8203 19.8281 12.8203C20.3485 12.8203 20.55 12.7359 20.8875 12.3937C21.2156 12.0562 21.2813 11.8078 21.2813 10.9219V10.1812L22.0922 10.1625C23.0016 10.1437 23.161 10.1016 23.5313 9.76875C24.2813 9.09375 24.1078 7.83281 23.1985 7.35C23.011 7.25156 22.9125 7.24219 22.1485 7.21875L21.3047 7.19531L21.2813 6.39844C21.2578 5.67656 21.2485 5.57812 21.1453 5.37187C21.0047 5.09062 20.7469 4.81875 20.475 4.68281C20.1844 4.52812 19.5656 4.50937 19.2375 4.64062Z" fill="white" />
                <path d="M9.75517 13.7887C10.0088 13.7958 10.8206 13.8722 11.1539 13.9327L11.1572 13.9331L11.1605 13.9336C12.7059 14.2087 14.1047 14.903 15.2053 15.9412C16.2867 16.9612 17.011 18.2498 17.3002 19.6692C17.3452 19.8895 17.347 19.8984 17.3316 20.2439L17.3311 20.25V20.2561C17.3147 20.677 17.31 20.6859 17.1942 20.9217L17.191 20.9283L17.1877 20.9348C17.0658 21.187 16.8689 21.382 16.6027 21.5152C16.3945 21.6173 16.2844 21.6689 15.6755 21.7078C15.3563 21.7284 13.1813 21.75 9.78001 21.75C6.48657 21.75 4.15454 21.7294 3.71907 21.7092C3.2972 21.6511 2.95173 21.5222 2.71876 21.3356C2.51861 21.1753 2.38689 20.9648 2.30486 20.6747C2.26079 20.4717 2.25329 19.8741 2.32736 19.5267C2.49564 18.7852 2.86501 17.91 3.26954 17.2936C3.92626 16.298 4.83704 15.4509 5.90392 14.8439C6.97501 14.2345 8.18486 13.8773 9.40267 13.8108L9.41626 13.8098L9.42986 13.8089C9.49923 13.8042 9.57564 13.7995 9.64173 13.7958C9.68439 13.7934 9.72283 13.7911 9.75517 13.7887ZM9.72986 11.5383C9.70033 11.5383 9.67548 11.5387 9.65626 11.5406C9.59064 11.5453 9.42189 11.5547 9.28126 11.5641C6.09376 11.7375 3.12657 13.425 1.3922 16.0547C0.834387 16.9031 0.356262 18.0422 0.131262 19.0406C-0.0140503 19.7062 -0.0140503 20.7094 0.131262 21.2531C0.54845 22.7766 1.74376 23.7328 3.4922 23.9484C3.76642 23.9822 6.78845 24 9.78095 24C12.5672 24 15.3277 23.985 15.8203 23.9531C16.6266 23.9016 17.0485 23.8031 17.6016 23.5312C18.3094 23.1797 18.8719 22.6219 19.2141 21.9141C19.4813 21.3703 19.5516 21.0656 19.5797 20.3437C19.6031 19.8141 19.5891 19.6312 19.5047 19.2188C18.7266 15.3984 15.5859 12.4359 11.5547 11.7187C11.1005 11.6367 10.0805 11.5383 9.72986 11.5383Z" fill="white" />
              </g>
              <defs>
                <clipPath id="clip0_3168_5863">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>

            {d["new appointment"][language]}
          </Link>
          {/* new appointment */}
          <Modal
            centered
            show={showModal}
            onHide={() => {
              setShowModal(false);
              setDate("");
              setTime("");
              setDuration("")
              setNote("");
              setSelectedPatient("");
            }}
            className="modal fade"
            id="addOrderModal"
          >
            <div role="document">
              <div className="modal-header d-flex align-items-center">
                <h5 className="modal-title fs-24" style={{ color: "#112361" }}>

                  {d["new appointment"][language]}
                </h5>
                <span
                  type="button"
                  onClick={() => {
                    setShowModal(false);
                    setDate("")
                    setTime("")
                    setDuration("")
                    setNote("")
                    setSelectedPatient("")
                  }}
                  className="fs-24"
                  data-dismiss="modal"
                >
                  <span>&times;</span>
                </span>
              </div>
              <div className="modal-body">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    addAppointment();
                  }}
                >
                  <div className="form-group">
                    <label className="text-black font-w500">Patient</label>
                    <select
                      style={selectedPatient === "" ? {} : { color: "black" }}
                      name="patients"
                      className="form-control"
                      onChange={(e) => {
                        setSelectedPatient(e.target.value);
                      }}
                      required="required"
                    >
                      <option className="fs-18" value="">
                        {d["choose a patient"][language]}
                      </option>
                      {patients.accepted?.map((p, index) => {
                        return (
                          <option className="fs-18" key={p.patient_id} value={p.patient_id}>
                            {p.patient_firstname} {p.patient_lastname}
                          </option>
                        );
                      })}
                    </select>
                    

                  </div>
                  <div className="form-group">
                    <label className="text-black font-w500">
                      {d["appointment date"][language]}
                    </label>
                    <input
                      style={date === "" ? {} : { color: "black" }}
                      required
                      min={minDate}
                      id="dateInput"
                      type="date"
                      className="form-control"
                      value={date}
                      onChange={(e) => {
                        setDate(e.target.value);
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label className="text-black font-w500">
                      {d["appointment time"][language]}
                    </label>
                    <input
                      required
                      style={time === "" ? {} : { color: "black" }}
                      type="time"
                      className="form-control"
                      placeholder={d["appointment time"][language]}
                      value={time}
                      onChange={(e) => {
                        setTime(e.target.value);
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label className="text-black font-w500">{d["duration"][language]}</label>
                    <select
                      name="duration"
                      className="form-control"
                      onChange={(e) => {
                        setDuration(e.target.value);
                      }}
                      value={duration}
                      required="required"
                    >
                      <option className="fs-18" value="">
                        {d["select a duration"][language]}
                      </option>
                          <option className="fs-18" value={15}>15 mins</option>
                          <option className="fs-18" value={30}>30 mins</option>
                          <option className="fs-18" value={45}>45 mins</option>
                          <option className="fs-18" value={60}>60 mins</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label className="text-black font-w500">
                      {d["note"][language]}
                    </label>
                    <textarea
                      style={note === "" ? {} : { color: "black" }}
                      placeholder={d["note"][language]}
                      className="form-control"
                      rows="5"
                      value={note}
                      onChange={(e) => {
                        setNote(e.target.value);
                      }}
                    ></textarea>
                  </div>

                  {error && (
                    <div style={{ color: "red" }} className="text-center">
                      {d[`${error}`]?.[language]}
                    </div>
                  )}
                  <div className="form-group text-center pt-3">
                    {loadingButton ? (
                      <button type="button" className="btn btn-primary">
                        <Spinner animation="border" />
                      </button>
                    ) : (
                      <button type="submit" className="btn btn-block btn-save" >
                        {d["save"][language]}
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </Modal>
          {/* accept patient's appointment */}
          <Modal
            centered
            show={showModal2}
            onHide={() => {
              setShowModal2(false);
              setDate("");
              setTime("");
              setDuration("")
              setNote("");
              setSelectedAppointment("")
            }}
            className="modal fade"
            id="addOrderModal"
          >
            <div role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">
                    {d["accept appointment"][language]}
                  </h5>
                  <button
                    type="button"
                    onClick={() => {
                      setShowModal2(false);
                      setDate("")
                      setTime("")
                      setDuration("")
                      setNote("")
                      setSelectedAppointment("")
                    }}
                    className="close m-1"
                    data-dismiss="modal"
                  >
                    <span>&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      acceptAppointment();
                    }}
                  >

                    <div className="form-group">
                      <label className="text-black font-w500">
                        {d["appointment date"][language]}
                      </label>
                      <input
                        style={date === "" ? {} : { color: "black" }}
                        required
                        min={minDate}
                        id="dateInput"
                        type="date"
                        className="form-control"
                        value={date}
                        onChange={(e) => {
                          setDate(e.target.value);
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <label className="text-black font-w500">
                        {d["appointment time"][language]}
                      </label>
                      <input
                        required
                        style={time === "" ? {} : { color: "black" }}
                        type="time"
                        className="form-control"
                        placeholder={d["appointment time"][language]}
                        value={time}
                        onChange={(e) => {
                          setTime(e.target.value);
                        }}
                      />
                    </div>
                    <div className="form-group">
                    <label className="text-black font-w500">{d["duration"][language]}</label>
                    <select
                      name="duration"
                      className="form-control"
                      onChange={(e) => {
                        setDuration(e.target.value);
                      }}
                      value={duration}
                      required="required"
                    >
                      <option className="fs-18" value="">
                        {d["select a duration"][language]}
                      </option>
                          <option className="fs-18" value={15}>1 - 15 mins</option>
                          <option className="fs-18" value={30}>16 - 30 mins</option>
                          <option className="fs-18" value={45}>31 - 45 mins</option>
                          <option className="fs-18" value={60}>46 - 60 mins</option>
                    </select>
                  </div>
                    <div className="form-group">
                      <label className="text-black font-w500">
                        {d["note"][language]}
                      </label>
                      <textarea
                        style={note === "" ? {} : { color: "black" }}
                        placeholder={d["note"][language]}
                        className="form-control"
                        rows="5"
                        value={note}
                        onChange={(e) => {
                          setNote(e.target.value);
                        }}
                      ></textarea>
                    </div>

                    {error && (
                      <div style={{ color: "red" }} className="text-center">
                        {d[`${error}`]?.[language]}
                      </div>
                    )}
                    <div className="form-group text-center pt-3">
                      {loadingButton ? (
                        <button type="button" className="btn btn-primary">
                          <Spinner animation="border" />
                        </button>
                      ) : (
                        <button type="submit" className="btn btn-primary">
                          {d["accept appointment"][language]}
                        </button>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Modal>

          {/* update accepted appointment modal */}
          <Modal
            centered
            show={showUpdateModal}
            onHide={() => {
              setShowUpdateModal(false);
              setDate("");
              setTime("");
              setDuration("")
              setNote("");
              setSelectedAppointment("")
              setSelectedPatient("")
            }}
            className="modal fade"
            id="addOrderModal"
          >
            <div role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">
                    {d["update appointment"][language]}
                  </h5>
                  <button
                    type="button"
                    onClick={() => {
                      setShowUpdateModal(false);
                      setDate("")
                      setTime("")
                      setDuration("")
                      setNote("")
                      setSelectedAppointment("")
                      setSelectedPatient("")
                    }}
                    className="close m-1"
                    data-dismiss="modal"
                  >
                    <span>&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      updateAppointment();
                    }}
                  >

                    <div className="form-group">
                      <label className="text-black font-w500">
                        {d["new appointment date"][language]}
                      </label>
                      <input
                        style={date === "" ? {} : { color: "black" }}
                        required
                        min={minDate}
                        id="dateInput"
                        type="date"
                        className="form-control"
                        value={date}
                        onChange={(e) => {
                          setDate(e.target.value);
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <label className="text-black font-w500">
                        {d["new appointment time"][language]}
                      </label>
                      <input
                        required
                        style={time === "" ? {} : { color: "black" }}
                        type="time"
                        className="form-control"
                        placeholder={d["appointment time"][language]}
                        value={time}
                        onChange={(e) => {
                          setTime(e.target.value);
                        }}
                      />
                    </div>
                    <div className="form-group">
                    <label className="text-black font-w500">{d["new appointment duration"][language]}</label>
                    <select
                      name="duration"
                      className="form-control"
                      onChange={(e) => {
                        setDuration(e.target.value);
                      }}
                      value={duration}
                      required="required"
                    >
                      <option className="fs-18" value="">
                        {d["select a duration"][language]}
                      </option>
                          <option className="fs-18" value={15}>1 - 15 mins</option>
                          <option className="fs-18" value={30}>16 - 30 mins</option>
                          <option className="fs-18" value={45}>31 - 45 mins</option>
                          <option className="fs-18" value={60}>46 - 60 mins</option>
                    </select>
                  </div>


                    {error && (
                      <div style={{ color: "red" }} className="text-center">
                        {d[`${error}`]?.[language]}
                      </div>
                    )}
                    <div className="form-group text-center pt-3">
                      {loadingButton ? (
                        <button type="button" className="btn btn-primary">
                          <Spinner animation="border" />
                        </button>
                      ) : (
                        <button type="submit" className="btn btn-primary">
                          {d["update appointment"][language]}
                        </button>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Modal>

        </div>
      </div>

      <div className="row">

        <div className="col-12 px-0">
          <div
            className="tab-pane fade show active"
            id="navpills-1"
            role="tabpanel"
          >
            <Tab.Content>
              {
                memoAppointments?.length === 0 ?
                  <div className="col-12 no-data">
                    <img src={RDV} alt="user" />
                    <p className="m-0">
                      {d["No appointment for the moment"][language]}
                    </p>
                    <Link
                      to={"#"}
                      onClick={() => {
                        setShowModal(true);
                      }}
                      data-toggle="modal"
                      data-target="#addOrderModal"
                      className="btn btn-primary follow-btn  GoldmanSemiBold fs-18"
                    >
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.7875 2.24625C10.6172 2.24625 11.4286 2.56828 12.0141 3.12984C12.3605 3.46406 12.4852 3.6314 12.6792 4.02234C13.0017 4.67343 13.1034 5.33953 12.9895 6.05906C12.8766 6.74812 12.5231 7.39687 11.9953 7.88531C11.4717 8.37 10.8112 8.66437 10.1344 8.715C10.0364 8.7225 9.9375 8.72625 9.84047 8.72625C8.63766 8.72625 7.59891 8.14734 6.98953 7.13812C6.84047 6.88593 6.72328 6.59062 6.62062 6.21L6.61781 6.19968L6.615 6.18937C6.60516 6.15422 6.60047 6.13547 6.59812 6.12562C6.58781 6.01547 6.58969 5.6264 6.59062 5.49656V5.49V5.48343C6.59062 5.30156 6.59062 4.92468 6.60094 4.83656C6.60281 4.82578 6.61078 4.79203 6.63844 4.69078L6.63984 4.68562L6.64125 4.68047C6.71812 4.39125 7.035 3.74812 7.17609 3.56062C7.69078 2.88937 8.52281 2.39765 9.34781 2.27718C9.49172 2.25703 9.63984 2.24625 9.7875 2.24625ZM9.7875 -0.00375175C9.5325 -0.00375175 9.27703 0.0145283 9.02344 0.0515594C7.6125 0.25781 6.25312 1.06406 5.38594 2.19843C5.05781 2.62968 4.61719 3.53906 4.46719 4.10156C4.35 4.53281 4.34062 4.64062 4.34062 5.48437C4.33594 6.29531 4.35 6.44531 4.44844 6.79687C4.60781 7.3875 4.80469 7.86562 5.05781 8.29218C6.08062 9.99422 7.84781 10.9767 9.84047 10.9767C9.99328 10.9767 10.1475 10.9711 10.3031 10.9594C12.7453 10.7766 14.8125 8.86406 15.2109 6.41718C15.3984 5.24062 15.225 4.09218 14.6953 3.02343C14.3859 2.4 14.1047 2.02031 13.575 1.50937C12.5644 0.538591 11.182 -0.00375175 9.7875 -0.00375175Z" fill="white" />
                        <path d="M19.2375 4.64062C18.9468 4.75312 18.6093 5.07656 18.4593 5.38125C18.3609 5.57812 18.3468 5.68594 18.3281 6.39844L18.3046 7.19531L17.5078 7.21875C16.5937 7.24687 16.4343 7.29375 16.0921 7.63594C15.8156 7.9125 15.6937 8.19375 15.6656 8.59687C15.6281 9.20625 15.9468 9.75 16.4906 10.0172C16.7437 10.1437 16.7953 10.1484 17.5406 10.1625L18.3187 10.1812L18.3375 10.9828C18.3562 11.9016 18.3984 12.0516 18.7593 12.4125C19.0734 12.7312 19.3078 12.8203 19.8281 12.8203C20.3484 12.8203 20.55 12.7359 20.8875 12.3937C21.2156 12.0562 21.2812 11.8078 21.2812 10.9219V10.1812L22.0921 10.1625C23.0015 10.1437 23.1609 10.1016 23.5312 9.76875C24.2812 9.09375 24.1078 7.83281 23.1984 7.35C23.0109 7.25156 22.9125 7.24219 22.1484 7.21875L21.3046 7.19531L21.2812 6.39844C21.2578 5.67656 21.2484 5.57812 21.1453 5.37187C21.0046 5.09062 20.7468 4.81875 20.475 4.68281C20.1843 4.52812 19.5656 4.50937 19.2375 4.64062Z" fill="white" />
                        <path d="M9.75511 13.7887C10.0087 13.7958 10.8206 13.8722 11.1539 13.9327L11.1571 13.9331L11.1604 13.9336C12.7059 14.2087 14.1046 14.903 15.2053 15.9412C16.2867 16.9612 17.0109 18.2498 17.3001 19.6692C17.3451 19.8895 17.347 19.8984 17.3315 20.2439L17.331 20.25V20.2561C17.3146 20.677 17.31 20.6859 17.1942 20.9217L17.1909 20.9283L17.1876 20.9348C17.0657 21.187 16.8689 21.382 16.6026 21.5152C16.3945 21.6173 16.2843 21.6689 15.6754 21.7078C15.3562 21.7284 13.1812 21.75 9.77995 21.75C6.48651 21.75 4.15448 21.7294 3.71901 21.7092C3.29714 21.6511 2.95167 21.5222 2.7187 21.3356C2.51854 21.1753 2.38683 20.9648 2.3048 20.6747C2.26073 20.4717 2.25323 19.8741 2.3273 19.5267C2.49558 18.7852 2.86495 17.91 3.26948 17.2936C3.9262 16.298 4.83698 15.4509 5.90386 14.8439C6.97495 14.2345 8.1848 13.8773 9.40261 13.8108L9.4162 13.8098L9.4298 13.8089C9.49917 13.8042 9.57558 13.7995 9.64167 13.7958C9.68433 13.7934 9.72276 13.7911 9.75511 13.7887ZM9.7298 11.5383C9.70026 11.5383 9.67542 11.5387 9.6562 11.5406C9.59058 11.5453 9.42183 11.5547 9.2812 11.5641C6.0937 11.7375 3.12651 13.425 1.39214 16.0547C0.834326 16.9031 0.356201 18.0422 0.131201 19.0406C-0.0141113 19.7062 -0.0141113 20.7094 0.131201 21.2531C0.548389 22.7766 1.7437 23.7328 3.49214 23.9484C3.76636 23.9822 6.78839 24 9.78089 24C12.5671 24 15.3276 23.985 15.8203 23.9531C16.6265 23.9016 17.0484 23.8031 17.6015 23.5312C18.3093 23.1797 18.8718 22.6219 19.214 21.9141C19.4812 21.3703 19.5515 21.0656 19.5796 20.3437C19.6031 19.8141 19.589 19.6312 19.5046 19.2188C18.7265 15.3984 15.5859 12.4359 11.5546 11.7187C11.1004 11.6367 10.0804 11.5383 9.7298 11.5383Z" fill="white" />
                      </svg>
                      {d["new appointment"][language]}
                    </Link>
                  </div>
                  :
                  <Table className="project-bx card-table rounded table-hover fs-14 table bg-white">
                    <thead>
                      <tr>
                        <th className="Goldman-SemiBold fs-24" color="#112361">{d["note"][language]}</th>
                        <th className="Goldman-SemiBold fs-24" color="#112361">{d["appointment date"][language]}</th>
                        <th className="Goldman-SemiBold fs-24" color="#112361">{d["patient"][language]}</th>
                        <th className="Goldman-SemiBold fs-24" color="#112361">{d["status"][language]}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {memoAppointments?.map((item) => (
                        <tr
                          key={item.appointement_id}

                        >
                          <td className="title fs-20 " color="#112361">
                            {item.appointement_note || d["no note"][language]}
                          </td>
                          <td className="fs-20">
                            {item.appointement_date && (moment.parseZone(item.appointement_date).format(`HH:mm - YYYY/MM/DD`))}
                          </td>
                          <td className="Goldman-SemiBold fs-20" style={{ cursor: "pointer",color:"#2C47DB" }} onClick={() => history.push(`/patient:${item.patient_id}`)}>
                            {item.patient_firstname + " " + item.patient_lastname}
                          </td>
                          <td>

                            {
                              (() => {
                                if (keyword === "expired") {
                                  return <span className="status-badge fs-18">{d["expired"][language]}</span>
                                  
                                } else {

                                  switch (item.appointement_status) {

                                    case "accepted":
                                      return <>
                                        <StatusBadge status={item.appointement_status} />
                                        <span title={d["update"][language]} className="btn btn-warning btn-sm rounded px-2 ml-2 my-1 text-center" onClick={() => {
                                          setSelectedAppointment(item.appointement_id)
                                          setSelectedPatient(item.patient_id)
                                          setShowUpdateModal(true)
                                        }}>
                                          <i className="fa fa-pencil" aria-hidden="true"></i>
                                        </span>
                                        <span title={d["cancel"][language]} className="btn btn-danger btn-sm rounded px-2 ml-2 my-1 text-center" onClick={() => {
                                          handleCancel(item.appointement_id, item.patient_id)
                                        }}>

                                          <i className="fa fa-times" aria-hidden="true"></i>
                                        </span>
                                      </>
                                    case "pending":
                                      return <>
                                        <StatusBadge status={item.appointement_status} />
                                        <span title={d["cancel"][language]} className="btn btn-danger btn-sm rounded px-2 ml-2 my-1 text-center" onClick={() => {
                                          handleCancel(item.appointement_id, item.patient_id)
                                        }}>

                                          <i className="fa fa-times" aria-hidden="true"></i>
                                        </span>
                                      </>
                                    case "sending":
                                      return <>
                                        <StatusBadge status={item.appointement_status} />
                                        <span title={d["cancel"][language]} className="btn btn-danger btn-sm rounded px-2 ml-2 my-1 text-center" onClick={() => {
                                          handleCancel(item.appointement_id, item.patient_id)
                                        }}>

                                          <i className="fa fa-times" aria-hidden="true"></i>
                                        </span>
                                      </>
                                    case "updated":
                                      return <>
                                        <StatusBadge status={item.appointement_status} />
                                        <span title={d["cancel"][language]} className="btn btn-danger btn-sm rounded px-2 ml-2 my-1 text-center" onClick={() => {
                                          handleCancel(item.appointement_id, item.patient_id)
                                        }}>

                                          <i className="fa fa-times" aria-hidden="true"></i>
                                        </span>
                                      </>

                                    case "request":
                                      return <>
                                        <StatusBadge status={item.appointement_status} />
                                        <span title={d["accept"][language]} className="btn btn-success btn-sm rounded px-2 ml-2 my-1 text-center" onClick={() => {

                                          setSelectedAppointment(item.appointement_id)
                                          setShowModal2(true)
                                        }}>

                                          <i className="fa fa-check-square" aria-hidden="true"></i> </span>
                                        <span title={d["reject"][language]} className="btn btn-danger btn-sm rounded px-2 ml-2 my-1 text-center" onClick={() => {
                                          handleReject(item.appointement_id)

                                        }}>

                                          <i className="fa fa-window-close" aria-hidden="true"></i>
                                        </span>

                                      </>

                                    case "rejected":
                                      return <>
                                        <StatusBadge status={item.appointement_status} />

                                      </>
                                    case "canceled":
                                      return <>
                                        <StatusBadge status={item.appointement_status} />

                                      </>


                                    default:
                                      return <>
                                        <StatusBadge status={item.appointement_status} />

                                      </>
                                  }
                                }


                              })()
                            }


                          </td>

                        </tr>
                      ))}
                    </tbody>
                  </Table>
              }

            </Tab.Content>
          </div>
        </div>
      </div>

    </Tab.Container>
  );
}

export default Appointments;