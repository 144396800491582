import React, { useContext, useState } from "react";
import { Link, useHistory } from "react-router-dom";
// import logo from "../../../images/logo full.png";
import logo from "../../../images/login.png";
import d from "../../../resources/dictionary.json";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { LanguageContext } from "../../../resources/languageContext";
import "./LoginJWT.css";
import moment from "moment";
import { Spinner } from "react-bootstrap";

const LoginJWT = () => {
  const [loading, setLoading] = useState(false);
  const { language, setLanguage } = useContext(LanguageContext);
  const [loginData, setLoginData] = useState({});
  const history = useHistory();
  const handleBlur = (e) => {
    const newLoginData = { ...loginData };
    newLoginData[e.target.name] = e.target.value;
    setLoginData(newLoginData);
  };

  const login = async () => {
    setLoading(true)
    const request = {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
      body: JSON.stringify({
        email: loginData.email,
        password: loginData.password,
      }),
    };
    try {
      let response = await fetch(
        "https://doctor.shls.care/api/auth/doctors/login",
        request,
        {
          mode: "cors",
        }
      );
      let data = await response.json();
     
      if (data.success) {
        Cookies.set("jwt", data.accessToken, { expires: 15 });
        // window.location.href = `${process.env.PUBLIC_URL}/`
        window.location.href = "/";
        // history.push("/dashboard");
      } else {
        toast.error(data.message);
      }
      setLoading(false)
    } catch (err) {
      toast.error(err.message);
      setLoading(false)
    }
  };
  const submitHandler = (e) => {
    e.preventDefault();
    login();
  };
  return (
    <div className="row main">
      <div className="col-md-6 img d-flex justify-content-center align-items-center">
        <img src={logo} alt="" />
      </div>

      <div className="col-md-6 col-sm-12 login">

          {loading?
      <div className="d-flex justify-content-center align-items-center h-75">
        <Spinner animation="border" variant="primary" />
      </div>:
        <form action="" onSubmit={submitHandler}>
          <div className="login-box ">
            <h1>Welcome Back 👋</h1>
            <p>{d["sign in to your account"][language]}</p>
            <div className="form-group">
              <label>Email</label>
              <input className="form-control " type="email"
                placeholder="email@email.com"
                name="email"
                required
                onChange={handleBlur} />
            </div>
            <div className="form-group">
              <label>{d["password"][language]}</label>
              <input className="form-control " required
                type="password"
                placeholder="******"
                name="password"
                onChange={handleBlur} />
            </div>
            <Link
              className="fpwd"
              to="/forgot-password-page"
            >
              {d["forgot password"][language]}?
            </Link>
            <button type="submit"
              className="btn btn-primary btn-block "
              onClick={() => submitHandler}
            >{d["log in"][language]}</button>
            <p className="text-center fs-18">
                    {d["you don't have an account"][language]}?{" "}
                    <Link
                      className="fpwd"
                      to="/register-page"
                    >
                      {d["sign up"][language]}
                    </Link>
                  </p>
          </div>
        </form>
    }
        <footer className="login-footer" >© {moment().format("YYYY")} ALL RIGHTS RESERVED</footer>
      </div>

    </div>
  );
};

export default LoginJWT;
