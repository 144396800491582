import React, { useContext, useMemo, useState, useEffect, useCallback } from "react";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import d from "../../resources/dictionary.json";
import { LanguageContext } from "../../resources/languageContext";
import Cookies from "js-cookie";
import ChartCard from "../components/custom/ChartCard";
import DetailedValues from "./DetailedValues";
import useFetch from "../../helpers/useFetch";
import RecentActivity from "../components/custom/RecentActivity";
import "./Dashboard.css"
import { toast } from "react-toastify";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { moment } from "moment";


export default function Dashboard() {
  const token = Cookies.get("jwt");
  const { language } = useContext(LanguageContext);

  const request = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  const { data, loading } = useFetch(
    "https://doctor.shls.care/api/doctor/dashboard",
    request
  );


  const {
    total_accepted_patients,
    total_pending_patients,
    appointements,
    recent_activities,
  } = data;
  const rejectedAppointments = appointements?.filter((obj) => {
    return Date.parse(obj.appointement_date) > Date.now();
  }).filter((apt) => {
    return apt.appointment_status === "rejected"
  }).length
  const params = [{name: "glycemia", chart: "glycemiaChart"},
  { name: "Hba1c", chart: "Hba1cChart" },
  { name: "Creatinine", chart: "CreatinineCharts" }, 
  { name: "Cholesterol", chart: "CholesterolCahrt" }, 
  {name: "Triglycerides", chart: "TriglyceridesChart"},
  { name: "uricAcid", chart: "uricAcidChart" },
  { name: "Spo2", chart: "Spo2Chart" },
  { name: "Pressure", chart: "PressureChart" },
  { name: "BMI", chart: "BMI_Chart" },
  { name: "Hemoglobine", chart: "HemoglobineCHart" },
  { name: "Tsh", chart: "TshChart" },
  { name: "Temperature", chart: "Temperature" },
  { name: "sportive", chart: "sportiveChart" },
  { name: "physical activity", chart: "stepsChart" }
  ];

  const [selectedParam, setSelectedParam] = useState(params[0].name)
  const [chartName, setChartName] = useState(params[0].chart);
  const [selectedLevel, setSelectedLevel] = useState("bad")

  const handleParamChange = (value) => {
    setSelectedParam(params[value].name)
    setChartName(params[value].chart)
  }

  const [selectedPeriod, setSelectedPeriod] = useState("day")
  const arrfn = (recent_activities) => {
    let newArray = [];
    for (let key in recent_activities) {
      for (let element of recent_activities[key]) {
        let newElement = { ...element, type: key };
        newArray.push(newElement);
      }
    }
    newArray.sort((x, y) => {
      return +new Date(y.date) - +new Date(x.date);
    });
    return newArray;
  };
  const recentActivities = useMemo(
    () => arrfn(recent_activities),
    [recent_activities]
  );

  const [date, setDate] = useState(new Date());

  const events = appointements?.map((apt) => {
    return {
      date: new Date(apt.appointement_date),
      type: "consultation",
      patient: apt.patient_firstname + " " + apt.patient_lastname
    }
  })

  // Handle date change
  const handleDateChange = (newDate) => {
    setDate(newDate);
  }


  const hasEvents = (dateToCheck) => {
    return events?.some(event => event.date.toDateString() === dateToCheck.toDateString());
  };


  if (loading)
    return (
      <div className="d-flex justify-content-center align-items-center h-75">
        <Spinner animation="border" variant="primary" />
      </div>
    );
  return (
    <div className="row">
      <div className="col-lg-9">
        <div className="row">
          <div className="col-md-3 ">
            <Link to="/patients" style={{ textDecorationLine: "none" }}>
              <div className="card " style={{ borderRadius: "1rem", background: "#3453DF", opacity: "0.96" }}>
                <div className="card-body">

                  <span className="fs-20 text-white">
                    {d["total patients"][language]}
                  </span>
                  <div className="d-flex justify-content-between align-items-baseline mt-4">
                    <h2 className="text-white GoldmanSemiBold fs-32">
                      {total_accepted_patients?.[0]?.count}
                    </h2>
                    <svg width="35" height="32" viewBox="0 0 35 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M16.866 1.03148C13.4343 1.53773 10.8254 4.06273 10.4106 7.26898C10.0093 10.3627 12.1098 14.8815 14.8457 16.8065C16.7991 18.1815 18.7323 18.269 20.6856 17.0815C23.1139 15.6065 25.1943 12.069 25.5355 8.83773C25.9168 5.27523 23.542 2.10023 19.7959 1.16898C19.3879 1.06273 19.0333 1.03148 18.1704 1.01898C17.5617 1.00648 16.9797 1.01273 16.866 1.03148Z" fill="white" />
                      <path d="M11.983 16.844C11.5214 16.9002 10.3708 17.194 9.84234 17.394C7.44752 18.3002 5.52095 20.1752 4.6647 22.444C4.29009 23.444 4.16299 24.144 4.16968 25.3002C4.16968 26.1565 4.18975 26.4127 4.31016 26.819C4.7918 28.4752 5.98921 29.294 8.39073 29.6252C9.2336 29.744 26.7065 29.744 27.5493 29.6252C29.9575 29.294 31.1483 28.4752 31.6299 26.819C31.8373 26.1065 31.8373 24.5315 31.6299 23.6315C31.2352 21.9315 30.4325 20.5127 29.1816 19.3315C28.2517 18.4502 27.3286 17.869 26.1579 17.419C25.5559 17.1877 24.4454 16.9065 23.8567 16.8315L23.5557 16.8002L23.4353 17.1065C23.3751 17.2752 23.1945 17.5877 23.0406 17.8002C22.666 18.3252 21.4151 19.5315 20.3515 20.394C19.3213 21.219 18.0503 22.1752 17.97 22.1752C17.9366 22.1752 17.4951 21.8627 16.9867 21.4815C14.4045 19.5315 12.8459 18.019 12.5047 17.1065C12.4312 16.9127 12.3509 16.8065 12.284 16.8065C12.2305 16.8127 12.0967 16.8315 11.983 16.844ZM25.7566 22.2127C25.7766 22.269 25.7967 22.6315 25.7967 23.0252V23.7377H26.5593C26.9807 23.7377 27.3687 23.7565 27.4289 23.7752C27.5895 23.8315 27.5895 25.4565 27.4289 25.5127C27.3687 25.5315 26.9807 25.5502 26.5593 25.5502H25.7967V26.2815C25.7967 26.6877 25.7699 27.0565 25.7432 27.1002C25.6963 27.1565 25.4622 27.1752 24.7866 27.1627L23.8902 27.144L23.8701 26.344L23.8501 25.5502H23.0206C22.2647 25.5502 22.1844 25.5377 22.1175 25.4315C22.084 25.3627 22.0506 25.0065 22.0506 24.644C22.0506 24.2815 22.084 23.9252 22.1175 23.8565C22.1844 23.7502 22.2647 23.7377 23.0206 23.7377H23.8567V22.969C23.8567 22.544 23.8768 22.1752 23.9036 22.1565C23.9236 22.1315 24.3451 22.1127 24.8334 22.1127C25.5759 22.1127 25.7231 22.1252 25.7566 22.2127Z" fill="white" />
                    </svg>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-md-3">
            <Link to={{ pathname: "/patients", state: 2 }} style={{ textDecorationLine: "none" }}>
              <div className="card" style={{ borderRadius: "1rem", background: "#FFA500", opacity: "0.8" }}>
                <div className="card-body">

                  <span className="fs-20 text-white">
                    {d["follow requests"][language]}
                  </span>
                  <div className="d-flex justify-content-between align-items-baseline mt-4">
                    <h2 className="text-white GoldmanSemiBold fs-32">
                      {total_pending_patients?.[0]?.count}
                    </h2>
                    <svg width="35" height="32" viewBox="0 0 35 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M16.866 1.03148C13.4343 1.53773 10.8254 4.06273 10.4106 7.26898C10.0093 10.3627 12.1098 14.8815 14.8457 16.8065C16.7991 18.1815 18.7323 18.269 20.6856 17.0815C23.1139 15.6065 25.1943 12.069 25.5355 8.83773C25.9168 5.27523 23.542 2.10023 19.7959 1.16898C19.3879 1.06273 19.0333 1.03148 18.1704 1.01898C17.5617 1.00648 16.9797 1.01273 16.866 1.03148Z" fill="white" />
                      <path d="M11.983 16.844C11.5214 16.9002 10.3708 17.194 9.84234 17.394C7.44752 18.3002 5.52095 20.1752 4.6647 22.444C4.29009 23.444 4.16299 24.144 4.16968 25.3002C4.16968 26.1565 4.18975 26.4127 4.31016 26.819C4.7918 28.4752 5.98921 29.294 8.39073 29.6252C9.2336 29.744 26.7065 29.744 27.5493 29.6252C29.9575 29.294 31.1483 28.4752 31.6299 26.819C31.8373 26.1065 31.8373 24.5315 31.6299 23.6315C31.2352 21.9315 30.4325 20.5127 29.1816 19.3315C28.2517 18.4502 27.3286 17.869 26.1579 17.419C25.5559 17.1877 24.4454 16.9065 23.8567 16.8315L23.5557 16.8002L23.4353 17.1065C23.3751 17.2752 23.1945 17.5877 23.0406 17.8002C22.666 18.3252 21.4151 19.5315 20.3515 20.394C19.3213 21.219 18.0503 22.1752 17.97 22.1752C17.9366 22.1752 17.4951 21.8627 16.9867 21.4815C14.4045 19.5315 12.8459 18.019 12.5047 17.1065C12.4312 16.9127 12.3509 16.8065 12.284 16.8065C12.2305 16.8127 12.0967 16.8315 11.983 16.844ZM25.7566 22.2127C25.7766 22.269 25.7967 22.6315 25.7967 23.0252V23.7377H26.5593C26.9807 23.7377 27.3687 23.7565 27.4289 23.7752C27.5895 23.8315 27.5895 25.4565 27.4289 25.5127C27.3687 25.5315 26.9807 25.5502 26.5593 25.5502H25.7967V26.2815C25.7967 26.6877 25.7699 27.0565 25.7432 27.1002C25.6963 27.1565 25.4622 27.1752 24.7866 27.1627L23.8902 27.144L23.8701 26.344L23.8501 25.5502H23.0206C22.2647 25.5502 22.1844 25.5377 22.1175 25.4315C22.084 25.3627 22.0506 25.0065 22.0506 24.644C22.0506 24.2815 22.084 23.9252 22.1175 23.8565C22.1844 23.7502 22.2647 23.7377 23.0206 23.7377H23.8567V22.969C23.8567 22.544 23.8768 22.1752 23.9036 22.1565C23.9236 22.1315 24.3451 22.1127 24.8334 22.1127C25.5759 22.1127 25.7231 22.1252 25.7566 22.2127Z" fill="white" />
                    </svg>
                  </div>
                </div>
              </div>

            </Link>
          </div>
          <div className="col-md-3 ">
            <Link to="/appointments" style={{ textDecorationLine: "none" }}>
              <div className="card " style={{ borderRadius: "1rem", background: "#2BC155", opacity: "0.8" }}>
                <div className="card-body">

                  <span className="fs-20 text-white">
                    {d["All appointments"][language]}
                  </span>
                  <div className="d-flex justify-content-between align-items-baseline mt-4">
                    <h2 className="text-white GoldmanSemiBold fs-32">
                      {appointements?.filter((obj) => {
        return Date.parse(obj.appointement_date) > Date.now();
      }).length}
                    </h2>
                    <svg width="35" height="32" viewBox="0 0 35 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M16.866 1.03148C13.4343 1.53773 10.8254 4.06273 10.4106 7.26898C10.0093 10.3627 12.1098 14.8815 14.8457 16.8065C16.7991 18.1815 18.7323 18.269 20.6856 17.0815C23.1139 15.6065 25.1943 12.069 25.5355 8.83773C25.9168 5.27523 23.542 2.10023 19.7959 1.16898C19.3879 1.06273 19.0333 1.03148 18.1704 1.01898C17.5617 1.00648 16.9797 1.01273 16.866 1.03148Z" fill="white" />
                      <path d="M11.983 16.844C11.5214 16.9002 10.3708 17.194 9.84234 17.394C7.44752 18.3002 5.52095 20.1752 4.6647 22.444C4.29009 23.444 4.16299 24.144 4.16968 25.3002C4.16968 26.1565 4.18975 26.4127 4.31016 26.819C4.7918 28.4752 5.98921 29.294 8.39073 29.6252C9.2336 29.744 26.7065 29.744 27.5493 29.6252C29.9575 29.294 31.1483 28.4752 31.6299 26.819C31.8373 26.1065 31.8373 24.5315 31.6299 23.6315C31.2352 21.9315 30.4325 20.5127 29.1816 19.3315C28.2517 18.4502 27.3286 17.869 26.1579 17.419C25.5559 17.1877 24.4454 16.9065 23.8567 16.8315L23.5557 16.8002L23.4353 17.1065C23.3751 17.2752 23.1945 17.5877 23.0406 17.8002C22.666 18.3252 21.4151 19.5315 20.3515 20.394C19.3213 21.219 18.0503 22.1752 17.97 22.1752C17.9366 22.1752 17.4951 21.8627 16.9867 21.4815C14.4045 19.5315 12.8459 18.019 12.5047 17.1065C12.4312 16.9127 12.3509 16.8065 12.284 16.8065C12.2305 16.8127 12.0967 16.8315 11.983 16.844ZM25.7566 22.2127C25.7766 22.269 25.7967 22.6315 25.7967 23.0252V23.7377H26.5593C26.9807 23.7377 27.3687 23.7565 27.4289 23.7752C27.5895 23.8315 27.5895 25.4565 27.4289 25.5127C27.3687 25.5315 26.9807 25.5502 26.5593 25.5502H25.7967V26.2815C25.7967 26.6877 25.7699 27.0565 25.7432 27.1002C25.6963 27.1565 25.4622 27.1752 24.7866 27.1627L23.8902 27.144L23.8701 26.344L23.8501 25.5502H23.0206C22.2647 25.5502 22.1844 25.5377 22.1175 25.4315C22.084 25.3627 22.0506 25.0065 22.0506 24.644C22.0506 24.2815 22.084 23.9252 22.1175 23.8565C22.1844 23.7502 22.2647 23.7377 23.0206 23.7377H23.8567V22.969C23.8567 22.544 23.8768 22.1752 23.9036 22.1565C23.9236 22.1315 24.3451 22.1127 24.8334 22.1127C25.5759 22.1127 25.7231 22.1252 25.7566 22.2127Z" fill="white" />
                    </svg>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-md-3">
            <Link to={{ pathname: "/appointments",state:5 }} style={{ textDecorationLine: "none" }}>
              <div className="card " style={{ borderRadius: "1rem", background: "#ED1607", opacity: "0.72" }}>
                <div className="card-body">

                  <span className="fs-20 text-white">
                   {d["rejected appointments"][language]}
                  </span>
                  <div className="d-flex justify-content-between align-items-baseline mt-4">
                    <h2 className="text-white GoldmanSemiBold fs-32">
                      {rejectedAppointments}
                    </h2>
                    <svg width="35" height="32" viewBox="0 0 35 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M16.866 1.03148C13.4343 1.53773 10.8254 4.06273 10.4106 7.26898C10.0093 10.3627 12.1098 14.8815 14.8457 16.8065C16.7991 18.1815 18.7323 18.269 20.6856 17.0815C23.1139 15.6065 25.1943 12.069 25.5355 8.83773C25.9168 5.27523 23.542 2.10023 19.7959 1.16898C19.3879 1.06273 19.0333 1.03148 18.1704 1.01898C17.5617 1.00648 16.9797 1.01273 16.866 1.03148Z" fill="white" />
                      <path d="M11.983 16.844C11.5214 16.9002 10.3708 17.194 9.84234 17.394C7.44752 18.3002 5.52095 20.1752 4.6647 22.444C4.29009 23.444 4.16299 24.144 4.16968 25.3002C4.16968 26.1565 4.18975 26.4127 4.31016 26.819C4.7918 28.4752 5.98921 29.294 8.39073 29.6252C9.2336 29.744 26.7065 29.744 27.5493 29.6252C29.9575 29.294 31.1483 28.4752 31.6299 26.819C31.8373 26.1065 31.8373 24.5315 31.6299 23.6315C31.2352 21.9315 30.4325 20.5127 29.1816 19.3315C28.2517 18.4502 27.3286 17.869 26.1579 17.419C25.5559 17.1877 24.4454 16.9065 23.8567 16.8315L23.5557 16.8002L23.4353 17.1065C23.3751 17.2752 23.1945 17.5877 23.0406 17.8002C22.666 18.3252 21.4151 19.5315 20.3515 20.394C19.3213 21.219 18.0503 22.1752 17.97 22.1752C17.9366 22.1752 17.4951 21.8627 16.9867 21.4815C14.4045 19.5315 12.8459 18.019 12.5047 17.1065C12.4312 16.9127 12.3509 16.8065 12.284 16.8065C12.2305 16.8127 12.0967 16.8315 11.983 16.844ZM25.7566 22.2127C25.7766 22.269 25.7967 22.6315 25.7967 23.0252V23.7377H26.5593C26.9807 23.7377 27.3687 23.7565 27.4289 23.7752C27.5895 23.8315 27.5895 25.4565 27.4289 25.5127C27.3687 25.5315 26.9807 25.5502 26.5593 25.5502H25.7967V26.2815C25.7967 26.6877 25.7699 27.0565 25.7432 27.1002C25.6963 27.1565 25.4622 27.1752 24.7866 27.1627L23.8902 27.144L23.8701 26.344L23.8501 25.5502H23.0206C22.2647 25.5502 22.1844 25.5377 22.1175 25.4315C22.084 25.3627 22.0506 25.0065 22.0506 24.644C22.0506 24.2815 22.084 23.9252 22.1175 23.8565C22.1844 23.7502 22.2647 23.7377 23.0206 23.7377H23.8567V22.969C23.8567 22.544 23.8768 22.1752 23.9036 22.1565C23.9236 22.1315 24.3451 22.1127 24.8334 22.1127C25.5759 22.1127 25.7231 22.1252 25.7566 22.2127Z" fill="white" />
                    </svg>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            <div className="card pb-3">
              <div className="card-header" >
                <div className="mr-2">
                  <h4 className="fs-20 mb-0 GoldmanSemiBold ">
                    {d["Latest results"][language]}
                  </h4>
                </div>
                <span className="fake-plus-icon"></span>
              </div>
              <PerfectScrollbar
                option={{ suppressScrollX: true }}
                className="card-body loadmore-content  dlab-scroll  pb-0 "
                id="RecentActivitiesContent"
              >
                <div className="row">
                  <div className="form-group mb-0 col-8">
                    <select
                      className="form-control d-block"
                      name="param"
                      onChange={(e) => {

                        handleParamChange(e.target.value)

                      }}
                    >
                      {params?.map((param) => {
                        return <option key={params.indexOf(param)} value={params.indexOf(param)}>
                          {d[param.name][language]}
                        </option>
                      })}
                    </select>
                  </div>
                  <div className="col-4">
                    <select
                      className="form-control d-block"
                      name="period"
                      onChange={(e) => { setSelectedPeriod(e.target.value) }}
                    >
                      <option value="day">{d["daily"][language]}</option>
                      <option value="week">{d["weekly"][language]}</option>
                      <option value="month">{d["monthly"][language]}</option>
                    </select>
                  </div>

                </div>
                {/* values stats */}
                <div className=" row my-3 justify-content-between">
                  <div className="col-4 ">
                    <div className="px-4 pt-4 pb-3" style={{
                      backgroundColor: "rgba(255,165,0,0.08)",
                      borderRadius: "1rem",cursor:"pointer"
                    }} onClick={()=>setSelectedLevel("bad")}>

                      <div className="d-flex justify-content-between "
                      >
                        <h3 style={{ color: "#FFA500" }}>{d["low"][language]}</h3>
                        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M15.039 27.9617C17.0679 27.7703 18.6101 27.3382 20.2944 26.4906C22.5147 25.3804 24.571 23.4937 25.9218 21.3281C26.9007 19.764 27.6116 17.746 27.9015 15.7226C27.9944 15.0773 27.9944 12.9226 27.9015 12.2773C27.6718 10.664 27.2452 9.25307 26.578 7.90229C25.8288 6.37104 25.0905 5.34291 23.8765 4.12338C22.6679 2.92026 21.6233 2.17104 20.1522 1.44916C19.1241 0.94604 18.4624 0.699946 17.4179 0.437446C14.6835 -0.251616 11.9382 -0.120366 9.26943 0.825728C8.44912 1.11557 7.03818 1.82651 6.31631 2.30776C3.25381 4.35307 1.11006 7.45932 0.328029 10.9921C0.147559 11.8124 0.0545913 12.5835 0.0163109 13.5351C-0.0657201 15.8648 0.415529 18.0851 1.45459 20.1742C2.13272 21.5414 2.89287 22.6187 3.95928 23.7179C5.12959 24.932 6.31084 25.7906 7.84756 26.5507C9.46631 27.3492 10.9757 27.7812 12.7694 27.9453C13.3272 27.9945 14.5905 28.0054 15.039 27.9617ZM12.0585 26.0914C8.62412 25.5117 5.72569 23.6414 3.79522 20.7484C2.81631 19.2882 2.17647 17.6148 1.87569 15.7773C1.74444 14.9406 1.74444 13.0593 1.87569 12.2226C2.46631 8.56948 4.46787 5.53979 7.57412 3.58198C8.90303 2.74526 10.5108 2.15463 12.2226 1.87573C13.0647 1.74448 14.9405 1.74448 15.7772 1.8812C19.3702 2.46635 22.3288 4.37495 24.2921 7.38276C25.2108 8.78823 25.8288 10.4289 26.1241 12.2226C26.2554 13.0593 26.2554 14.9406 26.1241 15.7773C25.6812 18.4953 24.5327 20.7648 22.646 22.6406C20.5569 24.7242 17.9319 25.9492 14.9843 26.2171C14.3937 26.2718 12.6983 26.1953 12.0585 26.0914Z" fill="#FFA500" />
                          <path d="M14.3553 20.0484C14.4428 20.0047 15.5693 18.9164 16.8545 17.6312C18.8943 15.5805 19.1896 15.2578 19.2225 15.0828C19.2826 14.7492 19.206 14.4922 18.9818 14.268C18.7576 14.0437 18.5006 13.9672 18.167 14.0273C17.992 14.0602 17.7514 14.2734 16.4225 15.6023L14.8748 17.1391L14.8639 12.7859C14.8475 8.48203 14.8475 8.42734 14.7326 8.27969C14.5193 7.98984 14.3443 7.90234 13.9998 7.90234C13.6553 7.90234 13.4803 7.98984 13.267 8.27969C13.1521 8.42734 13.1521 8.48203 13.1357 12.7859L13.1248 17.1391L11.5771 15.6023C9.91464 13.9398 9.9037 13.9344 9.42245 14.0273C9.15448 14.0766 8.82636 14.4047 8.77714 14.6727C8.68417 15.1703 8.61855 15.0938 11.1451 17.6313C12.4685 18.9547 13.5568 20.0047 13.6553 20.0484C13.874 20.1414 14.142 20.1414 14.3553 20.0484Z" fill="#FFA500" />
                        </svg>
                      </div>
                      <div className="d-flex justify-content-start">
                        <span className="GoldmanSemiBold m-0" style={{ fontSize: "2.5rem", color: "#FFA500", textAlign: "left" }}>
                          {(selectedPeriod==="day"? (data[chartName]?.[0]["bad_value_daily"]):data[chartName]?.[0]["bad_value_"+selectedPeriod+"ly"]) ?? 0}</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="px-4 pt-4 pb-3" style={{
                      backgroundColor: "rgba(43,193,85,0.08)",
                      borderRadius: "1rem",cursor:"pointer"
                    }} onClick={()=>setSelectedLevel("good")}>

                      <div className="d-flex justify-content-between "
                      >
                        <h3 style={{ color: "#2BC155" }}>{d["good"][language]}</h3>
                        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clipPath="url(#clip0_396_6872)">
                            <path d="M12.6603 0.0601807C9.62514 0.371901 6.77592 1.64065 4.57748 3.65315C-0.524862 8.32893 -1.47642 15.925 2.31889 21.711C3.30326 23.2149 4.78529 24.6969 6.2892 25.6813C8.08295 26.8571 10.0408 27.5844 12.2775 27.9016C12.9228 27.9946 15.0775 27.9946 15.7228 27.9016C18.9494 27.4422 21.6837 26.1024 23.8931 23.893C26.1025 21.6836 27.4423 18.9492 27.9017 15.7227C27.9947 15.0774 27.9947 12.9227 27.9017 12.2774C27.4423 9.05081 26.1025 6.31643 23.8931 4.10706C21.733 1.95237 19.0587 0.612524 15.9689 0.136744C15.2798 0.0328369 13.3603 -0.0163822 12.6603 0.0601807ZM15.0119 2.13831C20.2673 2.58674 24.6259 6.48049 25.6705 11.6485C26.7423 16.9367 24.079 22.3235 19.2228 24.6969C14.6947 26.9063 9.30795 26.0367 5.69311 22.5149C0.979044 17.9211 0.886076 10.4125 5.48529 5.69299C7.97904 3.13362 11.4736 1.83206 15.0119 2.13831Z" fill="#2BC155" />
                            <path d="M19.9611 8.66795C19.8408 8.72263 18.233 10.2922 15.6955 12.8242L11.6213 16.8984L9.95332 15.225C9.03457 14.3062 8.19239 13.5078 8.08301 13.4476C7.73848 13.2726 7.21895 13.3437 6.9291 13.6062C6.58457 13.9179 6.51348 14.5304 6.77598 14.9351C6.86348 15.0664 7.86426 16.1 9.00176 17.2375C10.7463 18.9711 11.1072 19.3047 11.2932 19.3539C11.5939 19.4359 11.7799 19.4304 12.0205 19.332C12.2721 19.2281 21.115 10.4125 21.2791 10.1062C21.5033 9.6742 21.3502 9.05623 20.951 8.78279C20.683 8.59685 20.24 8.54763 19.9611 8.66795Z" fill="#2BC155" />
                          </g>
                          <defs>
                            <clipPath id="clip0_396_6872">
                              <rect width="28" height="28" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>

                      </div>
                      <div className="d-flex justify-content-start">
                        <span className="GoldmanSemiBold m-0" style={{ fontSize: "2.5rem", color: "#2BC155", textAlign: "left" }}>
                        {(selectedPeriod==="day"? data[chartName]?.[0]["good_value_daily"]:data[chartName]?.[0]["good_value_"+selectedPeriod+"ly"]) ?? 0}</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="px-4 pt-4 pb-3" style={{
                      backgroundColor: "rgba(237,22,7,0.08)",
                      borderRadius: "1rem",cursor:"pointer"
                    }} onClick={()=>setSelectedLevel("high")}
                    >

                      <div className="d-flex justify-content-between "
                      >
                        <h3 style={{ color: "#ED1607" }}>{d["high"][language]}</h3>
                        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clipPath="url(#clip0_396_6885)">
                            <path d="M12.961 0.0383377C10.9321 0.229744 9.38994 0.661776 7.70557 1.50943C5.48525 2.61959 3.429 4.50631 2.07822 6.67193C1.09932 8.23599 0.388379 10.254 0.0985352 12.2774C0.00556641 12.9227 0.00556641 15.0774 0.0985352 15.7227C0.328223 17.336 0.754785 18.7469 1.42197 20.0977C2.17119 21.629 2.90947 22.6571 4.12354 23.8766C5.33213 25.0797 6.37666 25.829 7.84775 26.5508C8.87588 27.054 9.5376 27.3001 10.5821 27.5626C13.3165 28.2516 16.0618 28.1204 18.7306 27.1743C19.5509 26.8844 20.9618 26.1735 21.6837 25.6922C24.7462 23.6469 26.8899 20.5407 27.672 17.0079C27.8524 16.1876 27.9454 15.4165 27.9837 14.4649C28.0657 12.1352 27.5845 9.9149 26.5454 7.82584C25.8673 6.45865 25.1071 5.38131 24.0407 4.28209C22.8704 3.06803 21.6892 2.20943 20.1524 1.44927C18.5337 0.650837 17.0243 0.218805 15.2306 0.0547428C14.6728 0.00552559 13.4095 -0.00541306 12.961 0.0383377ZM15.9415 1.90865C19.3759 2.48834 22.2743 4.35865 24.2048 7.25162C25.1837 8.71177 25.8235 10.3852 26.1243 12.2227C26.2556 13.0594 26.2556 14.9407 26.1243 15.7774C25.5337 19.4305 23.5321 22.4602 20.4259 24.418C19.097 25.2547 17.4892 25.8454 15.7774 26.1243C14.9353 26.2555 13.0595 26.2555 12.2228 26.1188C8.62978 25.5336 5.67119 23.6251 3.70791 20.6172C2.78916 19.2118 2.17119 17.5711 1.87588 15.7774C1.74463 14.9407 1.74463 13.0594 1.87588 12.2227C2.31885 9.50474 3.46729 7.23521 5.354 5.35943C7.44307 3.27584 10.0681 2.05084 13.0157 1.78287C13.6063 1.72818 15.3017 1.80474 15.9415 1.90865Z" fill="#ED1607" />
                            <path d="M13.6447 7.95156C13.5572 7.99531 12.4307 9.08359 11.1455 10.3688C9.10567 12.4195 8.81036 12.7422 8.77755 12.9172C8.71739 13.2508 8.79396 13.5078 9.01817 13.732C9.24239 13.9563 9.49942 14.0328 9.83302 13.9727C10.008 13.9398 10.2486 13.7266 11.5775 12.3977L13.1252 10.8609L13.1361 15.2141C13.1525 19.518 13.1525 19.5727 13.2674 19.7203C13.4807 20.0102 13.6557 20.0977 14.0002 20.0977C14.3447 20.0977 14.5197 20.0102 14.733 19.7203C14.8479 19.5727 14.8479 19.518 14.8643 15.2141L14.8752 10.8609L16.4229 12.3977C18.0854 14.0602 18.0963 14.0656 18.5775 13.9727C18.8455 13.9234 19.1736 13.5953 19.2229 13.3273C19.3158 12.8297 19.3815 12.9062 16.8549 10.3688C15.5315 9.04531 14.4432 7.99531 14.3447 7.95156C14.126 7.85859 13.858 7.85859 13.6447 7.95156Z" fill="#ED1607" />
                          </g>
                          <defs>
                            <clipPath id="clip0_396_6885">
                              <rect width="28" height="28" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>

                      </div>
                      <div className="d-flex justify-content-start">
                        <span className="GoldmanSemiBold m-0" style={{ fontSize: "2.5rem", color: "#ED1607", textAlign: "left" }}>
                        {(selectedPeriod==="day"? data[chartName]?.[0]["high_value_daily"]:data[chartName]?.[0]["high_value_"+selectedPeriod+"ly"]) ?? 0}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <DetailedValues title={selectedParam} selected={selectedPeriod} selectedLevel={selectedLevel}/>
              </PerfectScrollbar>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="card">
              <div className="card-header shadow-sm">
                <div className="mr-2">
                  <h4 className="fs-20 mb-0 GoldmanSemiBold text-primary">
                    {d["recent activities"][language]}
                  </h4>
                </div>
                <span className="fake-plus-icon"></span>
              </div>
              <PerfectScrollbar
                className="card-body loadmore-content dlab-scroll  pb-0" style={{ height: "470px" }}
                id="RecentActivitiesContent"
              >
                {recentActivities.map((item, index) => {
                  return <RecentActivity item={item} key={index} />;
                })}
              </PerfectScrollbar>
            </div>
          </div>


        </div>
      </div>

      <div className="col-lg-3">
        <div className="row">

          <div className="col-lg-12">
            <div className="card dashboard-calendar" style={{ border: " 10px solid rgb(17, 35, 97,0.24)" }}>
              <PerfectScrollbar
                option={{ suppressScrollX: true }}
                className="card-body loadmore-content  dlab-scroll  pb-0 "
                id="RecentActivitiesContent" style={{ height: "720px" }}
              >
                <Calendar
                 formatShortWeekday={(locale, date) => {
                  const fullWeekdayName = date.toLocaleDateString(locale, { weekday: 'long' });
                  return fullWeekdayName.charAt(0);
                }}
                  className="GoldmanMedium"
                  onChange={handleDateChange}
                  value={date}
                  onClickDay={(value) => { setDate(value) }}
                  tileContent={({ date, view }) => {
                    if (view === 'month') {
                      if (hasEvents(date)) {
                        return <div style={{ backgroundColor: '#2BC155', borderRadius: '50%', width: '10px', height: '10px' }}></div>;
                      }
                    }
                    return null;
                  }}
                />
                <p className="fs-20" style={{ marginTop: "1.875rem" }}>{d["upcoming appointments"][language]}</p>
                <div
                  id="DZ_W_TimeLine"
                  className="widget-timeline dlab-scroll  ps ps--active-y"
                >
                  <ul className="timeline timeline-appointments">
                    {
                    
                      events?.filter((evt) => { return evt.date.toDateString() === date.toDateString() }).map((evt) => {
                        return <li key={events?.indexOf(evt)}>

                          <div className="timeline-badge " ></div>
                          <div
                            className="timeline-panel text-muted"
                            to="/widget-basic"
                          >
                            <span className="fs-16" style={{ opacity: "0.64" }}>{evt.date.getDate() + "/" + evt.date.getMonth() + ", " + evt.date.getHours() + ":" + evt.date.getMinutes()}</span>
                            <span className="fs-16" style={{ marginTop: "1.625rem", marginBottom: "0.875rem" }}>{d[evt.type][language]}</span>
                            <h6 className="mb-0" style={{ opacity: "0.24", color: "#112361" }}>
                              {evt.patient}
                            </h6>
                          </div>
                        </li>
                      })
                    }
                  </ul>
                </div>


              </PerfectScrollbar>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}
