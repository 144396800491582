import React, { useContext} from "react";
import d from "../../resources/dictionary.json";
import { LanguageContext } from "../../resources/languageContext";
import { useState } from "react";
import { Card, Accordion } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";

export default function Contact() {
  const [activeDefault, setActiveDefault] = useState(0);
  const { language } = useContext(LanguageContext);
  const defaultAccordion = [
    {
      title: "Que signifient ces graphiques dans le tableau de bord?",
      text: "Il existe 6 types de graphiques (glycémie / pression artérielle / HbA1c / poids / marche / TSH). Chacun d'eux montre le nombre de patients qui ont mesuré la moyenne (élevée / bonne / faible)",
      bg: "primary",
    },
    {
      title: "Comment ajouter un patient?",
      text: "Vous pouvez cliquer sur l'icône du menu dans le coin supérieur gauche, une liste de catégories devrait apparaître. Sélectionnez les patients / connexions. Vous verrez un bouton AJOUTER NOUVEAU qui se trouve dans le coin supérieur droit de l'écran. Saisissez l'adresse e-mail ou le numéro d'identification du patient et cliquez sur Soumettre",
      bg: "info",
    },
    {
      title:
        "Je ne peux pas voir les patients après leur avoir envoyé une demande de suivi!",
      text: "Une fois qu'une demande est envoyée, elle doit d'abord être acceptée par le patient afin d'être autorisé à voir ce patient dans votre liste de connexions. En attendant, vous pouvez voir vos demandes en attente sur les> patients> pendants",
      bg: "success",
    },
    {
      title: "Comment trouver un patient spécifique?",
      text: "Pour trouver un patient, vous pouvez simplement taper votre texte dans la zone de recherche en haut, ou vous pouvez aller à> patients> connexions, puis rechercher le patient que vous voulez par nom ou prénom",
      bg: "success",
    },
    {
      title: "Puis-je supprimer ma connexion avec un patient",
      text: "Oui absolument, vous pouvez supprimer toute connexion que vous voulez à tout moment, pour le faire; allez dans> patients> connexions> sélectionnez le patient et appuyez sur ne plus suivre",
      bg: "success",
    },
    {
      title:
        "Puis-je mettre à jour mes informations ou changer mon mot de passe?",
      text: "Oui, allez dans> compte et choisissez votre option",
      bg: "success",
    },
  ];
  return (
    <>
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-body d-flex justify-content-between">
            <div className=" GoldmanSemiBold" style={{color:"#112361"}}>
              {d["aide mail"][language]}
            </div>
            <div className=" GoldmanSemiBold" style={{color:"#3453DF"}}>
              Contact: <a href="mailto:support@shls.care">support@shls.care</a> 
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
    <Card>
        <Card.Header className="d-block card-header p-4">
          <Card.Title>{d["frequently asked questions"][language]}</Card.Title>
        </Card.Header>
        <PerfectScrollbar
              className=" card-body height520 dlab-scroll" 
               >
<Accordion
            className="accordion accordion-primary"
            defaultActiveKey="0"
          >
            {defaultAccordion.map((d, i) => (
              <div className="accordion__item" key={i}>
                <Accordion.Toggle
                  as={Card.Text}
                  eventKey={`${i}`}
                  className={`accordion__header rounded-lg ${
                    activeDefault === i ? "" : "collapsed"
                  }`}
                  onClick={() => setActiveDefault(activeDefault === i ? -1 : i)}
                >
                  <span className="accordion__header--text">{d.title}</span>
                  <span className="accordion__header--indicator"></span>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={`${i}`}>
                  <div className="accordion__body--text">{d.text}</div>
                </Accordion.Collapse>
              </div>
            ))}
          </Accordion>
               </PerfectScrollbar>
      </Card>
    </div>
    </>
  );
}
