import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";

import PerfectScrollbar from "react-perfect-scrollbar";
import { LanguageContext } from "../../../resources/languageContext";
import d from "../../../resources/dictionary.json";
import { useContext } from "react";
import Cookies from "js-cookie";
import jwt from "jsonwebtoken";
import { toast } from "react-toastify";
import "./DoctorProfile.css"

function DoctorProfile() {
  const { language } = useContext(LanguageContext);
  const [activeToggle, setActiveToggle] = useState("settings");
  const token = Cookies.get("jwt");
  const {
    doctor_firstname,
    doctor_lastname,
    doctor_email,
    doctor_address,
    doctor_phonenumber,
    doctor_speciality,
    doctor_ville,
    doctor_sexe,
  } = jwt.decode(token);
  const [inputs, setInputs] = useState({
    firstName: doctor_firstname ?? "",
    lastName: doctor_lastname ?? "",
    email: doctor_email ?? "",
    phone: doctor_phonenumber ?? "",
    address: doctor_address ?? "",
    specialty: doctor_speciality ?? "",
    city: doctor_ville ?? "",
    sex: doctor_sexe ?? "",
  });

  const updateDoctorProfile = async () => {
    const request = {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        firstnameDoctor: inputs.firstName,
        nameDoctor: inputs.lastName,
        emailDoctor: inputs.email,
        address: inputs.address,
        ville: inputs.city,
        phonenumber: inputs.phone,
        speciality: inputs.specialty,
        sexe: inputs.sex,
      }),
    };
    try {
      let response = await fetch(
        "https://doctor.shls.care/api/doctor/update",
        request
      );
      let data = await response.json();
      if (response.status === 200) {
        toast.success(data.message);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  function handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    setInputs((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  const [passwordInputs, setPasswordInputs] = useState({
    oldPW: "",
    newPW: "",
  });
  function handlePasswordInputs(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    setPasswordInputs((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  const modifyPassword = async () => {
    const request = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        password: passwordInputs.newPW,
        oldpassword: passwordInputs.oldPW,
      }),
    };
    try {
      let response = await fetch(
        "https://doctor.shls.care/api/doctor/changePassword",
        request
      );
      if (response.status === 200) {
        toast.success(d["success"][language]);
      } else if (response.status === 409) {
        toast.error(d["error"][language]);
      }
    } catch (error) {
      console.error(error.message);
      toast.error(d["error"][language]);
    }
  };

  return (
    <Fragment>
      <div className="row justify-content-center">
        <div className="col-9 ">
          <div className="card setting-card" >
            <div className="card-header px-0" >
              <h4 className="fs-24 mb-0 GoldmanSemiBold">
                {d["account settings"][language]}
              </h4>
            </div>
            <PerfectScrollbar
              className=" card-body height620 dlab-scroll" 
               >
                  <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          if (passwordInputs.newPW==="") {
                            modifyPassword();
                          }
                          updateDoctorProfile();
                        }}
                      >
                    <div className="settings-form">
                      <h4 className="text-primary GoldmanSemiBold fs-20 pb-2" style={{ color: "#3453DF" }}>
                        {d["user info"][language]}
                      </h4>
                        <div className="d-flex justify-content-between ">
                          <div className="col-5 px-0">
                            <div className="right-inputs">
                              <div className="form-group col-md-12 px-0">
                                <label>{d["lastname"][language]}</label>
                                <input
                                  value={inputs.lastName}
                                  style={
                                    inputs.lastName === ""
                                      ? {}
                                      : { color: "black" }
                                  }
                                  name="lastName"
                                  onChange={handleInputChange}
                                  type="text"
                                  placeholder={d["lastname"][language]}
                                  className="form-control"
                                />
                              </div>
                              <div className="form-group col-md-12 px-0">
                                <label>Email</label>
                                <input
                                  value={inputs.email}
                                  style={
                                    inputs.email === ""
                                      ? {}
                                      : { color: "black" }
                                  }
                                  name="email"
                                  onChange={handleInputChange}
                                  type="email"
                                  placeholder="Email"
                                  className="form-control"
                                />
                              </div>
                              <div className="form-group col-md-12 px-0">
                                <label>{d["specialty"][language]}</label>
                                <input
                                  value={inputs.specialty}
                                  style={
                                    inputs.specialty === ""
                                      ? {}
                                      : { color: "black" }
                                  }
                                  name="specialty"
                                  onChange={handleInputChange}
                                  type="text"
                                  placeholder={d["specialty"][language]}
                                  className="form-control"
                                />
                              </div>
                              <div className="form-group col-md-12 px-0">
                                <label>{d["address"][language]}</label>
                                <input
                                  value={inputs.address}
                                  style={
                                    inputs.address === ""
                                      ? {}
                                      : { color: "black" }
                                  }
                                  name="address"
                                  onChange={handleInputChange}
                                  type="text"
                                  placeholder={d["address"][language]}
                                  className="form-control"
                                />
                              </div>

                            </div>
                          </div>
                          <div className="col-5 px-0">
                            <div className="left-inputs">
                              <div className="form-group col-md-12 px-0">
                                <label>{d["firstname"][language]}</label>
                                <input
                                  value={inputs.firstName}
                                  style={
                                    inputs.firstName === ""
                                      ? {}
                                      : { color: "black" }
                                  }
                                  name="firstName"
                                  onChange={handleInputChange}
                                  type="text"
                                  placeholder={d["firstname"][language]}
                                  className="form-control"
                                />
                              </div>
                              <div className="form-group col-md-12 px-0">
                                <label>{d["phone"][language]}</label>
                                <input
                                  value={inputs.phone}
                                  style={
                                    inputs.phone === ""
                                      ? {}
                                      : { color: "black" }
                                  }
                                  name="phone"
                                  onChange={handleInputChange}
                                  type="phone"
                                  placeholder={d["phone"][language]}
                                  className="form-control"
                                />
                              </div>
                              <div className="form-group col-md-12 px-0">
                                <label>{d["sex"][language]}</label>
                                <select
                                  className="form-control"
                                  onChange={handleInputChange}
                                  value={inputs.sex}
                                  style={
                                    inputs.sex === "" ? {} : { color: "black" }
                                  }
                                  name="sex"
                                >
                                  <option value="">{d["sex"][language]}</option>
                                  <option value="male">
                                    {d["male"][language]}
                                  </option>
                                  <option value="female">
                                    {d["female"][language]}
                                  </option>
                                </select>
                              </div>
                              <div className="form-group col-md-12 px-0">
                                <label>{d["city"][language]}</label>
                                <input
                                  value={inputs.city}
                                  style={
                                    inputs.city === "" ? {} : { color: "black" }
                                  }
                                  name="city"
                                  onChange={handleInputChange}
                                  type="text"
                                  placeholder={d["city"][language]}
                                  className="form-control"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                    </div>
                    <div className="settings-form mt-3">
                      <h4 className="text-primary GoldmanSemiBold fs-20 pb-2" style={{ color: "#3453DF" }}>
                        {d["reset password"][language]}
                      </h4>
                      
                        <div className="d-flex justify-content-between">
                          <div className="col-5 px-0">
                            <div className="right-inputs">
                              <div className="form-group col-md-12 px-0">
                              <label>{d["old password"][language]}</label>
                                <input
                                  value={passwordInputs.oldPW}
                                  style={
                                    passwordInputs.oldPW === ""
                                      ? {}
                                      : { color: "black" }
                                  }
                                  name="oldPW"
                                  onChange={handlePasswordInputs}
                                  type="password"
                                  placeholder="******"
                                  className="form-control"
                                />
                              </div>

                            </div>
                          </div>
                          <div className="col-5 px-0">
                            <div className="left-inputs">

                              <div className="form-group col-md-12 px-0">
                              <label>
                                  {d["new password"][language]}
                                </label>
                                <input
                                  value={passwordInputs.newPW}
                                  style={
                                    passwordInputs.newPW === ""
                                      ? {}
                                      : { color: "black" }
                                  }
                                  name="newPW"
                                  onChange={handlePasswordInputs}
                                  type="password"
                                  placeholder="******"
                                  className="form-control"
                                />
                              </div>
                              <div className="form-group col-md-12 px-0">
                                <button className="btn btn-block btn-primary GoldmanSemiBold save-btn" type="submit">
                                  {d["save"][language]}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                    </div>
                    
                  </form>
            </PerfectScrollbar>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default DoctorProfile;
