import Cookies from "js-cookie";
import moment from "moment";
import React from "react";
import { useContext, useState, useEffect } from "react";
import d from "../../../resources/dictionary.json";
import { LanguageContext } from "../../../resources/languageContext";

const Alerts = ({ PerfectScrollbar, toggleChatBox }) => {
  const { language } = useContext(LanguageContext);
  const [fetchData, setFetchData] = useState([]);
  const [showText, setShowText] = useState("");
  const token = Cookies.get("jwt");

  const toggleText = (id) => {
    if (id === showText) {
      setShowText("");
    } else {
      setShowText(id);
    }
  };

  useEffect(() => {
    let mount = true;
    const fetchMessages = async () => {
      try {
        let response = await fetch(
          `https://doctor.shls.care/api/doctor/notification/get`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json; charset=UTF-8",
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Headers":
                "Origin, X-requested-With, Content-Type, Accept",
              Authorization: "Bearer " + token,
            },
          },
          { mode: "cors" }
        );
        const data = await response.json();
        if (response.status === 200) {
          if (mount) setFetchData(data.data);
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    fetchMessages();
    return () => {
      mount = false;
    };
  }, []);

  return (
    <div className={`tab-pane fade active show`} id="alerts" role="tabpanel">
      <div className="card mb-sm-3 mb-md-0 contacts_card">
        <PerfectScrollbar
          className={`card-body contacts_body p-0 dz-scroll  ${
            toggleChatBox ? "ps ps--active-y" : ""
          }`}
          id="DZ_W_Contacts_Body1"
        >
          <ul className="contacts">
            <li className="name-first-letter py-3"  key={"notifications"}>
              {d["messages"][language]}
            </li>
            {fetchData.map((item,index) => (
              <li
                key={index}
                onClick={() => {
                  toggleText(item.notification_id);
                }}
              >
                <div className="d-flex bd-highlight">
                  <div className="img_cont primary">
                    {Array.from(item.patient_firstname)[0]}
                    {Array.from(item.patient_lastname)[0]}
                  </div>
                  <div className="user_info">
                    <span>
                      {item.patient_firstname} {item.patient_lastname}
                    </span>
                    <p style={{ fontWeight: "600" }} className="pb-1">
                      {item.notification_title==="New Appointment"?d["new message"][language]:item.notification_title}
                    </p>
                    <p className="mt-2">
                      {moment(item.created_at_n).format("YYYY-MM-DD")}
                    </p>
                  </div>
                </div>
                {item.notification_id === showText ? (
                  <div className="content ml-5">
                    <p style={{ fontSize: "14px" }}>
                      {item.notification_content}
                    </p>
                  </div>
                ) : null}
              </li>
            ))}
          </ul>
        </PerfectScrollbar>
      </div>
    </div>
  );
};

export default Alerts;
