import React, {  useContext } from "react";

import d from "../../../resources/dictionary.json";
import { LanguageContext } from "../../../resources/languageContext";

function ChartCard({ title, period, chartData }) {
  const { language } = useContext(LanguageContext);

  const data=[
    chartData?.bad_value_daily,
    chartData?.good_value_daily,
    chartData?.high_value_daily,
  ];

 

  return (
    <div className=" row my-3 justify-content-between">
      <div className="col-4 ">
        <div className="px-4 pt-4 pb-3" style={{
          backgroundColor: "rgba(255,165,0,0.08)",
          borderRadius: "1rem",

        }}>

          <div className="d-flex justify-content-between "
          >
            <h3 style={{ color: "#FFA500" }}>{d["low"][language]}</h3>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15.039 27.9617C17.0679 27.7703 18.6101 27.3382 20.2944 26.4906C22.5147 25.3804 24.571 23.4937 25.9218 21.3281C26.9007 19.764 27.6116 17.746 27.9015 15.7226C27.9944 15.0773 27.9944 12.9226 27.9015 12.2773C27.6718 10.664 27.2452 9.25307 26.578 7.90229C25.8288 6.37104 25.0905 5.34291 23.8765 4.12338C22.6679 2.92026 21.6233 2.17104 20.1522 1.44916C19.1241 0.94604 18.4624 0.699946 17.4179 0.437446C14.6835 -0.251616 11.9382 -0.120366 9.26943 0.825728C8.44912 1.11557 7.03818 1.82651 6.31631 2.30776C3.25381 4.35307 1.11006 7.45932 0.328029 10.9921C0.147559 11.8124 0.0545913 12.5835 0.0163109 13.5351C-0.0657201 15.8648 0.415529 18.0851 1.45459 20.1742C2.13272 21.5414 2.89287 22.6187 3.95928 23.7179C5.12959 24.932 6.31084 25.7906 7.84756 26.5507C9.46631 27.3492 10.9757 27.7812 12.7694 27.9453C13.3272 27.9945 14.5905 28.0054 15.039 27.9617ZM12.0585 26.0914C8.62412 25.5117 5.72569 23.6414 3.79522 20.7484C2.81631 19.2882 2.17647 17.6148 1.87569 15.7773C1.74444 14.9406 1.74444 13.0593 1.87569 12.2226C2.46631 8.56948 4.46787 5.53979 7.57412 3.58198C8.90303 2.74526 10.5108 2.15463 12.2226 1.87573C13.0647 1.74448 14.9405 1.74448 15.7772 1.8812C19.3702 2.46635 22.3288 4.37495 24.2921 7.38276C25.2108 8.78823 25.8288 10.4289 26.1241 12.2226C26.2554 13.0593 26.2554 14.9406 26.1241 15.7773C25.6812 18.4953 24.5327 20.7648 22.646 22.6406C20.5569 24.7242 17.9319 25.9492 14.9843 26.2171C14.3937 26.2718 12.6983 26.1953 12.0585 26.0914Z" fill="#FFA500" />
              <path d="M14.3553 20.0484C14.4428 20.0047 15.5693 18.9164 16.8545 17.6312C18.8943 15.5805 19.1896 15.2578 19.2225 15.0828C19.2826 14.7492 19.206 14.4922 18.9818 14.268C18.7576 14.0437 18.5006 13.9672 18.167 14.0273C17.992 14.0602 17.7514 14.2734 16.4225 15.6023L14.8748 17.1391L14.8639 12.7859C14.8475 8.48203 14.8475 8.42734 14.7326 8.27969C14.5193 7.98984 14.3443 7.90234 13.9998 7.90234C13.6553 7.90234 13.4803 7.98984 13.267 8.27969C13.1521 8.42734 13.1521 8.48203 13.1357 12.7859L13.1248 17.1391L11.5771 15.6023C9.91464 13.9398 9.9037 13.9344 9.42245 14.0273C9.15448 14.0766 8.82636 14.4047 8.77714 14.6727C8.68417 15.1703 8.61855 15.0938 11.1451 17.6313C12.4685 18.9547 13.5568 20.0047 13.6553 20.0484C13.874 20.1414 14.142 20.1414 14.3553 20.0484Z" fill="#FFA500" />
            </svg>
          </div>
          <div className="d-flex justify-content-start">
            <span className="GoldmanSemiBold m-0" style={{ fontSize: "2.5rem", color: "#FFA500", textAlign: "left" }}>{data[0] ?? 0}</span>
          </div>
        </div>
      </div>
      <div className="col-4">
        <div className="px-4 pt-4 pb-3" style={{
          backgroundColor: "rgba(43,193,85,0.08)",
          borderRadius: "1rem"
        }}>

          <div className="d-flex justify-content-between "
          >
            <h3 style={{ color: "#2BC155" }}>{d["good"][language]}</h3>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_396_6872)">
                <path d="M12.6603 0.0601807C9.62514 0.371901 6.77592 1.64065 4.57748 3.65315C-0.524862 8.32893 -1.47642 15.925 2.31889 21.711C3.30326 23.2149 4.78529 24.6969 6.2892 25.6813C8.08295 26.8571 10.0408 27.5844 12.2775 27.9016C12.9228 27.9946 15.0775 27.9946 15.7228 27.9016C18.9494 27.4422 21.6837 26.1024 23.8931 23.893C26.1025 21.6836 27.4423 18.9492 27.9017 15.7227C27.9947 15.0774 27.9947 12.9227 27.9017 12.2774C27.4423 9.05081 26.1025 6.31643 23.8931 4.10706C21.733 1.95237 19.0587 0.612524 15.9689 0.136744C15.2798 0.0328369 13.3603 -0.0163822 12.6603 0.0601807ZM15.0119 2.13831C20.2673 2.58674 24.6259 6.48049 25.6705 11.6485C26.7423 16.9367 24.079 22.3235 19.2228 24.6969C14.6947 26.9063 9.30795 26.0367 5.69311 22.5149C0.979044 17.9211 0.886076 10.4125 5.48529 5.69299C7.97904 3.13362 11.4736 1.83206 15.0119 2.13831Z" fill="#2BC155" />
                <path d="M19.9611 8.66795C19.8408 8.72263 18.233 10.2922 15.6955 12.8242L11.6213 16.8984L9.95332 15.225C9.03457 14.3062 8.19239 13.5078 8.08301 13.4476C7.73848 13.2726 7.21895 13.3437 6.9291 13.6062C6.58457 13.9179 6.51348 14.5304 6.77598 14.9351C6.86348 15.0664 7.86426 16.1 9.00176 17.2375C10.7463 18.9711 11.1072 19.3047 11.2932 19.3539C11.5939 19.4359 11.7799 19.4304 12.0205 19.332C12.2721 19.2281 21.115 10.4125 21.2791 10.1062C21.5033 9.6742 21.3502 9.05623 20.951 8.78279C20.683 8.59685 20.24 8.54763 19.9611 8.66795Z" fill="#2BC155" />
              </g>
              <defs>
                <clipPath id="clip0_396_6872">
                  <rect width="28" height="28" fill="white" />
                </clipPath>
              </defs>
            </svg>

          </div>
          <div className="d-flex justify-content-start">
            <span className="GoldmanSemiBold m-0" style={{ fontSize: "2.5rem", color: "#2BC155", textAlign: "left" }}>{data[1] ?? 0}</span>
          </div>
        </div>
      </div>
      <div className="col-4">
        <div className="px-4 pt-4 pb-3" style={{
          backgroundColor: "rgba(237,22,7,0.08)",
          borderRadius: "1rem"
        }}>

          <div className="d-flex justify-content-between "
          >
            <h3 style={{ color: "#ED1607" }}>{d["high"][language]}</h3>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_396_6885)">
                <path d="M12.961 0.0383377C10.9321 0.229744 9.38994 0.661776 7.70557 1.50943C5.48525 2.61959 3.429 4.50631 2.07822 6.67193C1.09932 8.23599 0.388379 10.254 0.0985352 12.2774C0.00556641 12.9227 0.00556641 15.0774 0.0985352 15.7227C0.328223 17.336 0.754785 18.7469 1.42197 20.0977C2.17119 21.629 2.90947 22.6571 4.12354 23.8766C5.33213 25.0797 6.37666 25.829 7.84775 26.5508C8.87588 27.054 9.5376 27.3001 10.5821 27.5626C13.3165 28.2516 16.0618 28.1204 18.7306 27.1743C19.5509 26.8844 20.9618 26.1735 21.6837 25.6922C24.7462 23.6469 26.8899 20.5407 27.672 17.0079C27.8524 16.1876 27.9454 15.4165 27.9837 14.4649C28.0657 12.1352 27.5845 9.9149 26.5454 7.82584C25.8673 6.45865 25.1071 5.38131 24.0407 4.28209C22.8704 3.06803 21.6892 2.20943 20.1524 1.44927C18.5337 0.650837 17.0243 0.218805 15.2306 0.0547428C14.6728 0.00552559 13.4095 -0.00541306 12.961 0.0383377ZM15.9415 1.90865C19.3759 2.48834 22.2743 4.35865 24.2048 7.25162C25.1837 8.71177 25.8235 10.3852 26.1243 12.2227C26.2556 13.0594 26.2556 14.9407 26.1243 15.7774C25.5337 19.4305 23.5321 22.4602 20.4259 24.418C19.097 25.2547 17.4892 25.8454 15.7774 26.1243C14.9353 26.2555 13.0595 26.2555 12.2228 26.1188C8.62978 25.5336 5.67119 23.6251 3.70791 20.6172C2.78916 19.2118 2.17119 17.5711 1.87588 15.7774C1.74463 14.9407 1.74463 13.0594 1.87588 12.2227C2.31885 9.50474 3.46729 7.23521 5.354 5.35943C7.44307 3.27584 10.0681 2.05084 13.0157 1.78287C13.6063 1.72818 15.3017 1.80474 15.9415 1.90865Z" fill="#ED1607" />
                <path d="M13.6447 7.95156C13.5572 7.99531 12.4307 9.08359 11.1455 10.3688C9.10567 12.4195 8.81036 12.7422 8.77755 12.9172C8.71739 13.2508 8.79396 13.5078 9.01817 13.732C9.24239 13.9563 9.49942 14.0328 9.83302 13.9727C10.008 13.9398 10.2486 13.7266 11.5775 12.3977L13.1252 10.8609L13.1361 15.2141C13.1525 19.518 13.1525 19.5727 13.2674 19.7203C13.4807 20.0102 13.6557 20.0977 14.0002 20.0977C14.3447 20.0977 14.5197 20.0102 14.733 19.7203C14.8479 19.5727 14.8479 19.518 14.8643 15.2141L14.8752 10.8609L16.4229 12.3977C18.0854 14.0602 18.0963 14.0656 18.5775 13.9727C18.8455 13.9234 19.1736 13.5953 19.2229 13.3273C19.3158 12.8297 19.3815 12.9062 16.8549 10.3688C15.5315 9.04531 14.4432 7.99531 14.3447 7.95156C14.126 7.85859 13.858 7.85859 13.6447 7.95156Z" fill="#ED1607" />
              </g>
              <defs>
                <clipPath id="clip0_396_6885">
                  <rect width="28" height="28" fill="white" />
                </clipPath>
              </defs>
            </svg>

          </div>
          <div className="d-flex justify-content-start">
            <span className="GoldmanSemiBold m-0" style={{ fontSize: "2.5rem", color: "#ED1607", textAlign: "left" }}>{data[2] ?? 0}</span>
          </div>
        </div>
      </div>
    </div>
    // <div className="card pt-0 mb-0" id="RecentResultsContent" >
    //   <div className="card-body p-0 pt-4">
    //     <div className=" row px-3 mb-3 justify-content-between">
    //       <div className="col-4 px-1">
    //         <div className="px-4 pt-4 pb-3" style={{
    //           backgroundColor: "rgba(255,165,0,0.08)",
    //           borderRadius: "1rem",

    //         }}>

    //           <div className="d-flex justify-content-between "
    //           >
    //             <h3 style={{ color: "#FFA500" }}>{d["low"][language]}</h3>
    //             <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    //               <path d="M15.039 27.9617C17.0679 27.7703 18.6101 27.3382 20.2944 26.4906C22.5147 25.3804 24.571 23.4937 25.9218 21.3281C26.9007 19.764 27.6116 17.746 27.9015 15.7226C27.9944 15.0773 27.9944 12.9226 27.9015 12.2773C27.6718 10.664 27.2452 9.25307 26.578 7.90229C25.8288 6.37104 25.0905 5.34291 23.8765 4.12338C22.6679 2.92026 21.6233 2.17104 20.1522 1.44916C19.1241 0.94604 18.4624 0.699946 17.4179 0.437446C14.6835 -0.251616 11.9382 -0.120366 9.26943 0.825728C8.44912 1.11557 7.03818 1.82651 6.31631 2.30776C3.25381 4.35307 1.11006 7.45932 0.328029 10.9921C0.147559 11.8124 0.0545913 12.5835 0.0163109 13.5351C-0.0657201 15.8648 0.415529 18.0851 1.45459 20.1742C2.13272 21.5414 2.89287 22.6187 3.95928 23.7179C5.12959 24.932 6.31084 25.7906 7.84756 26.5507C9.46631 27.3492 10.9757 27.7812 12.7694 27.9453C13.3272 27.9945 14.5905 28.0054 15.039 27.9617ZM12.0585 26.0914C8.62412 25.5117 5.72569 23.6414 3.79522 20.7484C2.81631 19.2882 2.17647 17.6148 1.87569 15.7773C1.74444 14.9406 1.74444 13.0593 1.87569 12.2226C2.46631 8.56948 4.46787 5.53979 7.57412 3.58198C8.90303 2.74526 10.5108 2.15463 12.2226 1.87573C13.0647 1.74448 14.9405 1.74448 15.7772 1.8812C19.3702 2.46635 22.3288 4.37495 24.2921 7.38276C25.2108 8.78823 25.8288 10.4289 26.1241 12.2226C26.2554 13.0593 26.2554 14.9406 26.1241 15.7773C25.6812 18.4953 24.5327 20.7648 22.646 22.6406C20.5569 24.7242 17.9319 25.9492 14.9843 26.2171C14.3937 26.2718 12.6983 26.1953 12.0585 26.0914Z" fill="#FFA500" />
    //               <path d="M14.3553 20.0484C14.4428 20.0047 15.5693 18.9164 16.8545 17.6312C18.8943 15.5805 19.1896 15.2578 19.2225 15.0828C19.2826 14.7492 19.206 14.4922 18.9818 14.268C18.7576 14.0437 18.5006 13.9672 18.167 14.0273C17.992 14.0602 17.7514 14.2734 16.4225 15.6023L14.8748 17.1391L14.8639 12.7859C14.8475 8.48203 14.8475 8.42734 14.7326 8.27969C14.5193 7.98984 14.3443 7.90234 13.9998 7.90234C13.6553 7.90234 13.4803 7.98984 13.267 8.27969C13.1521 8.42734 13.1521 8.48203 13.1357 12.7859L13.1248 17.1391L11.5771 15.6023C9.91464 13.9398 9.9037 13.9344 9.42245 14.0273C9.15448 14.0766 8.82636 14.4047 8.77714 14.6727C8.68417 15.1703 8.61855 15.0938 11.1451 17.6313C12.4685 18.9547 13.5568 20.0047 13.6553 20.0484C13.874 20.1414 14.142 20.1414 14.3553 20.0484Z" fill="#FFA500" />
    //             </svg>
    //           </div>
    //           <div className="d-flex justify-content-start">
    //             <span className="GoldmanSemiBold m-0" style={{ fontSize: "2.5rem", color: "#FFA500", textAlign: "left" }}>{data[0]}</span>
    //           </div>
    //         </div>
    //       </div>
    //       <div className="col-4 px-1">
    //         {/* <Link
    //           style={{ textDecorationLine: "none",
    //          }}
    //           to={{
    //             pathname: "/detailedvalues",
    //             state: { title, selected, detail: "bad" },
    //           }}
    //         > */}
    //         <div className="px-4 pt-4 pb-3" style={{
    //           backgroundColor: "rgba(43,193,85,0.08)",
    //           borderRadius: "1rem"
    //         }}>

    //           <div className="d-flex justify-content-between "
    //           >
    //             <h3 style={{ color: "#2BC155" }}>{d["good"][language]}</h3>
    //             <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    //               <g clipPath="url(#clip0_396_6872)">
    //                 <path d="M12.6603 0.0601807C9.62514 0.371901 6.77592 1.64065 4.57748 3.65315C-0.524862 8.32893 -1.47642 15.925 2.31889 21.711C3.30326 23.2149 4.78529 24.6969 6.2892 25.6813C8.08295 26.8571 10.0408 27.5844 12.2775 27.9016C12.9228 27.9946 15.0775 27.9946 15.7228 27.9016C18.9494 27.4422 21.6837 26.1024 23.8931 23.893C26.1025 21.6836 27.4423 18.9492 27.9017 15.7227C27.9947 15.0774 27.9947 12.9227 27.9017 12.2774C27.4423 9.05081 26.1025 6.31643 23.8931 4.10706C21.733 1.95237 19.0587 0.612524 15.9689 0.136744C15.2798 0.0328369 13.3603 -0.0163822 12.6603 0.0601807ZM15.0119 2.13831C20.2673 2.58674 24.6259 6.48049 25.6705 11.6485C26.7423 16.9367 24.079 22.3235 19.2228 24.6969C14.6947 26.9063 9.30795 26.0367 5.69311 22.5149C0.979044 17.9211 0.886076 10.4125 5.48529 5.69299C7.97904 3.13362 11.4736 1.83206 15.0119 2.13831Z" fill="#2BC155" />
    //                 <path d="M19.9611 8.66795C19.8408 8.72263 18.233 10.2922 15.6955 12.8242L11.6213 16.8984L9.95332 15.225C9.03457 14.3062 8.19239 13.5078 8.08301 13.4476C7.73848 13.2726 7.21895 13.3437 6.9291 13.6062C6.58457 13.9179 6.51348 14.5304 6.77598 14.9351C6.86348 15.0664 7.86426 16.1 9.00176 17.2375C10.7463 18.9711 11.1072 19.3047 11.2932 19.3539C11.5939 19.4359 11.7799 19.4304 12.0205 19.332C12.2721 19.2281 21.115 10.4125 21.2791 10.1062C21.5033 9.6742 21.3502 9.05623 20.951 8.78279C20.683 8.59685 20.24 8.54763 19.9611 8.66795Z" fill="#2BC155" />
    //               </g>
    //               <defs>
    //                 <clipPath id="clip0_396_6872">
    //                   <rect width="28" height="28" fill="white" />
    //                 </clipPath>
    //               </defs>
    //             </svg>

    //           </div>
    //           <div className="d-flex justify-content-start">
    //             <span className="GoldmanSemiBold m-0" style={{ fontSize: "2.5rem", color: "#2BC155", textAlign: "left" }}>{data[1]}</span>
    //           </div>
    //         </div>
    //         {/* </Link> */}
    //       </div>
    //       <div className="col-4 px-1">
    //         <div className="px-4 pt-4 pb-3" style={{
    //           backgroundColor: "rgba(237,22,7,0.08)",
    //           borderRadius: "1rem"
    //         }}>

    //           <div className="d-flex justify-content-between "
    //           >
    //             <h3 style={{ color: "#ED1607" }}>{d["high"][language]}</h3>
    //             <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    //               <g clipPath="url(#clip0_396_6885)">
    //                 <path d="M12.961 0.0383377C10.9321 0.229744 9.38994 0.661776 7.70557 1.50943C5.48525 2.61959 3.429 4.50631 2.07822 6.67193C1.09932 8.23599 0.388379 10.254 0.0985352 12.2774C0.00556641 12.9227 0.00556641 15.0774 0.0985352 15.7227C0.328223 17.336 0.754785 18.7469 1.42197 20.0977C2.17119 21.629 2.90947 22.6571 4.12354 23.8766C5.33213 25.0797 6.37666 25.829 7.84775 26.5508C8.87588 27.054 9.5376 27.3001 10.5821 27.5626C13.3165 28.2516 16.0618 28.1204 18.7306 27.1743C19.5509 26.8844 20.9618 26.1735 21.6837 25.6922C24.7462 23.6469 26.8899 20.5407 27.672 17.0079C27.8524 16.1876 27.9454 15.4165 27.9837 14.4649C28.0657 12.1352 27.5845 9.9149 26.5454 7.82584C25.8673 6.45865 25.1071 5.38131 24.0407 4.28209C22.8704 3.06803 21.6892 2.20943 20.1524 1.44927C18.5337 0.650837 17.0243 0.218805 15.2306 0.0547428C14.6728 0.00552559 13.4095 -0.00541306 12.961 0.0383377ZM15.9415 1.90865C19.3759 2.48834 22.2743 4.35865 24.2048 7.25162C25.1837 8.71177 25.8235 10.3852 26.1243 12.2227C26.2556 13.0594 26.2556 14.9407 26.1243 15.7774C25.5337 19.4305 23.5321 22.4602 20.4259 24.418C19.097 25.2547 17.4892 25.8454 15.7774 26.1243C14.9353 26.2555 13.0595 26.2555 12.2228 26.1188C8.62978 25.5336 5.67119 23.6251 3.70791 20.6172C2.78916 19.2118 2.17119 17.5711 1.87588 15.7774C1.74463 14.9407 1.74463 13.0594 1.87588 12.2227C2.31885 9.50474 3.46729 7.23521 5.354 5.35943C7.44307 3.27584 10.0681 2.05084 13.0157 1.78287C13.6063 1.72818 15.3017 1.80474 15.9415 1.90865Z" fill="#ED1607" />
    //                 <path d="M13.6447 7.95156C13.5572 7.99531 12.4307 9.08359 11.1455 10.3688C9.10567 12.4195 8.81036 12.7422 8.77755 12.9172C8.71739 13.2508 8.79396 13.5078 9.01817 13.732C9.24239 13.9563 9.49942 14.0328 9.83302 13.9727C10.008 13.9398 10.2486 13.7266 11.5775 12.3977L13.1252 10.8609L13.1361 15.2141C13.1525 19.518 13.1525 19.5727 13.2674 19.7203C13.4807 20.0102 13.6557 20.0977 14.0002 20.0977C14.3447 20.0977 14.5197 20.0102 14.733 19.7203C14.8479 19.5727 14.8479 19.518 14.8643 15.2141L14.8752 10.8609L16.4229 12.3977C18.0854 14.0602 18.0963 14.0656 18.5775 13.9727C18.8455 13.9234 19.1736 13.5953 19.2229 13.3273C19.3158 12.8297 19.3815 12.9062 16.8549 10.3688C15.5315 9.04531 14.4432 7.99531 14.3447 7.95156C14.126 7.85859 13.858 7.85859 13.6447 7.95156Z" fill="#ED1607" />
    //               </g>
    //               <defs>
    //                 <clipPath id="clip0_396_6885">
    //                   <rect width="28" height="28" fill="white" />
    //                 </clipPath>
    //               </defs>
    //             </svg>

    //           </div>
    //           <div className="d-flex justify-content-start">
    //             <span className="GoldmanSemiBold m-0" style={{ fontSize: "2.5rem", color: "#ED1607", textAlign: "left" }}>{data[2]}</span>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    //   {/* <div className="card-body">
    //     <div className="row stat-row">
    //         <div className="col-6 px-0">
    //         <button className={"btn btn-block btn-stat btn-graph"+(type==="graph"&&" btn-selected")}
    //         onClick={()=>setType("graph")}
    //         >{d["Graphic Curve"][language]}</button>
    //         </div>
    //         <div className="col-6 px-0">
    //           <button className={"btn btn-block btn-stat btn-pie "+(type==="pie"&&" btn-selected")}
    //           onClick={()=>setType("pie")}
    //           >{d["Graphic Pie"][language]}</button>
    //         </div>
    //     </div>
    //     {
    //       type==="pie"?
    //       <Doughnut data={dataSet} options={options}  />
    //       :
    //       <Line data={lineDataSet} options={lineOptions}  />

    //     }

    //   </div> */}
    // </div>
  );
}


export default ChartCard;
