import React from "react";
import { Table } from "reactstrap";
import user from "../../../images/user.png"
import { LanguageContext } from "../../../resources/languageContext";
import d from "../../../resources/dictionary.json";
import { useContext } from "react";
import moment from "moment/moment";

function Risk({ diabet, cardio }) {
  const { language } = useContext(LanguageContext);
  return (
    <div className="risk-assessment">
      <div className="row">


        {
          (diabet?.length === 0 && cardio?.length === 0) ?
            <div className="col-12 no-data p-0" style={{ gap: "0.5rem" }}>
              <img className="mt-5" src={user} alt="user" />
              <p className="m-0">
                {d["there is no risk evaluation"][language]}
              </p>

            </div> :
            <>
              <div className="col-md-6 pt-4">

                {cardio?.length !== 0 ? <Table>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Date</th>
                      <th>Type</th>
                      <th>{d["risk"][language]}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cardio?.map((item, index) => (

                      <tr key={index}>
                        <td>{index}</td>
                        <td>{moment(item.created_at).format("DD-MM-YYYY")}</td>
                        <td>Cardio</td>
                        <td>{item.risk_cardio_result} %</td>
                      </tr>
                    ))}
                  </tbody>
                </Table> :
                  <div className="col-12 no-data p-0" style={{ gap: "0.5rem" }}>
                    <img className="mt-5" src={user} alt="user" />
                    <p className="m-0">
                      {d["there is no risk evaluation for cardio"][language]}
                    </p>

                  </div>}
              </div>
              <div className="col-md-6 pt-4">
                {diabet?.length !== 0 ? <Table>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Date</th>
                      <th>Type</th>
                      <th>{d["risk"][language]}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {diabet?.map((item, index) => (
                      <tr key={index}>
                        <td>{index}</td>
                        <td>{moment(item.created_at).format("DD-MM-YYYY")}</td>
                        <td>{d["diabetes"][language]}</td>
                        <td>{item.risk_diabet_result} %</td>
                      </tr>
                    ))
                    }
                  </tbody>
                </Table> :
                  <div className="col-12 no-data p-0" style={{ gap: "0.5rem" }}>
                    <img className="mt-5" src={user} alt="user" />
                    <p className="m-0">
                      {d["there is no risk evaluation for diabetes"][language]}
                    </p>

                  </div>}
              </div>
            </>

        }

      </div>
    </div>
  );
}

export default Risk;
