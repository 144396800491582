import React, { useState, useEffect, useContext } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { Spinner, Tab, Nav, Modal, Button } from "react-bootstrap";
import d from "../../../resources/dictionary.json";
import user from "../../../images/user.png"
import PerfectScrollbar from "react-perfect-scrollbar";
import user13 from "../../../images/users/13.jpg";
import u5 from "../../../images/avatar/5.png";
import user5 from "../../../images/avatar/user5.png";
import patient from "../../../images/avatar/patient.png";
import woman from "../../../images/avatar/woman.png";
import user25 from "../../../images/users/25.jpg";
import { LanguageContext } from "../../../resources/languageContext";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import "./Patient.css"

export default function Patients() {
  const { language } = useContext(LanguageContext);
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const [patientEmail, setPatientEmail] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [fetchData, setFetchData] = useState("");
  const [tab, setTab] = useState("");
  const { accepted, pending } = fetchData;
  // const { patients } = data || [];
  const token = Cookies.get("jwt");

  useEffect(() => {
    let ignore = false;
    const fetchData = async () => {
      const request = {
        method: "GET",
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers":
            "Origin, X-requested-With, Content-Type, Accept",
          Authorization: "Bearer " + token,
        },
      };
      try {
        let response = await fetch(
          "https://doctor.shls.care/api/doctor/patients",
          request,
          {
            mode: "cors",
          }
        );
        const data = await response.json();
        if (response.status === 200) {
          setFetchData(data);
        }
      } catch (err) {
        console.log(err.message);
      } finally {
        setLoading(false);
      }
    };
    if (!ignore) {
      fetchData();
    }

    return () => {
      setLoading(false);
      window.scrollTo(0, 0);
      ignore = true;
    };
  }, []);

  const handleSubmit = () => {
    //api call to send the invitation.
    const fetchData = async () => {
      const request = {
        method: "POST",
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers":
            "Origin, X-requested-With, Content-Type, Accept",
          Authorization: "Bearer " + token,
        },
      };
      try {
        let response = await fetch(
          `https://doctor.shls.care/api/doctor/follow/add?patient_email=${patientEmail}`,
          request,
          { mode: "cors" }
        );
        const data = await response.json();
        if (response.status === 200) {
          toast.success(data.message);
          setResponse(true);
        } else {
          toast.error(data.message);
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    fetchData();
    setShowModal(false);
  };
  const handleCancelRequest = (patientId, followId) => {
    Swal.fire({
      text: d["do you really want to cancel this request ?"][language],
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: d['confirm'][language]
    }).then((result) => {
      if (result.isConfirmed) {
        cancelRequest(patientId, followId)
      }
    })

  }
  const cancelRequest = async (patientId, followId) => {
    const request = {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "Origin, X-requested-With, Content-Type, Accept",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        patient_id: patientId,
        followId: followId,
      }),
    };
    try {
      let response = await fetch(
        `https://doctor.shls.care/api/doctor/unfollow`,
        request,
        {
          mode: "cors",
        }
      );
      const data = await response.json();
      if (response.status === 200) {
        toast.error(d["unfollowed"][language]);
        let newPending = [...pending];
        let newIndex = newPending.findIndex(
          (obj) => obj.patient_id === patientId
        );
        newPending.splice(newIndex, 1);
        setFetchData({ accepted, newPending });
      } else {
        toast.error(data.message);
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  if (loading)
    return (
      <div className="d-flex justify-content-center align-items-center h-75">
        <Spinner animation="border" variant="primary" />
      </div>
    );

  return (
    <>
      <Tab.Container defaultActiveKey={location.state ?? 1}>
        <div className="row d-md-flex d-block mb-4 ">
          <div className="card-action card-tabs mb-md-0 mb-3  mr-auto">
            <Nav as="ul" className="nav nav-tabs tabs-lg ">
              <Nav.Item as="li" key={1}>
                <Nav.Link

                  eventKey={1}
                  onClick={() => {
                    setTab("accepted");
                  }}
                >
                  {accepted?.length + " " + d["accepted"][language]}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item as="li" key={2}>
                <Nav.Link
                  eventKey={2}
                  onClick={() => {
                    setTab("pending");
                  }}
                >
                  {pending?.length + " " + d["pending"][language]}
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
          <div>
            <Link
              to={"#"}
              onClick={() => {
                setShowModal(true);
              }}
              data-toggle="modal"
              data-target="#addOrderModal"
              className="btn btn-primary follow-btn GoldmanSemiBold fs-18"
            >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.7875 2.24625C10.6172 2.24625 11.4286 2.56828 12.0141 3.12984C12.3605 3.46406 12.4852 3.6314 12.6792 4.02234C13.0017 4.67343 13.1034 5.33953 12.9895 6.05906C12.8766 6.74812 12.5231 7.39687 11.9953 7.88531C11.4717 8.37 10.8112 8.66437 10.1344 8.715C10.0364 8.7225 9.9375 8.72625 9.84047 8.72625C8.63766 8.72625 7.59891 8.14734 6.98953 7.13812C6.84047 6.88593 6.72328 6.59062 6.62062 6.21L6.61781 6.19968L6.615 6.18937C6.60516 6.15422 6.60047 6.13547 6.59812 6.12562C6.58781 6.01547 6.58969 5.6264 6.59062 5.49656V5.49V5.48343C6.59062 5.30156 6.59062 4.92468 6.60094 4.83656C6.60281 4.82578 6.61078 4.79203 6.63844 4.69078L6.63984 4.68562L6.64125 4.68047C6.71812 4.39125 7.035 3.74812 7.17609 3.56062C7.69078 2.88937 8.52281 2.39765 9.34781 2.27718C9.49172 2.25703 9.63984 2.24625 9.7875 2.24625ZM9.7875 -0.00375175C9.5325 -0.00375175 9.27703 0.0145283 9.02344 0.0515594C7.6125 0.25781 6.25312 1.06406 5.38594 2.19843C5.05781 2.62968 4.61719 3.53906 4.46719 4.10156C4.35 4.53281 4.34062 4.64062 4.34062 5.48437C4.33594 6.29531 4.35 6.44531 4.44844 6.79687C4.60781 7.3875 4.80469 7.86562 5.05781 8.29218C6.08062 9.99422 7.84781 10.9767 9.84047 10.9767C9.99328 10.9767 10.1475 10.9711 10.3031 10.9594C12.7453 10.7766 14.8125 8.86406 15.2109 6.41718C15.3984 5.24062 15.225 4.09218 14.6953 3.02343C14.3859 2.4 14.1047 2.02031 13.575 1.50937C12.5644 0.538591 11.182 -0.00375175 9.7875 -0.00375175Z" fill="white" />
                <path d="M19.2375 4.64062C18.9468 4.75312 18.6093 5.07656 18.4593 5.38125C18.3609 5.57812 18.3468 5.68594 18.3281 6.39844L18.3046 7.19531L17.5078 7.21875C16.5937 7.24687 16.4343 7.29375 16.0921 7.63594C15.8156 7.9125 15.6937 8.19375 15.6656 8.59687C15.6281 9.20625 15.9468 9.75 16.4906 10.0172C16.7437 10.1437 16.7953 10.1484 17.5406 10.1625L18.3187 10.1812L18.3375 10.9828C18.3562 11.9016 18.3984 12.0516 18.7593 12.4125C19.0734 12.7312 19.3078 12.8203 19.8281 12.8203C20.3484 12.8203 20.55 12.7359 20.8875 12.3937C21.2156 12.0562 21.2812 11.8078 21.2812 10.9219V10.1812L22.0921 10.1625C23.0015 10.1437 23.1609 10.1016 23.5312 9.76875C24.2812 9.09375 24.1078 7.83281 23.1984 7.35C23.0109 7.25156 22.9125 7.24219 22.1484 7.21875L21.3046 7.19531L21.2812 6.39844C21.2578 5.67656 21.2484 5.57812 21.1453 5.37187C21.0046 5.09062 20.7468 4.81875 20.475 4.68281C20.1843 4.52812 19.5656 4.50937 19.2375 4.64062Z" fill="white" />
                <path d="M9.75511 13.7887C10.0087 13.7958 10.8206 13.8722 11.1539 13.9327L11.1571 13.9331L11.1604 13.9336C12.7059 14.2087 14.1046 14.903 15.2053 15.9412C16.2867 16.9612 17.0109 18.2498 17.3001 19.6692C17.3451 19.8895 17.347 19.8984 17.3315 20.2439L17.331 20.25V20.2561C17.3146 20.677 17.31 20.6859 17.1942 20.9217L17.1909 20.9283L17.1876 20.9348C17.0657 21.187 16.8689 21.382 16.6026 21.5152C16.3945 21.6173 16.2843 21.6689 15.6754 21.7078C15.3562 21.7284 13.1812 21.75 9.77995 21.75C6.48651 21.75 4.15448 21.7294 3.71901 21.7092C3.29714 21.6511 2.95167 21.5222 2.7187 21.3356C2.51854 21.1753 2.38683 20.9648 2.3048 20.6747C2.26073 20.4717 2.25323 19.8741 2.3273 19.5267C2.49558 18.7852 2.86495 17.91 3.26948 17.2936C3.9262 16.298 4.83698 15.4509 5.90386 14.8439C6.97495 14.2345 8.1848 13.8773 9.40261 13.8108L9.4162 13.8098L9.4298 13.8089C9.49917 13.8042 9.57558 13.7995 9.64167 13.7958C9.68433 13.7934 9.72276 13.7911 9.75511 13.7887ZM9.7298 11.5383C9.70026 11.5383 9.67542 11.5387 9.6562 11.5406C9.59058 11.5453 9.42183 11.5547 9.2812 11.5641C6.0937 11.7375 3.12651 13.425 1.39214 16.0547C0.834326 16.9031 0.356201 18.0422 0.131201 19.0406C-0.0141113 19.7062 -0.0141113 20.7094 0.131201 21.2531C0.548389 22.7766 1.7437 23.7328 3.49214 23.9484C3.76636 23.9822 6.78839 24 9.78089 24C12.5671 24 15.3276 23.985 15.8203 23.9531C16.6265 23.9016 17.0484 23.8031 17.6015 23.5312C18.3093 23.1797 18.8718 22.6219 19.214 21.9141C19.4812 21.3703 19.5515 21.0656 19.5796 20.3437C19.6031 19.8141 19.589 19.6312 19.5046 19.2188C18.7265 15.3984 15.5859 12.4359 11.5546 11.7187C11.1004 11.6367 10.0804 11.5383 9.7298 11.5383Z" fill="white" />
              </svg>
              {d["follow a patient"][language]}
            </Link>

            <Modal
              centered
              show={showModal}
              onHide={() => {
                setShowModal(false);
              }}
              className="modal fade"
              id="addOrderModal"
            >
              <div role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">
                      {d["add patient"][language]}
                    </h5>
                    <button
                      type="button"
                      onClick={() => {
                        setShowModal(false);
                      }}
                      className="close"
                      data-dismiss="modal"
                    >
                      <span>&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit();
                      }}
                    >
                      <div className="form-group">
                        <label className=" font-w500">
                          {d["patient's email"][language]}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={patientEmail}
                          onChange={(e) => {
                            setPatientEmail(e.target.value);
                          }}
                        />
                      </div>
                      <div className="form-group text-center mt-3">
                        <button
                          type="submit"
                          className="btn btn-primary btn-block"
                        // onClick={handleSubmit}
                        >
                          {d["send"][language]}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
        </div>
        <PerfectScrollbar
          className=" row dlab-scroll height640"
        >
          <div className="col-12">
            <div
              className="tab-pane fade show active"
              id="navpills-1"
              role="tabpanel"
            >
              <Tab.Content>
                <Tab.Pane eventKey={1} key={1}>
                  <div className="row ">

                    {accepted?.length === 0 ?
                      <div className="col-12 no-data">
                        <img src={user} alt="user" />
                        <p className="m-0">
                          {d["No accepted patient for the moment"][language]}
                        </p>
                        <Link
                          to={"#"}
                          onClick={() => {
                            setShowModal(true);
                          }}
                          data-toggle="modal"
                          data-target="#addOrderModal"
                          className="btn btn-primary follow-btn  GoldmanSemiBold fs-18"
                        >
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.7875 2.24625C10.6172 2.24625 11.4286 2.56828 12.0141 3.12984C12.3605 3.46406 12.4852 3.6314 12.6792 4.02234C13.0017 4.67343 13.1034 5.33953 12.9895 6.05906C12.8766 6.74812 12.5231 7.39687 11.9953 7.88531C11.4717 8.37 10.8112 8.66437 10.1344 8.715C10.0364 8.7225 9.9375 8.72625 9.84047 8.72625C8.63766 8.72625 7.59891 8.14734 6.98953 7.13812C6.84047 6.88593 6.72328 6.59062 6.62062 6.21L6.61781 6.19968L6.615 6.18937C6.60516 6.15422 6.60047 6.13547 6.59812 6.12562C6.58781 6.01547 6.58969 5.6264 6.59062 5.49656V5.49V5.48343C6.59062 5.30156 6.59062 4.92468 6.60094 4.83656C6.60281 4.82578 6.61078 4.79203 6.63844 4.69078L6.63984 4.68562L6.64125 4.68047C6.71812 4.39125 7.035 3.74812 7.17609 3.56062C7.69078 2.88937 8.52281 2.39765 9.34781 2.27718C9.49172 2.25703 9.63984 2.24625 9.7875 2.24625ZM9.7875 -0.00375175C9.5325 -0.00375175 9.27703 0.0145283 9.02344 0.0515594C7.6125 0.25781 6.25312 1.06406 5.38594 2.19843C5.05781 2.62968 4.61719 3.53906 4.46719 4.10156C4.35 4.53281 4.34062 4.64062 4.34062 5.48437C4.33594 6.29531 4.35 6.44531 4.44844 6.79687C4.60781 7.3875 4.80469 7.86562 5.05781 8.29218C6.08062 9.99422 7.84781 10.9767 9.84047 10.9767C9.99328 10.9767 10.1475 10.9711 10.3031 10.9594C12.7453 10.7766 14.8125 8.86406 15.2109 6.41718C15.3984 5.24062 15.225 4.09218 14.6953 3.02343C14.3859 2.4 14.1047 2.02031 13.575 1.50937C12.5644 0.538591 11.182 -0.00375175 9.7875 -0.00375175Z" fill="white" />
                            <path d="M19.2375 4.64062C18.9468 4.75312 18.6093 5.07656 18.4593 5.38125C18.3609 5.57812 18.3468 5.68594 18.3281 6.39844L18.3046 7.19531L17.5078 7.21875C16.5937 7.24687 16.4343 7.29375 16.0921 7.63594C15.8156 7.9125 15.6937 8.19375 15.6656 8.59687C15.6281 9.20625 15.9468 9.75 16.4906 10.0172C16.7437 10.1437 16.7953 10.1484 17.5406 10.1625L18.3187 10.1812L18.3375 10.9828C18.3562 11.9016 18.3984 12.0516 18.7593 12.4125C19.0734 12.7312 19.3078 12.8203 19.8281 12.8203C20.3484 12.8203 20.55 12.7359 20.8875 12.3937C21.2156 12.0562 21.2812 11.8078 21.2812 10.9219V10.1812L22.0921 10.1625C23.0015 10.1437 23.1609 10.1016 23.5312 9.76875C24.2812 9.09375 24.1078 7.83281 23.1984 7.35C23.0109 7.25156 22.9125 7.24219 22.1484 7.21875L21.3046 7.19531L21.2812 6.39844C21.2578 5.67656 21.2484 5.57812 21.1453 5.37187C21.0046 5.09062 20.7468 4.81875 20.475 4.68281C20.1843 4.52812 19.5656 4.50937 19.2375 4.64062Z" fill="white" />
                            <path d="M9.75511 13.7887C10.0087 13.7958 10.8206 13.8722 11.1539 13.9327L11.1571 13.9331L11.1604 13.9336C12.7059 14.2087 14.1046 14.903 15.2053 15.9412C16.2867 16.9612 17.0109 18.2498 17.3001 19.6692C17.3451 19.8895 17.347 19.8984 17.3315 20.2439L17.331 20.25V20.2561C17.3146 20.677 17.31 20.6859 17.1942 20.9217L17.1909 20.9283L17.1876 20.9348C17.0657 21.187 16.8689 21.382 16.6026 21.5152C16.3945 21.6173 16.2843 21.6689 15.6754 21.7078C15.3562 21.7284 13.1812 21.75 9.77995 21.75C6.48651 21.75 4.15448 21.7294 3.71901 21.7092C3.29714 21.6511 2.95167 21.5222 2.7187 21.3356C2.51854 21.1753 2.38683 20.9648 2.3048 20.6747C2.26073 20.4717 2.25323 19.8741 2.3273 19.5267C2.49558 18.7852 2.86495 17.91 3.26948 17.2936C3.9262 16.298 4.83698 15.4509 5.90386 14.8439C6.97495 14.2345 8.1848 13.8773 9.40261 13.8108L9.4162 13.8098L9.4298 13.8089C9.49917 13.8042 9.57558 13.7995 9.64167 13.7958C9.68433 13.7934 9.72276 13.7911 9.75511 13.7887ZM9.7298 11.5383C9.70026 11.5383 9.67542 11.5387 9.6562 11.5406C9.59058 11.5453 9.42183 11.5547 9.2812 11.5641C6.0937 11.7375 3.12651 13.425 1.39214 16.0547C0.834326 16.9031 0.356201 18.0422 0.131201 19.0406C-0.0141113 19.7062 -0.0141113 20.7094 0.131201 21.2531C0.548389 22.7766 1.7437 23.7328 3.49214 23.9484C3.76636 23.9822 6.78839 24 9.78089 24C12.5671 24 15.3276 23.985 15.8203 23.9531C16.6265 23.9016 17.0484 23.8031 17.6015 23.5312C18.3093 23.1797 18.8718 22.6219 19.214 21.9141C19.4812 21.3703 19.5515 21.0656 19.5796 20.3437C19.6031 19.8141 19.589 19.6312 19.5046 19.2188C18.7265 15.3984 15.5859 12.4359 11.5546 11.7187C11.1004 11.6367 10.0804 11.5383 9.7298 11.5383Z" fill="white" />
                          </svg>
                          {d["follow a patient"][language]}
                        </Link>
                      </div>
                      : accepted?.map((item, index) => (
                        <div className="col-lg-3 col-md-4 px-2" key={item.patient_id}>
                          <div className="card">
                            <div className="card-body p-0" role="button">
                              <div className="patient-card d-flex" onClick={() => {
                                history.push({
                                  pathname: `/patient:${item.patient_id}`,
                                  state: item,
                                });
                              }}>
                                <img
                                  src={
                                    (() => {
                                      if (item.profil_picture !== null && item.profil_picture !== "") {

                                        return "https://doctor.shls.care/pictures/" + item.profil_picture
                                      } else {
                                        if ((item.patient_sexe === "male") || (item.patient_sexe === "Male")) {
                                          return patient
                                        } else {
                                          return woman
                                        }

                                      }
                                    })()
                                  }
                                  alt="User"
                                  className="rounded-circle mr-3"
                                  width="80"
                                  height="80"
                                />
                                <div className="">
                                  <h6 className="fs-24 mb-0">
                                    <span
                                      className="text-black GoldmanSemiBold"
                                    >
                                      {item.patient_lastname}{" "}
                                      {item.patient_firstname}
                                    </span>
                                  </h6>

                                  <p className="fs-18 GoldmanNormal">{item.patient_ville}</p>
                                </div>

                              </div>
                              <div className="patient-info d-flex justify-content-between " onClick={() => {
                                history.push({
                                  pathname: `/patient:${item.patient_id}`,
                                  state: item,
                                });
                              }}>
                                <label className="fs-18">{d["phone"][language]}:</label>
                                <p className="fs-18">
                                  {item.patient_phonenumber}
                                </p>

                              </div>
                              <a href={"mailto:" + item.patient_email}>
                                <div className="patient-info d-flex justify-content-between mb-3">
                                  <label className="fs-18">Email:</label>
                                  <p className="fs-18" >
                                    {item.patient_email}
                                  </p>

                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      ))
                    }

                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey={2} key={2}>
                  <div
                    className="row loadmore-content"
                    id="RecentActivitiesContent"
                  >
                    {pending?.length === 0 ?
                      <div className="col-12 no-data">
                        <img src={user} alt="user" />
                        <p className="m-0">
                          {d["No pending patient for the moment"][language]}
                        </p>
                        <Link
                          to={"#"}
                          onClick={() => {
                            setShowModal(true);
                          }}
                          data-toggle="modal"
                          data-target="#addOrderModal"
                          className="btn btn-primary follow-btn GoldmanSemiBold fs-18 "
                        >
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.7875 2.24625C10.6172 2.24625 11.4286 2.56828 12.0141 3.12984C12.3605 3.46406 12.4852 3.6314 12.6792 4.02234C13.0017 4.67343 13.1034 5.33953 12.9895 6.05906C12.8766 6.74812 12.5231 7.39687 11.9953 7.88531C11.4717 8.37 10.8112 8.66437 10.1344 8.715C10.0364 8.7225 9.9375 8.72625 9.84047 8.72625C8.63766 8.72625 7.59891 8.14734 6.98953 7.13812C6.84047 6.88593 6.72328 6.59062 6.62062 6.21L6.61781 6.19968L6.615 6.18937C6.60516 6.15422 6.60047 6.13547 6.59812 6.12562C6.58781 6.01547 6.58969 5.6264 6.59062 5.49656V5.49V5.48343C6.59062 5.30156 6.59062 4.92468 6.60094 4.83656C6.60281 4.82578 6.61078 4.79203 6.63844 4.69078L6.63984 4.68562L6.64125 4.68047C6.71812 4.39125 7.035 3.74812 7.17609 3.56062C7.69078 2.88937 8.52281 2.39765 9.34781 2.27718C9.49172 2.25703 9.63984 2.24625 9.7875 2.24625ZM9.7875 -0.00375175C9.5325 -0.00375175 9.27703 0.0145283 9.02344 0.0515594C7.6125 0.25781 6.25312 1.06406 5.38594 2.19843C5.05781 2.62968 4.61719 3.53906 4.46719 4.10156C4.35 4.53281 4.34062 4.64062 4.34062 5.48437C4.33594 6.29531 4.35 6.44531 4.44844 6.79687C4.60781 7.3875 4.80469 7.86562 5.05781 8.29218C6.08062 9.99422 7.84781 10.9767 9.84047 10.9767C9.99328 10.9767 10.1475 10.9711 10.3031 10.9594C12.7453 10.7766 14.8125 8.86406 15.2109 6.41718C15.3984 5.24062 15.225 4.09218 14.6953 3.02343C14.3859 2.4 14.1047 2.02031 13.575 1.50937C12.5644 0.538591 11.182 -0.00375175 9.7875 -0.00375175Z" fill="white" />
                            <path d="M19.2375 4.64062C18.9468 4.75312 18.6093 5.07656 18.4593 5.38125C18.3609 5.57812 18.3468 5.68594 18.3281 6.39844L18.3046 7.19531L17.5078 7.21875C16.5937 7.24687 16.4343 7.29375 16.0921 7.63594C15.8156 7.9125 15.6937 8.19375 15.6656 8.59687C15.6281 9.20625 15.9468 9.75 16.4906 10.0172C16.7437 10.1437 16.7953 10.1484 17.5406 10.1625L18.3187 10.1812L18.3375 10.9828C18.3562 11.9016 18.3984 12.0516 18.7593 12.4125C19.0734 12.7312 19.3078 12.8203 19.8281 12.8203C20.3484 12.8203 20.55 12.7359 20.8875 12.3937C21.2156 12.0562 21.2812 11.8078 21.2812 10.9219V10.1812L22.0921 10.1625C23.0015 10.1437 23.1609 10.1016 23.5312 9.76875C24.2812 9.09375 24.1078 7.83281 23.1984 7.35C23.0109 7.25156 22.9125 7.24219 22.1484 7.21875L21.3046 7.19531L21.2812 6.39844C21.2578 5.67656 21.2484 5.57812 21.1453 5.37187C21.0046 5.09062 20.7468 4.81875 20.475 4.68281C20.1843 4.52812 19.5656 4.50937 19.2375 4.64062Z" fill="white" />
                            <path d="M9.75511 13.7887C10.0087 13.7958 10.8206 13.8722 11.1539 13.9327L11.1571 13.9331L11.1604 13.9336C12.7059 14.2087 14.1046 14.903 15.2053 15.9412C16.2867 16.9612 17.0109 18.2498 17.3001 19.6692C17.3451 19.8895 17.347 19.8984 17.3315 20.2439L17.331 20.25V20.2561C17.3146 20.677 17.31 20.6859 17.1942 20.9217L17.1909 20.9283L17.1876 20.9348C17.0657 21.187 16.8689 21.382 16.6026 21.5152C16.3945 21.6173 16.2843 21.6689 15.6754 21.7078C15.3562 21.7284 13.1812 21.75 9.77995 21.75C6.48651 21.75 4.15448 21.7294 3.71901 21.7092C3.29714 21.6511 2.95167 21.5222 2.7187 21.3356C2.51854 21.1753 2.38683 20.9648 2.3048 20.6747C2.26073 20.4717 2.25323 19.8741 2.3273 19.5267C2.49558 18.7852 2.86495 17.91 3.26948 17.2936C3.9262 16.298 4.83698 15.4509 5.90386 14.8439C6.97495 14.2345 8.1848 13.8773 9.40261 13.8108L9.4162 13.8098L9.4298 13.8089C9.49917 13.8042 9.57558 13.7995 9.64167 13.7958C9.68433 13.7934 9.72276 13.7911 9.75511 13.7887ZM9.7298 11.5383C9.70026 11.5383 9.67542 11.5387 9.6562 11.5406C9.59058 11.5453 9.42183 11.5547 9.2812 11.5641C6.0937 11.7375 3.12651 13.425 1.39214 16.0547C0.834326 16.9031 0.356201 18.0422 0.131201 19.0406C-0.0141113 19.7062 -0.0141113 20.7094 0.131201 21.2531C0.548389 22.7766 1.7437 23.7328 3.49214 23.9484C3.76636 23.9822 6.78839 24 9.78089 24C12.5671 24 15.3276 23.985 15.8203 23.9531C16.6265 23.9016 17.0484 23.8031 17.6015 23.5312C18.3093 23.1797 18.8718 22.6219 19.214 21.9141C19.4812 21.3703 19.5515 21.0656 19.5796 20.3437C19.6031 19.8141 19.589 19.6312 19.5046 19.2188C18.7265 15.3984 15.5859 12.4359 11.5546 11.7187C11.1004 11.6367 10.0804 11.5383 9.7298 11.5383Z" fill="white" />
                          </svg>
                          {d["follow a patient"][language]}
                        </Link>
                      </div>
                      : pending?.map((item, index) => (
                        <div className="col-lg-3 col-md-4 px-2" key={item.patient_id}>
                          <div className="card">
                            <div className="card-body p-0" role="button" >
                              <div className="patient-card d-flex" onClick={() => {
                                history.push({
                                  pathname: `/patient:${item.patient_id}`,
                                  state: item,
                                });
                              }}>
                                <img
                                  src={
                                    (() => {
                                      if (item.profil_picture !== null && item.profil_picture !== "") {

                                        return "https://doctor.shls.care/pictures/" + item.profil_picture
                                      } else {
                                        if ((item.patient_sexe === "male") || (item.patient_sexe === "Male")) {
                                          return patient
                                        } else {
                                          return woman
                                        }

                                      }
                                    })()
                                  }
                                  alt="User"
                                  className="rounded-circle mr-3"
                                  width="80"
                                  height="80"
                                />
                                <div className="">
                                  <h6 className="fs-24 mb-0">
                                    <span
                                      className="text-black GoldmanSemiBold"
                                    >
                                      {item.patient_lastname}{" "}
                                      {item.patient_firstname}
                                    </span>
                                  </h6>

                                  <p className="fs-18 GoldmanNormal">{item.patient_ville}</p>
                                </div>

                              </div>
                              <div className="patient-info d-flex justify-content-between " onClick={() => {
                                history.push({
                                  pathname: `/patient:${item.patient_id}`,
                                  state: item,
                                });
                              }}>
                                <label className="fs-18">{d["phone"][language]}:</label>
                                <p className="fs-18">
                                  {item.patient_phonenumber}
                                </p>

                              </div>
                              <a href={"mailto:" + item.patient_email}>
                                <div className="patient-info d-flex justify-content-between mb-3">
                                  <label className="fs-18">Email:</label>
                                  <p className="fs-18" >
                                    {item.patient_email}
                                  </p>
                                </div>
                              </a>
                            </div>
                            <div className="card-footer">
                              <p
                                className="mt-3 text-center"
                                style={{
                                  color: "#ed0b4c",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  handleCancelRequest(
                                    item.patient_id,
                                    item.follow_doctor_id
                                  )
                                }
                              >
                                {d["cancel request"][language]}
                              </p>
                            </div>
                          </div>
                        </div>

                      ))}
                  </div>
                </Tab.Pane>
              </Tab.Content>

            </div>
          </div>
        </PerfectScrollbar>


      </Tab.Container>

    </>
  );
}
