import React, { useState, useEffect } from "react";
import { Badge, Spinner,Table } from "react-bootstrap";
import { LanguageContext } from "../../../resources/languageContext";
import d from "../../../resources/dictionary.json";
import Cookies from "js-cookie";
import { useParams } from "react-router-dom";
import { useContext } from "react";
import user from "../../../images/user.png"

export default function Medication() {
  const [loading, setLoading] = useState(true);
  const { language } = useContext(LanguageContext);
  const { id } = useParams();
  const token = Cookies.get("jwt");
  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      let response = await fetch(
        `https://doctor.shls.care/api/doctor/medicament?id=${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json; charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers":
              "Origin, X-requested-With, Content-Type, Accept",
            Authorization: "Bearer " + token,
          },
        },
        { mode: "cors" }
      );
      const data = await response.json();
      if (response.status === 200) {
        setData(data?.data);
      }
    } catch (err) {
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {

    fetchData();
   
  }, []);

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center p-4">
        <Spinner animation="border" variant="primary" />
      </div>
    );
  } else {
    return (
      <div className="medication mt-4">
        {data.length!==0 ? (
          <Table className="table table-bordered " style={{ borderRadius: "0.5rem" }}>
              <thead>
                <tr>
                  <th className="fs-18">{d["medication name"][language]}</th>
                  <th className="fs-18">{d["start date"][language]}</th>
                  <th className="fs-18">{d["duration"][language]}</th>
                  <th className="fs-18">{d["dose"][language]}</th>
                  <th className="fs-18">{d["time"][language]}</th>
                  <th className="fs-18">{d["note"][language]}</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, key) => (
                  <tr key={key} className="fs-16">
                    <td>
                    {item.medical_treatment_name}
                    </td>
                    <td>
                    {item.medical_treatment_start_date}
                    </td>
                    <td>
                    {item.medical_treatment_duration}{" "}{d["days"][language]}
                    </td>
                    <td>{item.medical_treatment_dose}</td>
                    <td>{JSON.parse(item.medical_treatment_times).map((item,index) => (
                      <Badge className="mr-2" variant="info" key={index}>
                        {item}
                      </Badge>
                    ))}</td>
                    <td>{item.medical_treatment_note}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
        ) : (
          <div className="col-12 no-data p-0" style={{ gap: "0.5rem" }}>
            <img className="mt-5" src={user} alt="user" />
            <p className="m-0">
              {d["there are no medications"][language]}
            </p>

          </div>
        )}
      </div>
    );
  }
}
