import React, { useState } from "react";

/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";

/// Components
import Notes from "../components/chatBox/Notes";
import Alerts from "../components/chatBox/Alerts";
import Notifications from "../components/chatBox/Notifications";
import Chat from "../components/chatBox/Chat";

const ChatBox = ({ onClick, toggle }) => {
  const [toggleTab, settoggleTab] = useState("alert");

  const dataToggle = [
    { href: "#notes", name: "Notes" },
    { href: "#alerts", name: "Alerts" },
    { href: "#chat", name: "Chat" },
  ];

  return (
    <>
      <div className={`chatbox ${toggle === "chatbox" ? "active" : ""}`}>
        <div className="chatbox-close" onClick={() => onClick()}></div>
        <div className="custom-tab-1">
          <div className="tab-content">
            <Alerts PerfectScrollbar={PerfectScrollbar} toggle={toggle} />
          </div>
        </div>
      </div>
      <div className={`chatbox ${toggle === "notification" ? "active" : ""}`}>
        <div className="chatbox-close" onClick={() => onClick()}></div>
        <div className="custom-tab-1">
          <div className="tab-content">
            <Notifications PerfectScrollbar={PerfectScrollbar} toggle={toggle} />
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatBox;
