import React, { useContext } from "react";
import { Badge } from "react-bootstrap";
import { LanguageContext } from "../../../resources/languageContext";
import d from "../../../resources/dictionary.json";

export default function StatusBadge({ status, variant }) {
  const { language } = useContext(LanguageContext);

  switch (status) {
    case "canceled":
      return (
        <Badge variant={"outline-danger badge-lg badge-rounded p-2"}>
          {d["canceled"][language]}
        </Badge>
      );
    case "rejected":
      return (
        <Badge variant={"outline-danger badge-lg badge-rounded p-2"}>
          {d["rejected"][language]}
        </Badge>
      );

    case "pending":
      return (
        <Badge variant={"btn outline-info badge-lg badge-rounded p-2"}>
          {d["pending"][language]}
        </Badge>
      );
      case "sending":
      return (
        <Badge variant={"outline-info badge-lg badge-rounded p-2"}>
          {d["pending"][language]}
        </Badge>
      );
      case "updated":
      return (
        <Badge variant={"outline-info badge-lg badge-rounded p-2"}>
          {d["pending"][language]}
        </Badge>
      );
      case "request":
        return (
          <Badge variant={"outline-warning badge-lg badge-rounded p-2"}>
            {d["requested"][language]}
          </Badge>
        );
        case "accepted":
          return (
            <Badge variant={"outline-success badge-lg badge-rounded p-2"}>
                {d["accepted"][language]}
              </Badge>
          );
    default:
      return (
        <Badge variant={"outline-success badge-lg badge-rounded p-2"}>
            {status}
          </Badge>
      );
  }
}
