import React from "react";
import d from "../../../resources/dictionary.json";
import { LanguageContext } from "../../../resources/languageContext";
import { useContext } from "react";

function Unit({ type }) {
  const { language } = useContext(LanguageContext);
  switch (type) {
    case "Spo2":
      return <>%</>;
    case "spo2":
      return <>%</>;
      case "Hemoglobine":
        return <>g/dL</>;
    case "hemoglobine":
      return <>g/dL</>;
    case "hemoglobin":
      return <>g/dL</>;
    case "creatinine":
      return <>mg/L</>;
      case "Creatinine":
        return <>mg/L</>;
    case "weight":
      return <>kg</>;
    case "glycemia":
      return <>mg/dL</>;
    case "Weight":
      return <>kg</>;
    case "Glycemia":
      return <>mg/dL</>;
    case "cholesterol":
      return <>g/L</>;
      case "Triglycerides":
        return <>g/L</>;
    case "triglyceride":
      return <>g/L</>;
    case "uricAcid":
      return <>mg/dL</>;
    case "triglycerid":
      return <>g/L</>;
    case "uricacid":
      return <>mg/dL</>;
    case "uric acid":
      return <>mg/dL</>;
      case "Pressure":
        return <>mmHg</>;
    case "pressure":
      return <>mmHg</>;
      case "BMI":
        return <></>;
    case "bmi":
      return <></>;
    case "thyroid":
      return <>mUl/L</>;
    case "thyroide_tsh":
      return <>mUl/L</>;
      case "Temperature":
        return <>°</>;
    case "temperature":
      return <>°</>;
    case "hba1c":
      return <>%</>;
    case "Hba1c":
      return <>%</>;
      case "Tsh":
        return <>uIU/mL</>;
    case "tsh":
      return <>uIU/mL</>;
    case "t3":
      return <>nmol/L</>;
    case "t4":
      return <>mIU/mL</>;
      case "Steps":
        return <>{d["steps"][language]}</>;
    case "steps":
      return <>{d["steps"][language]}</>;
    case "pas":
      return <>{d["steps"][language]}</>;
    case "physical activity":
      return <>{d["steps"][language]}</>;
      case "Sportive":
        return <>Min</>;
    case "sportive":
      return <>Min</>;
      case "Sportive activity":
        return <>Min</>;
    case "sportive activity":
      return <>Min</>;
    default:
      return <>{type}</>;
  }
}

export default Unit;
